define("lh4/utils/external-permissions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EXTERNAL_PERMISSIONS = Object.freeze({
    ANALYTICS_LINK_VISIBILITY: 'analytics-link-visibility',
    INVOICES_LINK_VISIBILITY: 'invoices-link-visibility'
  });
  var _default = EXTERNAL_PERMISSIONS;
  _exports.default = _default;
});