define("lh4/mirage/fixtures/reports/solo/transaction-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SOLO_TRANSACTION_SUMMARY = {
    custom: true,
    name: 'Transaction Summary',
    total: {
      balance: '$55',
      voids: '$18',
      tax: '$3',
      surcharges: '$7',
      tips: '$10',
      total: '$75',
      totalCash: '$5',
      totalCredit: '$35',
      totalDebit: '$30',
      totalGiftCard: '$40'
    },
    employees: [{
      balance: '$30',
      voids: '$6',
      tips: '$7',
      name: 'Emp 1',
      totalCash: '$5',
      totalCredit: '$10',
      totalDebit: '$20',
      totalGiftCard: '$20',
      transactions: [{
        type: 'sale',
        processedAt: '01/18/2020 16:18PM',
        balance: '$5',
        tips: '$1',
        terminal: 'TERMINAL 1',
        cardBrand: 'Visa',
        tenderType: 'debit'
      }, {
        type: 'void',
        processedAt: '01/19/2020 16:19PM',
        balance: '$5',
        tips: '$1',
        terminal: 'TERMINAL 2',
        cardBrand: 'Mastercard',
        tenderType: 'debit'
      }, {
        type: 'sale',
        processedAt: '01/19/2020 18:18PM',
        balance: '$20',
        tips: '$4',
        terminal: 'TERMINAL 1',
        cardBrand: 'Visa',
        tenderType: 'credit'
      }, {
        type: 'sale',
        processedAt: '01/19/2020 18:21PM',
        balance: '$5',
        tips: '$3',
        terminal: 'TERMINAL 1',
        cardBrand: 'Visa',
        tenderType: 'cash'
      }, {
        type: 'sale',
        processedAt: '01/19/2020 18:24PM',
        balance: '$20',
        tips: '$0',
        terminal: 'TERMINAL 1',
        cardBrand: '',
        tenderType: 'gift-card'
      }]
    }, {
      balance: '$15',
      voids: '$12',
      tips: '$3',
      name: 'Emp 2',
      totalCash: '$0',
      totalCredit: '$10',
      totalDebit: '$25',
      totalGiftCard: '$20',
      transactions: [{
        type: 'sale',
        processedAt: '01/18/2020 16:18PM',
        balance: '$25',
        tips: '$5',
        terminal: 'TERMINAL 3',
        cardBrand: 'My Card',
        tenderType: 'debit'
      }, {
        type: 'void',
        processedAt: '01/18/2020 19:18PM',
        balance: '$10',
        tips: '$2',
        terminal: 'TERMINAL 3',
        cardBrand: 'Maestro',
        tenderType: 'credit'
      }, {
        type: 'sale',
        processedAt: '01/19/2020 20:24PM',
        balance: '$20',
        tips: '$0',
        terminal: 'TERMINAL 3',
        cardBrand: '',
        tenderType: 'gift-card'
      }]
    }]
  };
  var _default = SOLO_TRANSACTION_SUMMARY;
  _exports.default = _default;
});