define("lh4/services/permissions", ["exports", "lh4/enums/services", "lh4/enums/microfrontend-event-keys", "lh4/utils/permissions"], function (_exports, _services, _microfrontendEventKeys, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function matchLocation(locationId) {
    return function (x) {
      return Number(x.l, 10) === Number(locationId) || Number(x.locationId, 10) === Number(locationId);
    };
  }

  function matchDataOwnerLocation(locationId) {
    return function (x) {
      var _x$l;

      return (_x$l = x.l) === null || _x$l === void 0 ? void 0 : _x$l.includes(Number(locationId));
    };
  }

  var matchPermission = function matchPermission(id) {
    return function (x) {
      return x.p === id || x.userPermissionId === id;
    };
  };

  var matchDataOwnerPermission = function matchDataOwnerPermission(id) {
    return function (x) {
      var _x$p;

      return (_x$p = x.p) === null || _x$p === void 0 ? void 0 : _x$p.includes(id);
    };
  };

  var _default = Ember.Service.extend({
    location: Ember.inject.service(),
    tokenData: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    activateService: function activateService(name) {
      var _this = this;

      var location = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.get('location.model');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!location) {
          return reject(new Error('Invalid location'));
        }

        var serviceId = _services.SERVICE_IDS[name];

        if (!serviceId) {
          return reject(new Error('Invalid service'));
        }

        return _this.ajax.request("/api/v1/services/location/".concat(location.id)).then(function (locationServices) {
          var locationService = locationServices.services.findBy('name', name);

          if (locationService) {
            // Update existing service
            _this.ajax.request("/api/v1/services/location/".concat(location.id), {
              method: 'PUT',
              data: JSON.stringify({
                serviceId: serviceId,
                isActive: true,
                configuration: locationService.configuration
              })
            }).then(function () {
              return _this.refresh().then(resolve).catch(reject);
            }).catch(reject);
          } else {
            // Create new service
            _this.ajax.request("/api/v1/services/location/".concat(location.id), {
              method: 'POST',
              data: JSON.stringify({
                serviceId: serviceId
              })
            }).then(function () {
              return _this.refresh().then(resolve).catch(reject);
            }).catch(reject);
          }
        }).catch(reject);
      });
    },
    getService: function getService(name) {
      var _location$services;

      var location = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.location.model;
      return location === null || location === void 0 ? void 0 : (_location$services = location.services) === null || _location$services === void 0 ? void 0 : _location$services.find(function (s) {
        return s.name === name;
      });
    },
    hasService: function hasService(name) {
      var location = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.location.model;
      var locationService = this.getService(name, location);
      return locationService && locationService.isActive;
    },
    hasProduct: function hasProduct(product) {
      var location = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.get('location.model');
      return location && location.get('product') === product;
    },
    hasPermission: function hasPermission(permission) {
      var locationId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.get('location.model.id');

      var _this$get = this.get('tokenData.data'),
          permissions = _this$get.permissions,
          _this$get$dataOwnerPe = _this$get.dataOwnerPermissions,
          dataOwnerPermissions = _this$get$dataOwnerPe === void 0 ? [] : _this$get$dataOwnerPe;

      var hasLocationPermission = (locationId ? permissions.filter(matchLocation(locationId)) : permissions).some(matchPermission(permission));
      var hasDataOwnerPermission = false;

      if (dataOwnerPermissions) {
        hasDataOwnerPermission = (locationId ? dataOwnerPermissions.filter(matchDataOwnerLocation(locationId)) : dataOwnerPermissions).some(matchDataOwnerPermission(permission));
      }

      return hasLocationPermission || hasDataOwnerPermission;
    },
    getLocationPermissions: function getLocationPermissions() {
      var locationId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.get('location.model.id');

      var _this$get2 = this.get('tokenData.data'),
          permissions = _this$get2.permissions,
          dataOwnerPermissions = _this$get2.dataOwnerPermissions;

      var locationPermissions = permissions.filter(matchLocation(locationId)).map(function (x) {
        return x.p || x.userPermissionId;
      });
      var dataOwnerPermission = (dataOwnerPermissions === null || dataOwnerPermissions === void 0 ? void 0 : dataOwnerPermissions.filter(matchDataOwnerLocation(locationId)).flatMap(function (x) {
        return x.p;
      })) || [];
      return [].concat(_toConsumableArray(locationPermissions), _toConsumableArray(dataOwnerPermission));
    },
    isOwner: Ember.computed('tokenData.data', function isOwner() {
      return !this.get('tokenData.data.parentUserId');
    }),
    refresh: function refresh() {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var _this2$location$model;

        var locationId = (_this2$location$model = _this2.location.model) === null || _this2$location$model === void 0 ? void 0 : _this2$location$model.id;

        if (!locationId) {
          return reject(new Error('Invalid location'));
        }

        return _this2.store.findRecord('location', locationId).then(function () {
          var _Ember$getOwner$looku, _Ember$getOwner$looku2;

          // "this.set('location.model', location)" seemed to not do anything, probably because we were
          // setting it to the same object reference, thus observable engine didn't catch the property change.
          // Instead, "notifyPropertyChange" forces various dependent properties to recompute.
          _this2.location.notifyPropertyChange('model'); // In routes, "titleToken: computed('location.model')" seemed to not do anything
          // Instead, the following forces the ember-cli-document-title library to recollect the titleTokens


          (_Ember$getOwner$looku = Ember.getOwner(_this2).lookup('router:main')) === null || _Ember$getOwner$looku === void 0 ? void 0 : _Ember$getOwner$looku.send('collectTitleTokens', []); // The following notifies microfrontend of location data being updated

          (_Ember$getOwner$looku2 = Ember.getOwner(_this2).lookup('appShell:main')) === null || _Ember$getOwner$looku2 === void 0 ? void 0 : _Ember$getOwner$looku2.publish(_microfrontendEventKeys.default.LOCATION_REFRESH);
        }).then(resolve).catch(reject);
      });
    },
    reportLocations: Ember.computed('tokenData.data', function reportLocations() {
      var _this$get3 = this.get('tokenData.data'),
          permissions = _this$get3.permissions,
          dataOwnerPermissions = _this$get3.dataOwnerPermissions;

      var locations = permissions.filter(matchPermission(_permissions.default.GENERAL.REPORTS)).map(function (x) {
        return x.l || x.locationId;
      });
      var dataOwnerLocations = (dataOwnerPermissions || []).filter(matchDataOwnerPermission(_permissions.default.GENERAL.REPORTS)).map(function (perm) {
        return perm.l;
      });
      return Array.from(new Set([].concat(_toConsumableArray(locations), _toConsumableArray(dataOwnerLocations.flat()))));
    }),
    reportGiftCardLocations: Ember.computed('tokenData.data', function reportGiftCardLocations() {
      return this.get('tokenData.data').permissions.filter(matchPermission(_permissions.default.REPORTING.GIFT_CARDS)).map(function (x) {
        return x.l || x.locationId;
      });
    })
  });

  _exports.default = _default;
});