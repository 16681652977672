define("lh4/controllers/authenticated/epro/workforce/landing-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DELINQUENT_SETTING = 'isDelinquent';
  var SOCIAL_SCHEDULES = 'social-schedules';

  var _default = Ember.Controller.extend({
    permissions: Ember.inject.service(),
    showActivateButton: Ember.computed('permissions', function getter() {
      var _this$permissions$get, _this$permissions$get2;

      return !((_this$permissions$get = this.permissions.getService(SOCIAL_SCHEDULES)) !== null && _this$permissions$get !== void 0 && (_this$permissions$get2 = _this$permissions$get.configuration) !== null && _this$permissions$get2 !== void 0 && _this$permissions$get2[DELINQUENT_SETTING]);
    }),
    flashMessagesHelper: Ember.inject.service(),
    actions: {
      activateWorkforce: function activateWorkforce() {
        this.transitionToRoute('authenticated.epro.workforce.terms-and-conditions');
      },
      showErrorForReactivation: function showErrorForReactivation() {
        this.flashMessagesHelper.pushMessage('Onboarding has stopped for an unidentified reason. Please accept "Terms and Conditions" again in order to re-initiate the onboarding process. If you get this message again, please contact Support', 'error');
      },
      showErrorForOnboardingError: function showErrorForOnboardingError() {
        this.flashMessagesHelper.pushMessage('Onboarding has failed. Please contact Support', 'error');
      },
      showNotificationForOnboardingSuccess: function showNotificationForOnboardingSuccess() {
        var _this = this;

        this.flashMessagesHelper.pushMessage('Onboarding was successful, your Lighthouse Menu has changed', 'success');
        Ember.run.later(function () {
          _this.transitionToRoute('authenticated.epro.manage-employees');

          _this.set('isSaving', false);
        }, 5000);
      }
    }
  });

  _exports.default = _default;
});