define("lh4/abilities/internet-payment", ["exports", "ember-can", "lh4/enums/launch-darkly-feature-flags", "lh4/enums/services", "lh4/mixins/abilities/ability-mixin", "lh4/utils/permissions"], function (_exports, _emberCan, _launchDarklyFeatureFlags, _services, _abilityMixin, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_abilityMixin.default, {
    session: Ember.inject.service(),
    launchDarkly: Ember.inject.service('launch-darkly'),
    location: Ember.inject.service('location'),
    metrics: Ember.inject.service(),
    canManage: (0, _abilityMixin.computedAbility)('session.isSSO', function canManage() {
      return !this.session.isSSO;
    }),
    canView: (0, _abilityMixin.computedAbility)(function canManage() {
      return this.isOwner();
    }),
    canViewRiskRules: (0, _abilityMixin.computedAbility)(function canView() {
      return this.hasLocationPermission(_permissions.default.RISK.VIEW_RISK_RULES);
    }),
    canManageRiskRules: (0, _abilityMixin.computedAbility)(function canManage() {
      return this.hasLocationPermission(_permissions.default.RISK.MANAGE_RISK_RULES);
    }),
    canManageRiskBlockList: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlag', function canView() {
      return this.hasPermission(_permissions.default.RISK.MANAGE_BLOCK_LISTS, this.locationId) && this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.IP_MANAGE_BLOCKED_DATA_VISIBILITY];
    }),
    canViewRiskMenuItem: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlag', function canView() {
      return this.canViewRiskRules && this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.IP_RISK_MENU_ITEM_VISIBILITY] && !this.location.isTemporarilyHiddenForCA && !this.location.isCAMozartLBM2;
    }),
    canViewDisputesTab: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      this.metrics.trackEvent(['datadogRum'], {
        event: 'canViewDisputesTab ability',
        value: "\n\t\t\t\tlaunchDarkly: ".concat(this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.INTERNET_PAYMENTS_DISPUTE_MANAGEMENT], ",\n\t\t\t\thasService: ").concat(this.hasService(_services.default.MATTERHORN), ",\n\t\t\t")
      });
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.INTERNET_PAYMENTS_DISPUTE_MANAGEMENT] && this.hasService(_services.default.MATTERHORN);
    }),
    canViewDisputes: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      this.metrics.trackEvent(['datadogRum'], {
        event: 'canViewDisputes ability',
        value: "\n\t\t\t\tlaunchDarkly: ".concat(this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.INTERNET_PAYMENTS_DISPUTE_MANAGEMENT], ",\n\t\t\t\thasPermission: ").concat(this.hasPermission(_permissions.default.INTERNET_PAYMENTS_DISPUTES.IP_VIEW_DISPUTES, this.locationId), ",\n\t\t\t\tlocationId: ").concat(this.locationId, ",\n\t\t\t\tlocationUs: ").concat(this.location.isUS, ",\n\t\t\t\thasService: ").concat(this.hasService(_services.default.MATTERHORN), ",\n\t\t\t\teval: ").concat(this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.INTERNET_PAYMENTS_DISPUTE_MANAGEMENT] && this.hasPermission(_permissions.default.INTERNET_PAYMENTS_DISPUTES.IP_VIEW_DISPUTES, this.locationId) && (this.location.isUS || this.hasService(_services.default.MATTERHORN)), "\n\t\t\t")
      });
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.INTERNET_PAYMENTS_DISPUTE_MANAGEMENT] && this.hasPermission(_permissions.default.INTERNET_PAYMENTS_DISPUTES.IP_VIEW_DISPUTES, this.locationId) && (this.location.isUS || this.hasService(_services.default.MATTERHORN));
    }),
    canSubmitAcceptDisputeEvidence: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasPermission(_permissions.default.INTERNET_PAYMENTS_DISPUTES.IP_SUBMIT_DISPUTE_EVIDENCE, this.locationId);
    }),
    canViewIpAddressBlockTrustV2: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return !!this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.INTERNET_PAYMENTS_SHOW_IP_ADDRESS_BLOCK_TRUST_V2];
    }),
    canViewInsightsAuth: (0, _abilityMixin.computedAbility)(function canView() {
      return this.hasLocationPermission(_permissions.default.INSIGHTS.VIEW_AUTH);
    }),
    canViewInsightsRisk: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.hasLocationPermission(_permissions.default.INSIGHTS.VIEW_RISK) && this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.IP_RISK_INSIGHT_VISIBILITY] && !this.location.isTemporarilyHiddenForCA && !this.location.isCAMozartLBM2;
    }),
    canViewInsightsCardVerify: (0, _abilityMixin.computedAbility)(function canView() {
      return this.hasLocationPermission(_permissions.default.INSIGHTS.VIEW_CARD_VERIFY) && this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.IP_CARD_VERIFY_INSIGHT_VISIBILITY] && !this.location.isTemporarilyHiddenForCA && !this.location.isCAMozartLBM2;
    }),
    canViewInsightsChargeback: (0, _abilityMixin.computedAbility)(function canView() {
      return this.hasLocationPermission(_permissions.default.INSIGHTS.VIEW_CHARGEBACK) && this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.IP_CHARGEBACK_INSIGHT_VISIBILITY] && !this.location.isTemporarilyHiddenForCA && !this.location.isCAMozartLBM2;
    }),
    canViewInsights: (0, _abilityMixin.computedAbility)(function canView() {
      return this.canViewInsightsAuth || this.canViewInsightsRisk || this.canViewInsightsCardVerify || this.canViewInsightsChargeback;
    }),
    canViewLoginInterfaceUpdates: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.ENABLE_LOGIN_INTERFACE_UPDATES];
    }),
    canViewAccountMaintenance: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.ENABLE_HOSPITALITY_ACCOUNT_MAINTENANCE];
    }),
    canViewCardBrandBreakdownToggle: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.CARD_BRAND_BREAKDOWN];
    })
  });

  _exports.default = _default;
});