define("lh4/enums/services", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SERVICE_IDS = _exports.SERVICES = void 0;
  var SERVICES = Object.freeze({
    RESERVATIONS: 'reservations',
    ONLINE_ORDERING: 'online-ordering',
    TABBEDOUT: 'tabbedout',
    GIFT_CARDS: 'gift-cards',
    HARBORPAY: 'harborpay',
    LIGHTHOUSE_PREMIUM: 'lighthouse-premium',
    TABLESIDE: 'tableside',
    LOYALTY: 'lighthouse-loyalty',
    ST_JUDE: 'st-jude',
    PMS_CONNECTOR: 'pms-connector',
    SOCIAL_SCHEDULES: 'social-schedules',
    KOUNT: 'kount',
    MATTERHORN: 'matterhorn-api',
    FINARO_INSIGHT: 'finaro-insight',
    EXTERNAL_LOYALTY: 'external-loyalty',
    LBM2: 'lbm2',
    CHARGE_ZOOM: 'charge-zoom'
  });
  _exports.SERVICES = SERVICES;
  var SERVICE_IDS = Object.freeze({
    reservations: 1,
    'online-ordering': 2,
    tabbedout: 3,
    'gift-cards': 4,
    harborpay: 5,
    'lighthouse-premium': 6,
    tableside: 7,
    'lighthouse-loyalty': 8,
    'st-jude': 9,
    'pms-connector': 10,
    'social-schedules': 11,
    kount: 12,
    'matterhorn-api': 13,
    'finaro-insight': 14,
    'external-loyalty': 15,
    lbm2: 16,
    'charge-zoom': 17
  });
  _exports.SERVICE_IDS = SERVICE_IDS;
  var _default = SERVICES;
  _exports.default = _default;
});