define("lh4/controllers/authenticated/internet-payments/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    media: Ember.inject.service(),
    router: Ember.inject.service(),
    permissions: Ember.inject.service(),
    currentTitle: Ember.computed('media.isMobile', 'router.currentRouteName', function getter() {
      var _this = this;

      var currentName;

      var getCurrentRouteName = function getCurrentRouteName() {
        var route = _this.router.currentRouteName;
        var routeName;

        if (route.includes('account-preferences')) {
          routeName = 'Account Preferences';
        } else if (route.includes('account')) {
          routeName = 'Profile';
        } else if (route.includes('locations')) {
          routeName = 'Locations';
        } else if (route.includes('location-groups-v2')) {
          routeName = 'Groups';
        } else if (route.includes('location-groups')) {
          routeName = 'Location Groups';
        } else if (route.includes('two-factor')) {
          routeName = 'Two-Factor Authentication';
        } else if (route.includes('users')) {
          routeName = 'Users';
        } else if (route.includes('documents')) {
          routeName = 'Documents';
        } else {
          routeName = 'Profile';
        }

        return routeName;
      };

      if (this.media.isMobile) {
        currentName = getCurrentRouteName();
      } else {
        currentName = 'Profile';
      }

      return currentName;
    })
  });

  _exports.default = _default;
});