define("lh4/enums/transactions-search-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.IP_TRANSACTIONS_SEARCH_FILTERS = _exports.FNB_TRANSACTIONS_SEARCH_FILTERS = void 0;
  var FNB_TRANSACTIONS_SEARCH_FILTERS = Object.freeze([{
    name: 'customerName',
    value: 'enums:transactions-search-filters:customer'
  }, {
    name: 'customerEmail',
    value: 'enums:transactions-search-filters:email'
  }, {
    name: 'cardNumber',
    value: 'enums:transactions-search-filters:payment-method'
  }, {
    name: 'invoice',
    value: 'enums:transactions-search-filters:transaction-id'
  }, {
    name: 'authorizationCode',
    value: 'enums:transactions-search-filters:authorization-code'
  }, {
    name: 'customerIpAddress',
    value: 'enums:transactions-search-filters:customer-ip-address'
  }, {
    name: 'cardToken',
    value: 'enums:transactions-search-filters:card-token'
  }, {
    name: 'orderNumber',
    value: 'enums:transactions-search-filters:order-number'
  }, {
    name: 'serverName',
    value: 'enums:transactions-search-filters:server-name'
  }]);
  _exports.FNB_TRANSACTIONS_SEARCH_FILTERS = FNB_TRANSACTIONS_SEARCH_FILTERS;
  var IP_TRANSACTIONS_SEARCH_FILTERS = Object.freeze([{
    name: 'merchantReference',
    value: 'enums:transactions-search-filters:merchant-reference'
  }, {
    name: 'customerName',
    value: 'enums:transactions-search-filters:customer'
  }, {
    name: 'issuerBIN',
    value: 'enums:transactions-search-filters:issuer-bin'
  }, {
    name: 'customerEmail',
    value: 'enums:transactions-search-filters:email'
  }, {
    name: 'cardNumber',
    value: 'enums:transactions-search-filters:credit-card'
  }, {
    name: 'invoice',
    value: 'enums:transactions-search-filters:transaction-id'
  }, {
    name: 'customerIpAddress',
    value: 'enums:transactions-search-filters:customer-ip-address'
  }, {
    name: 'cardToken',
    value: 'enums:transactions-search-filters:card-token'
  }]);
  _exports.IP_TRANSACTIONS_SEARCH_FILTERS = IP_TRANSACTIONS_SEARCH_FILTERS;
});