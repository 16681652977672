define("lh4/abilities/echo-pro/invoice", ["exports", "ember-can", "lh4/mixins/abilities/ability-mixin", "lh4/enums/launch-darkly-feature-flags", "lh4/utils/permissions", "lh4/utils/external-permissions", "lh4/enums/services"], function (_exports, _emberCan, _abilityMixin, _launchDarklyFeatureFlags, _permissions, _externalPermissions, _services) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_abilityMixin.default, {
    session: Ember.inject.service(),
    location: Ember.inject.service(),
    permissions: Ember.inject.service(),
    externalPermissions: Ember.inject.service(),
    launchDarkly: Ember.inject.service('launch-darkly'),
    canViewInvoices: (0, _abilityMixin.computedAbility)('externalPermissions.permissions', function canViewInvoices() {
      return this.location.usesShift4PaymentProcessor && this.hasLocationPermission(_permissions.default.INVOICES.VIEW_INVOICES) && this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.INVOICES] && (this.hasService(_services.SERVICES.CHARGE_ZOOM) || this.externalPermissions.hasExternalPermission(_externalPermissions.default.INVOICES_LINK_VISIBILITY, this.location.model.id));
    })
  });

  _exports.default = _default;
});