define("lh4/components/modal/workforce-notification", ["exports", "moment", "lh4/enums/date-time-format"], function (_exports, _moment, _dateTimeFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Component.extend({
    classNames: ['workforce-notification'],
    can: Ember.inject.service(),
    location: Ember.inject.service(),
    permissions: Ember.inject.service(),
    modalsManager: Ember.inject.service('modals-manager'),

    /* @param {string} errorMessage - Message to display for form level error */
    errorMessage: null,
    locationId: Ember.computed('location.model', function getter() {
      return this.location.model.id;
    }),
    hideWfNotification: Ember.computed('location.model.id', {
      get: function get() {
        var hideWfNotification = JSON.parse(window.localStorage.getItem('hideWfNotification'));

        if (_typeof(hideWfNotification) !== 'object' || hideWfNotification === null) {
          hideWfNotification = {};
        }

        return hideWfNotification[this.location.model.id] || false;
      },
      set: function set(key, value) {
        return value;
      }
    }),
    message: Ember.computed('location.model', function getter() {
      var MIN_DIFF_IN_DAYS = 14;
      var workforceService = this.permissions.getService('social-schedules');

      if (workforceService) {
        var configuration = workforceService.configuration,
            isActive = workforceService.isActive;

        var _ref = configuration || {},
            cancelDate = _ref.cancelDate,
            cancelOrderDate = _ref.cancelOrderDate,
            subscriptionStartDate = _ref.subscriptionStartDate,
            trialEndDate = _ref.trialEndDate; // if it's an active subscription in pending cancellation status


        if (isActive && cancelDate && (0, _moment.default)(cancelDate).diff((0, _moment.default)(), 'days') <= MIN_DIFF_IN_DAYS && (!cancelOrderDate || (0, _moment.default)().diff((0, _moment.default)(cancelOrderDate), 'days') >= 1)) {
          return "Your Workforce subscription will be cancelled on ".concat((0, _moment.default)(cancelDate).format(_dateTimeFormat.default.LOCALIZED_DATE_ONLY), ".");
        } // if it's a trial


        if (subscriptionStartDate === null && trialEndDate && (0, _moment.default)(trialEndDate).diff((0, _moment.default)(), 'days') <= MIN_DIFF_IN_DAYS) {
          return "Your Workforce subscription will be ending trial on ".concat((0, _moment.default)(trialEndDate).format(_dateTimeFormat.default.LOCALIZED_DATE_ONLY), ".");
        }
      }

      return '';
    }),
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.shouldShow && this.message.length && !this.hideWfNotification) {
        this.modalsManager.pushToList('workforce-notification', this.$('.ui.modal'));
      }
    },
    didInsertElement: function didInsertElement() {
      if (this.shouldShow && this.message.length && !this.hideWfNotification) {
        this.modalsManager.pushToList('workforce-notification', this.$('.ui.modal'));
      }
    },
    actions: {
      close: function close() {
        this.modalsManager.deleteFromList('workforce-notification');
      },
      onHidden: function onHidden() {
        this.send('close');
      },
      setHideWfNotification: function setHideWfNotification() {
        this.set('hideWfNotification', !this.hideWfNotification);
        var hideWfNotification = JSON.parse(window.localStorage.getItem('hideWfNotification'));

        if (_typeof(hideWfNotification) !== 'object' || hideWfNotification === null) {
          hideWfNotification = {};
        }

        hideWfNotification[this.locationId] = this.hideWfNotification;
        window.localStorage.setItem('hideWfNotification', JSON.stringify(hideWfNotification));
      }
    }
  });

  _exports.default = _default;
});