define("lh4/mirage/fixtures/reports/processing/transactions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PROCESSING_TRANSACTIONS = {
    name: 'Transactions',
    headers: ['Merchant ID', 'Terminal ID', 'Batch ID', 'Batch Total', 'Credit Card No', 'Card Type', 'Transaction Type', 'Transaction Date', 'Approval Code', 'Reference No', 'Amount', 'Swiped?'],
    rows: [['0022091234', '77610034', '236121943', '$29.34', '402231******9922', 'Visa', 'Sale', '10/11/2024', '010006', null, '$29.34', 'Yes'], ['0022091234', '77610034', '236121943', '$29.34', '402231******9922', 'Visa', 'Sale', '10/11/2024', '010006', null, '$29.34', 'Yes'], ['0022091234', '77610034', '236121943', '$29.34', '402231******9922', 'Visa', 'Sale', '10/11/2024', '010006', null, '$29.34', 'Yes']],
    custom: false
  };
  var _default = PROCESSING_TRANSACTIONS;
  _exports.default = _default;
});