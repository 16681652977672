define("lh4/mirage/routes/echo-pro/reasons", ["exports", "lh4/mirage/fixtures/echo-pro/reasons"], function (_exports, _reasons) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageEchoProReasonsServer;

  function setMirageEchoProReasonsServer(server) {
    server.get('/echo-pro/reasons', function () {
      return _reasons.default;
    });
  }
});