define("lh4/abilities/skytab-enterprise", ["exports", "ember-can", "lh4/mixins/abilities/ability-mixin", "lh4/enums/launch-darkly-feature-flags", "lh4/utils/permissions"], function (_exports, _emberCan, _abilityMixin, _launchDarklyFeatureFlags, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCan.Ability.extend(_abilityMixin.default, {
    launchDarkly: Ember.inject.service('launch-darkly'),
    location: Ember.inject.service(),
    tokenData: Ember.inject.service(),
    canViewSkytabEnterprise: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.SKYTAB_ENTERPRISE_ENABLED];
    }),
    canViewSkytabEnterprisePublishing: (0, _abilityMixin.computedAbility)(function getter() {
      var locationId = this.get('location.model.id');
      return this.hasPermission(_permissions.default.PUBLICATION.ALLOW_PUBLISH, locationId);
    }),
    canAccessSkytabEnterprise: (0, _abilityMixin.computedAbility)('tokenData.data', function getter() {
      return this.get('tokenData.data.dataOwnerPermissions') && this.get('tokenData.data.dataOwnerPermissions').length > 0;
    }),
    canAccessSkytabEnterpriseAsLocation: (0, _abilityMixin.computedAbility)('tokenData.data', function getter() {
      var locationId = this.get('location.model.id');
      var dataOwnerPermissions = this.get('tokenData.data.dataOwnerPermissions');

      if (!Array.isArray(dataOwnerPermissions)) {
        return false;
      }

      return dataOwnerPermissions.some(function (permission) {
        return Array.isArray(permission.l) && permission.l.includes(Number(locationId));
      });
    })
  });

  _exports.default = _default;
});