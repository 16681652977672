define("lh4/mirage/fixtures/echo-pro/reasons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ECHO_PRO_REASONS_RESPONSE_FIXTURE = {
    reasons: [{
      createdAt: '2023-08-08T17:54:09.912Z',
      updatedAt: '2023-08-08T17:54:09.912Z',
      serverCreatedAt: '2023-08-08T17:54:09.912Z',
      serverUpdatedAt: '2023-08-08T17:54:09.912Z',
      guid: 'd76bf01e-0d6a-404f-8b79-ac6b7bed9612',
      locationId: 3344,
      name: 'Employee Error',
      description: null,
      isLocked: false,
      isWaste: true,
      isActive: true,
      ordinal: 0,
      descriptionRequired: false
    }, {
      createdAt: '2023-08-08T17:54:09.912Z',
      updatedAt: '2023-08-08T17:54:09.912Z',
      serverCreatedAt: '2023-08-08T17:54:09.912Z',
      serverUpdatedAt: '2023-08-08T17:54:09.912Z',
      guid: '3f7351a8-8859-4172-83d5-2427b0acdf1e',
      locationId: 3344,
      name: '86 Out of Item',
      description: null,
      isLocked: false,
      isWaste: false,
      isActive: true,
      ordinal: 1,
      descriptionRequired: false
    }, {
      createdAt: '2023-08-08T17:54:09.912Z',
      updatedAt: '2023-08-08T17:54:09.912Z',
      serverCreatedAt: '2023-08-08T17:54:09.912Z',
      serverUpdatedAt: '2023-08-08T17:54:09.912Z',
      guid: 'b716b56a-201c-4cae-8a80-6ec058da0f3f',
      locationId: 3344,
      name: 'Spilled Food/Drink',
      description: null,
      isLocked: false,
      isWaste: true,
      isActive: true,
      ordinal: 2,
      descriptionRequired: false
    }, {
      createdAt: '2023-08-08T17:54:09.912Z',
      updatedAt: '2023-08-08T17:54:09.912Z',
      serverCreatedAt: '2023-08-08T17:54:09.912Z',
      serverUpdatedAt: '2023-08-08T17:54:09.912Z',
      guid: '935b638e-579d-446b-8a49-bfaac4647fc9',
      locationId: 3344,
      name: 'Customer Changed Mind',
      description: null,
      isLocked: false,
      isWaste: true,
      isActive: true,
      ordinal: 3,
      descriptionRequired: false
    }, {
      createdAt: '2023-08-08T17:54:09.912Z',
      updatedAt: '2023-08-08T17:54:09.912Z',
      serverCreatedAt: '2023-08-08T17:54:09.912Z',
      serverUpdatedAt: '2023-08-08T17:54:09.912Z',
      guid: '415881a1-f2d1-4822-8673-b38b91f1e749',
      locationId: 3344,
      name: 'Training',
      description: null,
      isLocked: false,
      isWaste: false,
      isActive: true,
      ordinal: 4,
      descriptionRequired: false
    }, {
      createdAt: '2023-08-08T17:54:09.912Z',
      updatedAt: '2024-02-08T18:57:08.799Z',
      serverCreatedAt: '2023-08-08T17:54:09.912Z',
      serverUpdatedAt: '2024-02-08T18:57:11.424Z',
      guid: '4ec42bb5-e200-42db-8324-fa37b2c694ba',
      locationId: 3344,
      name: 'Testing',
      description: null,
      isLocked: false,
      isWaste: false,
      isActive: true,
      ordinal: 5,
      descriptionRequired: true
    }, {
      createdAt: '2024-02-08T18:05:05.321Z',
      updatedAt: '2024-02-08T18:05:05.321Z',
      serverCreatedAt: '2024-02-08T18:05:07.869Z',
      serverUpdatedAt: '2024-02-08T18:05:07.869Z',
      guid: '98ef7c4b-6528-47d2-97ba-075714e78ae2',
      locationId: 3344,
      name: 'Example Reason',
      description: '',
      isLocked: false,
      isWaste: false,
      isActive: true,
      ordinal: 6,
      descriptionRequired: false
    }]
  };
  var _default = ECHO_PRO_REASONS_RESPONSE_FIXTURE;
  _exports.default = _default;
});