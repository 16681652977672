define("lh4/services/external-permissions", ["exports", "lh4/utils/external-permissions"], function (_exports, _externalPermissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    tokenData: Ember.inject.service(),
    loading: {},
    setLoading: function setLoading(type, value) {
      this.set('loading', _objectSpread(_objectSpread({}, this.loading), {}, _defineProperty({}, type, value)));
    },
    permissions: {},
    setPermission: function setPermission(type, value) {
      this.set('permissions', _objectSpread(_objectSpread({}, this.permissions), {}, _defineProperty({}, type, value)));
    },
    loadPermission: function loadPermission(permission, url) {
      var _this = this;

      // get cached permissions
      if (typeof this.permissions[permission] !== 'undefined') {
        return this.permissions[permission];
      } // get visibility from api then cache the result


      if (!this.loading[permission]) {
        this.setLoading(permission, true);
        this.ajax.request(url, {
          permission: 'GET'
        }).then(function (response) {
          _this.setLoading(permission, false);

          _this.setPermission(permission, !!(response !== null && response !== void 0 && response.enabled) || false);
        }).catch(function () {
          _this.setLoading(permission, false);

          _this.setPermission(permission, false);
        });
      } // default to false while we wait for api call to resolve


      return false;
    },
    hasExternalPermission: function hasExternalPermission(type) {
      var locationId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      switch (type) {
        case _externalPermissions.default.ANALYTICS_LINK_VISIBILITY:
          // ANALYTICS_LINK_VISIBILITY is by user, only 1 request needed per session
          return this.loadPermission(type, '/api/v1/analytics/permissions');

        case _externalPermissions.default.INVOICES_LINK_VISIBILITY:
          // INVOICES_LINK_VISIBILITY is by location, 1 request is needed per location
          if (!locationId) {
            return false;
          }

          return this.loadPermission("".concat(type, "-location-").concat(locationId), "/api/v1/s4-invoices/app/".concat(locationId, "/permissions"));

        default:
          return false;
      }
    }
  });

  _exports.default = _default;
});