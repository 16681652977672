define("lh4/mirage/routes/reports", ["exports", "lh4/mirage/fixtures/reports", "lh4/mirage/fixtures/drill-down-report-list", "lh4/mirage/fixtures/drill-down-report", "lh4/mirage/fixtures/drill-down-ticket-details", "lh4/mirage/fixtures/echo-pro/cash-transaction-by-employee", "lh4/mirage/fixtures/echo-pro/activity-summary", "lh4/mirage/fixtures/ticket-details-download", "lh4/mirage/fixtures/fiscal-report", "lh4/mirage/fixtures/processing-finaro-list", "lh4/mirage/fixtures/processing-finaro-report", "lh4/mirage/fixtures/processing-report", "lh4/mirage/fixtures/ew-pos-reports/ticket-transaction-status", "lh4/mirage/fixtures/reports/solo/transaction-summary", "lh4/mirage/fixtures/reports/solo/sales-summary", "lh4/mirage/fixtures/reports/solo/quick-menu-summary", "lh4/mirage/fixtures/reports/processing/transactions"], function (_exports, _reports, _drillDownReportList, _drillDownReport, _drillDownTicketDetails, _cashTransactionByEmployee, _activitySummary, _ticketDetailsDownload, _fiscalReport, _processingFinaroList, _processingFinaroReport, _processingReport, _ticketTransactionStatus, _transactionSummary, _salesSummary, _quickMenuSummary, _transactions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageReportsServer;

  function setMirageReportsServer(server) {
    server.get('/reports', function () {
      return {
        reports: _reports.default
      };
    });
    server.get('/reports/subscriptions', function (_ref) {
      var subscriptions = _ref.subscriptions;
      return {
        subscriptions: subscriptions.all().models
      };
    });
    server.delete('/reports/subscriptions/:id', {});
    server.get('/gift-card-reports', function () {
      return _drillDownReportList.default;
    });
    server.get('/reports/fiscal-reports/:locationId', function () {
      return _fiscalReport.default;
    });
    server.post('/:reportUrl/:exportType/:reportType', function () {
      return {
        guid: 'guid',
        url: 'url'
      };
    });
    server.post('/gift-card-reports/transaction-summary', function () {
      return _drillDownReport.GC_TRANSACTION_REPORT_FIXTURE;
    });
    server.post('/gift-card-reports/transaction-detail', function () {
      return _drillDownReport.GC_TRANSACTION_DETAIL_FIXTURE;
    });
    server.post('/gift-card-reports/balances', function () {
      return _drillDownReport.GC_BALANCE_FIXTURE;
    });
    server.get('/tickets/:123/details', function () {
      return _drillDownTicketDetails.default;
    });
    server.get('/tickets/:123/details/pdf', function () {
      return _ticketDetailsDownload.default;
    });
    server.post('/reports/processing/transactions', function () {
      return _transactions.default;
    });
    server.post('/reports/solo/quick-menu-summary', function () {
      return _quickMenuSummary.default;
    });
    server.post('/reports/solo/transaction-summary', function () {
      return _transactionSummary.default;
    });
    server.post('/reports/solo/sales-summary', function () {
      return _salesSummary.default;
    });
    server.post('/reports/echo-pro/cash-transactions-by-employee', function () {
      return _cashTransactionByEmployee.default;
    });
    server.post('/reports/echo-pro/activity-summary', function () {
      return _activitySummary.default;
    });
    server.get('/reports/products/location/:id', function () {
      return {
        reportProducts: {
          'echo-pro': true,
          echo: false,
          retail: false,
          hospitality: false,
          harborpay: false,
          processing: true,
          solo: true
        }
      };
    });
    server.get('/reports/products/gpatt', function () {
      return {
        reportProducts: {
          gpatt: false
        }
      };
    });
    server.post('/reports/products/gpatt', function () {
      return {
        reportProducts: {
          gpatt: true
        }
      };
    });
    server.post('/reports/products/loyalty', function () {
      return {
        reportProducts: {
          loyalty: true
        }
      };
    });
    server.post('/reports/products/solo', function () {
      return {
        reportProducts: {
          solo: true
        }
      };
    });
    server.get('/processing-reports/options', function () {
      return _processingFinaroList.default;
    });
    server.post('/processing-reports/fetch', function (schema, request) {
      var _JSON$parse = JSON.parse(request.requestBody),
          reportType = _JSON$parse.reportType,
          locationIds = _JSON$parse.locationIds;

      if (reportType === 'ticket-transaction-status') {
        if ([1, 2].every(function (locationId) {
          return locationIds.includes(locationId);
        })) {
          return _ticketTransactionStatus.TICKET_TRANSACTION_STATUS_REPORT_MULTIPLE_LOCATIONS;
        }

        return _ticketTransactionStatus.TICKET_TRANSACTION_STATUS_REPORT;
      }

      return reportType === 'settlement' ? _processingReport.default : _processingFinaroReport.default;
    });
  }
});