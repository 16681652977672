define("lh4/mirage/fixtures/internet-payments/transactions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TRANSACTION_TYPE_INFO_RESPONSE_FIXTURE = _exports.default = void 0;
  var TRANSACTIONS_RESPONSE_FIXTURE = {
    transactions: [{
      date: '2022-10-03 08:01:29',
      processingStatus: 'unsettled',
      transactionId: 1,
      cardType: 'visa',
      cardNumber: '1119',
      customerName: 'SDK TestName',
      customerEmail: 'sdkTest@kountsdktestdomain.com',
      customerIpAddress: '192.168.6.4',
      amount: '1234.56',
      type: 'VOID',
      riskStatus: null,
      riskId: 'EA6E18A8-B9A1-4388-9706-A18AE0F1DEDD',
      acquirerResponse: 'N7 - ',
      invoice: '0000000001',
      cardToken: 1,
      merchantReference: 'simulator:0903180059',
      merchantBatchNumber: null,
      fundingSource: null,
      transType: 'VOID',
      transbaseId: 'uniqueId1',
      id: 1,
      eventHistory: [{
        messag: 'Captured',
        eventStatus: 'Captured',
        type: 'Sale',
        code: 'CAPTURED',
        date: '2025-02-11T21:08:17.933Z'
      }, {
        message: 'Auth Approved',
        eventStatus: 'Auth Approved',
        type: 'Auth',
        code: 'AUTH_APPROVED',
        date: '2025-02-11T21:08:17.933Z'
      }]
    }, {
      date: '2022-10-03 08:01:29',
      processingStatus: 'unsettled',
      transactionId: 2,
      cardType: 'visa',
      cardNumber: '1119',
      customerName: 'SDK TestName',
      customerEmail: 'sdkTest@kountsdktestdomain.com',
      customerIpAddress: '192.168.6.5',
      amount: '1234.56',
      type: 'VOID',
      riskStatus: null,
      riskId: 'EA6E18A8-B9A1-4388-9706-A18AE0F1DEDD',
      acquirerResponse: 'N7 - ',
      invoice: '0000000002',
      cardToken: 2,
      merchantReference: 'simulator:0903180059',
      merchantBatchNumber: null,
      fundingSource: null,
      transType: 'VOID',
      transbaseId: 'uniqueId2',
      id: 2,
      orderNumber: 7008,
      serverName: 'Server1',
      authorizationCode: 'OK347W',
      eventHistory: [{
        messag: 'Captured',
        eventStatus: 'Captured',
        type: 'Sale',
        code: 'CAPTURED',
        date: '2025-02-11T21:08:17.933Z'
      }, {
        message: 'Auth Approved',
        eventStatus: 'Auth Approved',
        type: 'Auth',
        code: 'AUTH_APPROVED',
        date: '2025-02-11T21:08:17.933Z'
      }]
    }, {
      date: '2022-10-03 08:01:29',
      processingStatus: 'unsettled',
      transactionId: 3,
      cardType: 'visa',
      cardNumber: '1119',
      customerName: 'SDK TestName',
      customerEmail: 'sdkTest@kountsdktestdomain.com',
      customerIpAddress: '192.168.6.6',
      amount: '1234.56',
      type: 'VOID',
      riskStatus: null,
      riskId: 'EA6E18A8-B9A1-4388-9706-A18AE0F1DEDD',
      acquirerResponse: 'N7 - ',
      invoice: '0000000003',
      cardToken: 3,
      merchantReference: 'simulator:0903180059',
      merchantBatchNumber: null,
      fundingSource: null,
      transType: 'VOID',
      id: 3,
      transbaseId: 'uniqueId3',
      orderNumber: 7009,
      serverName: 'Server2',
      authorizationCode: 'OK348W',
      eventHistory: [{
        messag: 'Captured',
        eventStatus: 'Captured',
        type: 'Sale',
        code: 'CAPTURED',
        date: '2025-02-11T21:08:17.933Z'
      }, {
        message: 'Auth Approved',
        eventStatus: 'Auth Approved',
        type: 'Auth',
        code: 'AUTH_APPROVED',
        date: '2025-02-11T21:08:17.933Z'
      }]
    }],
    count: 3
  };
  var _default = TRANSACTIONS_RESPONSE_FIXTURE;
  _exports.default = _default;
  var TRANSACTION_TYPE_INFO_RESPONSE_FIXTURE = {
    transactions: [{
      eventHistory: [{
        message: 'Settled',
        eventStatus: 'Settled',
        type: 'Sale',
        code: 'SETTLED',
        date: '2024-07-08T20:20:41.000Z'
      }],
      date: '2024-07-08T20:20:41.000Z',
      processingStatus: 'SETTLED',
      transactionId: 285529669,
      cardType: 'giftcard',
      cardNumber: '1821',
      customerName: null,
      customerEmail: null,
      customerIpAddress: null,
      customerReference: '',
      issuerBIN: null,
      amount: '110.00',
      secondaryAmount: '0.00',
      primaryAmount: '110.00',
      type: 'INFO',
      riskStatus: null,
      riskId: null,
      acquirerResponse: null,
      invoice: '6101292805',
      cardToken: '18212qymckzjrydj',
      merchantReference: '',
      merchantBatchNumber: null,
      fundingSource: null,
      hostResponse: null,
      shift4Mid: '668269',
      shift4Serial: '34248',
      riskScore: null,
      secureStatus: null,
      personaScore: 0,
      deviceId: null,
      browser: null,
      isMobileDevice: null,
      osVersion: null,
      riskRules: [],
      weightedRiskTotal: 0,
      displayV2Preferred: false,
      chargebackAmount: null,
      chargebackCode: null,
      chargebackDatePosted: null,
      chargebackReason: null,
      chargebackDescription: null,
      networkReferenceNumber: null,
      avsResponseCode: null,
      avsDescription: '',
      cvv2ResponseCode: null,
      cvv2Description: '',
      isRecurringPayment: false,
      transbaseId: 'TRANf1c5641b42ad51f2232e9400a640f1df',
      riskEvent: false,
      cardVerifyEvent: false,
      authEvent: false,
      authDateTz: '1899-12-30T00:00:00.000Z',
      captureEvent: false,
      captureDateTz: null,
      processedEvent: true,
      merchantBatchEvent: false,
      merchantBatchDateTz: null,
      eventStatus: 'BALANCE CHECK',
      transactionStatus: 'SETTLED',
      orderType: 'INFO',
      orderNumber: null,
      authorizationCode: '',
      serverName: null,
      ticketReference: null,
      ticketPaymentReference: null,
      customerPhone: null,
      merchantId: '0021746829',
      terminalNumber: null,
      batchGrossAmount: null,
      batchNetAmount: null,
      revenueCenter: null,
      cardTypeSource: 'Gift Card',
      dayParts: null,
      ticketOpen: null,
      ticketClose: null,
      isAutoSettled: true,
      transType: 'INFO',
      rules: [],
      id: 4
    }],
    count: 1
  };
  _exports.TRANSACTION_TYPE_INFO_RESPONSE_FIXTURE = TRANSACTION_TYPE_INFO_RESPONSE_FIXTURE;
});