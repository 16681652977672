define("lh4/mirage/routes/internet-payments/transactions/edit", ["exports", "lh4/mirage/fixtures/internet-payments/transactions"], function (_exports, _transactions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageInternetPaymentsTransactionsEditServer;

  function setMirageInternetPaymentsTransactionsEditServer(server) {
    server.get('/internet-payments/transactions/:transbaseId/detail', function (_, _ref) {
      var params = _ref.params;

      var transaction = _transactions.default.transactions.find(function (_ref2) {
        var transbaseId = _ref2.transbaseId;
        return transbaseId === params.transbaseId;
      });

      return {
        transaction: transaction
      };
    });
  }
});