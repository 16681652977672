define("lh4/templates/authenticated/onboarding-wizard/chapter-restaurant-basics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xVrbfty8",
    "block": "{\"symbols\":[],\"statements\":[[5,\"onboarding-wizard/onboarding-wizard-step-wrapper\",[],[[\"@data-test\",\"@data-dd-name\",\"@className\",\"@firstButtonText\",\"@onFirstButtonClick\",\"@isCentered\",\"@model\",\"@onBack\",\"@canNavigateBack\"],[\"onboarding-wizard-chapter-restaurant-basics\",\"onboarding-wizard-chapter-restaurant-basics\",\"onboarding-chapter\",\"CONTINUE\",[22,\"continue\"],true,[23,0,[\"model\"]],[22,\"back\"],true]],{\"statements\":[[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[0,\"Tell us more about your restaurant\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"progress-container\"],[8],[0,\"\\n\\t\\t\"],[5,\"ui/s4/progress-bar/onboarding-wizard-bar\",[],[[\"@stepData\",\"@showIcons\"],[[23,0,[\"model\",\"stepData\"]],true]]],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/onboarding-wizard/chapter-restaurant-basics.hbs"
    }
  });

  _exports.default = _default;
});