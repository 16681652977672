define("lh4/enums/ip-processing-report-slugs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var IP_PROCESSING_REPORT_SLUG = Object.freeze({
    ACH_DEPOSIT: 'ach-deposit',
    ACH_TRANSACTION: 'ach-transaction',
    CHARGEBACK_RECONCILIATION: 'chargeback-reconciliation',
    DISPUTE: 'dispute',
    FUNDS_IN_TRANSIT: 'funds-in-transit',
    INTERCHANGE: 'interchange',
    INTERCHANGE_SUMMARY: 'interchange-summary',
    REFUND: 'refund',
    RESERVE_BALANCE: 'reserve-balance',
    SETTLEMENT: 'settlement',
    SETTLEMENT_ENTERPRISE: 'settlement-enterprise',
    SETTLEMENT_DETAIL: 'settlement-detail',
    TRANSACTION: 'transaction',
    PREGENERATED_REPORTS: 'Pre-Generated Reports'
  });
  var _default = IP_PROCESSING_REPORT_SLUG;
  _exports.default = _default;
});