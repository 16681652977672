define("lh4/enums/microfrontend-event-keys", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MICROFRONTEND_EVENT_KEYS = Object.freeze({
    SIGNOUT: 'SIGNOUT',
    LOCATION: 'LOCATION',
    LOCATION_REFRESH: 'LOCATION_REFRESH',
    AUTH: 'AUTH',
    SIDEBAR_IS_OPEN: 'IS_OPEN',
    OPEN_HELP_MODAL: 'OPEN_HELP_MODAL',
    CHANGE_DATA_OWNER: 'CHANGE_DATA_OWNER'
  });
  var _default = MICROFRONTEND_EVENT_KEYS;
  _exports.default = _default;
});