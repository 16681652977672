define("lh4/controllers/authenticated/epro/menu/departments/edit", ["exports", "lh4/controllers/general-form-controller"], function (_exports, _generalFormController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalFormController.default.extend({
    locale: Ember.inject.service(),
    location: Ember.inject.service(),
    corporation: Ember.inject.service(),
    cleanupAfterSave: function cleanupAfterSave() {
      var _this$model$departmen, _this$model$departmen2, _this$model$departmen3;

      (_this$model$departmen = this.model.department.dynamicPricing) === null || _this$model$departmen === void 0 ? void 0 : _this$model$departmen.toArray().forEach(function (map) {
        map.unloadRecord();
      });
      (_this$model$departmen2 = this.model.department.get('availability.schedules')) === null || _this$model$departmen2 === void 0 ? void 0 : _this$model$departmen2.toArray().forEach(function (map) {
        map.unloadRecord();
      }); // Clear dirty attribues so that they won't override any future changes (ex. after bulk edit)

      if ((_this$model$departmen3 = this.model.department.get('availability.content')) !== null && _this$model$departmen3 !== void 0 && _this$model$departmen3.hasDirtyAttributes) {
        this.model.department.get('availability.content').rollbackAttributes();
      }
    },
    validateAndSaveChangeset: function validateAndSaveChangeset(changesets, key) {
      var _this = this;

      return changesets.map(function (record) {
        return record.validate().then(function () {
          if (!record.isValid && !record.get('isVoided')) {
            return Ember.RSVP.reject(record.get('errors'));
          }

          record.set('department', _this.model.department); // record.save was throwing because models don't have a save endpoint

          record.execute();
          record.rollback();

          _this.model.department.get(key).pushObject(record.data);

          return true;
        }).catch(function () {
          _this.set('areChildrenInvalid', true);

          return false;
        });
      });
    },
    savePricing: function savePricing() {
      if (this.changeset.dynamicPricingChangesets.some(function (c) {
        return c.get('hasZeroPrice') === true;
      })) {
        var confirmed = window.confirm("One or more item prices when affected by Dynamic Pricing rules will result to ".concat(this.locale.currencySymbol, "0. Would you like to proceed?"));

        if (!confirmed) {
          this.set('isSaving', false);
          return Ember.RSVP.reject(new Error('Please confirm'));
        }
      }

      var dynamicPricingChangesets = this.validateAndSaveChangeset(this.changeset.dynamicPricingChangesets, 'dynamicPricing');
      return Ember.RSVP.all(dynamicPricingChangesets);
    },
    saveAvailability: function saveAvailability() {
      var _this2 = this;

      if (this.changeset.get('defaultAvailabilityString') === 'true') {
        return Ember.RSVP.resolve();
      }

      var availability = this.changeset.availabilityChangeset;
      return availability.validate().then(function () {
        if (!availability.isValid) {
          _this2.set('areChildrenInvalid', true);

          return Ember.RSVP.reject(availability.get('errors'));
        }

        availability.execute();
        availability.rollback();

        _this2.model.department.set('availability', availability.data);

        return Ember.RSVP.resolve();
      }).then(function () {
        if (_this2.changeset.get('availabilityChangeset').get('timeOption') === 'Custom Schedule') {
          var schedulePromises = _this2.validateAndSaveChangeset(availability.scheduleChangesets, 'availability.schedules');

          return Ember.RSVP.all(schedulePromises);
        }

        return Ember.RSVP.resolve();
      });
    },
    handleLocalErrors: function handleLocalErrors(errors) {
      var _this3 = this;

      // We currently don't have a field component for checkboxes
      // Because of this there is no standard way of displaying
      // error messages for boolean fields
      // https://jira.shift4payments.com/browse/LH-8853
      if (errors.length) {
        var booleanError = errors.find(function (error) {
          return typeof error.value === 'boolean';
        });

        if (booleanError) {
          this.set('booleanError', booleanError.validation);
          this.set('serverErrorTimeoutId', Ember.run.later(this, function () {
            _this3.set('booleanError', null);
          }, this.serverErrorTimeoutDelay));
        } else {
          // Used to catch custom validation error that validates models one to another
          // For unique name
          var error = errors[0];

          if (error && error.validation) {
            this.set('customError', error.validation);
          }
        }
      }
    },
    handleServerErrors: function handleServerErrors(errors) {
      var _this4 = this;

      // TODO normalize how we handle these error messages
      var updateError = "Something went wrong, ".concat(this.instanceName, " could not be updated.");
      errors.forEach(function (error) {
        var errorMessage = error.details && error.details[0] || error.message || updateError;

        _this4.set('serverError', errorMessage);

        _this4.set('serverErrorTimeoutId', Ember.run.later(_this4, function () {
          _this4.set('serverError', null);
        }, _this4.serverErrorTimeoutDelay));
      });
      return updateError;
    },
    actions: {
      update: function update() {
        var _this5 = this;

        if (this.isSaving) {
          return;
        }

        this.setProperties({
          isSaving: true,
          showErrors: true
        });
        this.clearServerError(); // eslint-disable-next-line arrow-body-style

        Ember.RSVP.all([this.savePricing(), this.saveAvailability()]).then(function () {
          return _this5.changeset.validate().then(function () {
            if (_this5.changeset.get('isInvalid') || _this5.areChildrenInvalid) {
              return Ember.RSVP.reject(_this5.changeset.get('errors'));
            }

            if (_this5.isModelAndChangesetPristine()) {
              _this5.closeTray();

              return Ember.RSVP.resolve();
            }

            return _this5.changeset.save().then(function () {
              _this5.cleanupAfterSave();

              _this5.closeTray();
            }).catch(function (_ref) {
              var errors = _ref.errors;

              if (!(errors && errors[0])) {
                return;
              }

              _this5.handleServerErrors(errors);
            });
          }).catch(function (errors) {
            return _this5.handleLocalErrors(errors);
          });
        }).catch(function (errors) {
          return _this5.handleLocalErrors(errors);
        }).finally(function () {
          return _this5.set('isSaving', false);
        });
      },
      buildChildChangeset: function buildChildChangeset(model) {
        return this.buildChildChangeset(model);
      },
      setScheduleValidation: function setScheduleValidation(isAnyScheduleOverlapping) {
        this.set('isAnyScheduleOverlapping', isAnyScheduleOverlapping);
      }
    }
  });

  _exports.default = _default;
});