define("lh4/routes/authenticated/reporting/index", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    can: Ember.inject.service(),
    redirect: function redirect() {
      if (this.can.can('view reports in general')) {
        return this.transitionTo('authenticated.reporting.reports');
      }

      if (this.can.can('create own subscriptions in general')) {
        return this.transitionTo('authenticated.reporting.subscriptions');
      }

      if (this.can.can('view fiscalisations reports in feature-flag')) {
        return this.transitionTo('authenticated.reporting.fiscalization');
      }

      return this.transitionTo('/');
    }
  });

  _exports.default = _default;
});