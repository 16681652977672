define("lh4/templates/authenticated/internet-payments/reporting/reports/pre-generated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tLf6nOuK",
    "block": "{\"symbols\":[\"report\",\"format\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"pre-generated-reports\"],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"isLoading\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[5,\"internet-payments/spinners/loader-circle\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,0,[\"error\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[5,\"internet-payments/ui/message/warning-message\",[],[[\"@messageText\"],[[23,0,[\"error\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\t\"],[5,\"internet-payments/tables/scroll-table\",[],[[\"@headers\",\"@rows\",\"@itemsPerPage\",\"@offset\",\"@totalCount\",\"@onFilterChange\",\"@sortAsc\",\"@sortBy\"],[[23,0,[\"reportHeaders\"]],[23,0,[\"model\",\"reports\"]],[23,0,[\"preGeneratedLimit\"]],[23,0,[\"preGeneratedOffset\"]],[23,0,[\"model\",\"meta\",\"count\"]],[23,0,[\"onFilterChange\"]],[23,0,[\"sortAsc\"]],[23,0,[\"sortBy\"]]]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[7,\"td\",true],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n\\t\\t\\t\"],[7,\"td\",true],[8],[1,[28,\"moment-date-format\",[[23,1,[\"createdAt\"]],\"l\"],null],false],[9],[0,\"\\n\\t\\t\\t\"],[7,\"td\",true],[8],[0,\"\\n\\t\\t\\t\\t\"],[5,\"internet-payments/ui/dropdown/dropdown-file-selector\",[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,1,[\"formats\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\\t\"],[7,\"li\",false],[12,\"class\",\"item\"],[3,\"on\",[\"click\",[28,\"fn\",[[23,0,[\"download\"]],[23,1,[\"id\"]],[23,2,[]]],null]]],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\\t\"],[1,[28,\"file-extension-format\",[[23,2,[]]],null],false],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\t\\t\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/internet-payments/reporting/reports/pre-generated.hbs"
    }
  });

  _exports.default = _default;
});