define("lh4/controllers/authenticated/epro/menu/modifiers/create", ["exports", "lh4/controllers/general-form-controller", "jquery"], function (_exports, _generalFormController, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalFormController.default.extend({
    location: Ember.inject.service(),
    // Waits till modal closes and setups new modifier form with predefined values
    duplicateModifier: function duplicateModifier(modifierId) {
      var _this = this;

      Ember.run.later(this, function () {
        _this.transitionToRoute('authenticated.epro.menu.modifiers.create', {
          queryParams: {
            clone: modifierId
          }
        });

        (0, _jquery.default)('.fullscreen-modal > .content').animate({
          scrollTop: 0
        }, 'fast');
      }, 2500);
    },
    actions: {
      create: function create() {
        var _this2 = this;

        var cloning = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        if (this.isSaving) {
          return;
        }

        this.setProperties({
          showErrors: true,
          isSaving: true
        });
        this.clearServerError();
        this.changeset.validate().then(function () {
          if (_this2.changeset.get('isInvalid')) {
            return Ember.RSVP.reject(_this2.changeset.get('errors'));
          } // This only gets called when there's a model that has no changes AND no validations
          // attached to it. Not a very possible scenario but let's check for consistency


          if (_this2.isModelAndChangesetPristine()) {
            _this2.closeModal();

            return Ember.RSVP.resolve();
          }

          return _this2.changeset.save().then(function (data) {
            if (cloning) {
              _this2.duplicateModifier(data.id);
            } else {
              _this2.closeModal();
            }
          }).catch(function (_ref) {
            var errors = _ref.errors;

            if (errors && errors[0]) {
              // TODO normalize how we handle these error messages
              var createError = "Something went wrong, ".concat(_this2.instanceName, " could not be created.");
              errors.forEach(function (error) {
                var errorMessage = error.details && error.details[0] || error.message || createError;

                _this2.set('serverError', errorMessage);

                _this2.set('serverErrorTimeoutId', Ember.run.later(_this2, function () {
                  _this2.set('serverError', null);
                }, _this2.serverErrorTimeoutDelay));
              });
            }
          });
        }).catch(function (errors) {
          // We currently don't have a field component for checkboxes
          // Because of this there is no standard way of displaying
          // error messages for boolean fields
          // https://jira.shift4payments.com/browse/LH-8853
          if (errors.length) {
            var booleanError = errors.find(function (error) {
              return typeof error.value === 'boolean';
            });

            if (booleanError) {
              _this2.set('booleanError', booleanError.validation);

              _this2.set('serverErrorTimeoutId', Ember.run.later(_this2, function () {
                _this2.set('booleanError', null);
              }, _this2.serverErrorTimeoutDelay));
            }
          }
        }).finally(function () {
          return _this2.set('isSaving', false);
        });
      },
      createAndDuplicate: function createAndDuplicate() {
        for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        this.send.apply(this, ['create', true].concat(args));
      }
    }
  });

  _exports.default = _default;
});