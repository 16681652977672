define("lh4/serializers/echo-pro/setting/order-ready-screen", ["exports", "lh4/serializers/echo-pro/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serialize: function serialize(snapshot) {
      var _json$displayType;

      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      var json = this._super.apply(this, [snapshot].concat(args));

      delete json.setting;
      var result = {
        title: json.title,
        background_color: json.color,
        enabled: json.enabled === 'true' || json.enabled === '1' ? '1' : '0',
        display_mode: json.displayModeAuto ? 'auto' : 'manual',
        auto_display_duration: json.displayModeAuto ? json.autoDisplayDuration : '',
        manual_display_duration: json.displayModeManual ? json.manualDisplayDuration : '',
        display_type: (_json$displayType = json.displayType) !== null && _json$displayType !== void 0 ? _json$displayType : 'order_number'
      };
      return {
        settings: {
          locationId: snapshot.id,
          order_ready_screen: result
        }
      };
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id) {
      return {
        data: {
          id: id,
          type: 'echo-pro/setting/orderReadyScreen',
          attributes: payload.settings.order_ready_screen
        }
      };
    }
  });

  _exports.default = _default;
});