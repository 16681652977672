define("lh4/templates/components/ui/icons/s4/download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PV3C07DX",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"width\",\"24\"],[10,\"height\",\"24\"],[10,\"viewBox\",\"0 0 24 24\"],[10,\"fill\",\"currentColor\"],[10,\"class\",\"download-icon\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M18.5005 15.001V18.001H6.50049V15.001H4.50049V18.001C4.50049 19.101 5.40049 20.001 6.50049 20.001H18.5005C19.6005 20.001 20.5005 19.101 20.5005 18.001V15.001H18.5005ZM17.5005 11.001L16.0905 9.59098L13.5005 12.171V4.00098H11.5005V12.171L8.91049 9.59098L7.50049 11.001L12.5005 16.001L17.5005 11.001Z\"],[10,\"fill\",\"currentColor\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/s4/download.hbs"
    }
  });

  _exports.default = _default;
});