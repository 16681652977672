define("lh4/components/reports/table-wrapper", ["exports", "moment", "lh4/enums/timezone-long"], function (_exports, _moment, _timezoneLong) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Get limited locations array if limit is set for the report
   *
   * @param {object} context - "this" component
   * @returns {Array<string>} array of locations
   */
  function getLocations(context) {
    var _context$generatedRep, _context$generatedRep2, _context$generatedRep3;

    var selectedLocations = context.get('selectedLocations');
    var limit = (_context$generatedRep = context.generatedReport) === null || _context$generatedRep === void 0 ? void 0 : (_context$generatedRep2 = _context$generatedRep.params) === null || _context$generatedRep2 === void 0 ? void 0 : (_context$generatedRep3 = _context$generatedRep2.locations) === null || _context$generatedRep3 === void 0 ? void 0 : _context$generatedRep3.limit;
    return limit ? selectedLocations.slice(0, limit) : selectedLocations;
  }
  /**
   * Get Export URL
   *
   * @param {object} context - "this" component
   * @param {'csv'|'xls'|'pdf'} exportType - export format
   * @returns {string} export URL
   */


  function getExportUrl(context, exportType, sortBy, sortOrder) {
    return context.reportApi.getReportExportUrl(context.get('enabledProd'), context.get('reportType'), {
      startsAt: context.get('startsAt'),
      endsAt: context.get('endsAt'),
      locations: getLocations(context),
      sortBy: sortBy,
      sortOrder: sortOrder,
      intradayPeriodGroupGuids: context.get('intradayPeriodGroupGuids'),
      revenueCenterGuids: context.get('revenueCenterGuids')
    }, exportType);
  }

  var DEFAULT_TIMEZONE = 'UTC';

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    reportApi: Ember.inject.service('reports/report-api'),
    metrics: Ember.inject.service(),
    browser: Ember.inject.service(),
    isLoading: false,
    classNameBindings: ['enabledProd', 'reportType'],
    searchError: false,
    searchableReportList: ['Ticket Detail (Closed)', 'Ticket Detail (Open)', 'Transactions'],
    searchableReport: Ember.computed(function getter() {
      var reportName = this.get('searchableReportList').includes(this.get('currentReport.name'));

      if (reportName) {
        return true;
      }

      return false;
    }),
    disableCSV: Ember.computed.not('exportTypes.csv'),
    disableXLS: Ember.computed.not('exportTypes.xls'),
    disablePDF: Ember.computed.not('exportTypes.pdf'),
    pdfURL: Ember.computed('sortBy', 'sortOrder', function getter() {
      if (this.sortBy && this.sortOrder) {
        return getExportUrl(this, 'pdf', this.sortBy, this.sortOrder);
      }

      return getExportUrl(this, 'pdf');
    }),
    csvURL: Ember.computed(function getter() {
      return getExportUrl(this, 'csv');
    }),
    xlsURL: Ember.computed(function getter() {
      return getExportUrl(this, 'xls');
    }),
    sortBy: null,
    sortOrder: null,
    timeZone: Ember.computed('selectedLocations.[]', function getter() {
      var location;

      if (this.selectedLocations[0]) {
        location = this.store.peekRecord('location', this.selectedLocations[0]);
      }

      var timezone = location ? location.timezone : DEFAULT_TIMEZONE;

      var timezoneAbbr = _moment.default.tz(timezone).format('z');

      return "".concat(timezoneAbbr, " (").concat(_timezoneLong.default[timezoneAbbr], ")");
    }),
    actions: {
      toggleFavorite: function toggleFavorite() {
        this.onToggleFavorite();
      },
      setSortBy: function setSortBy(sortBy) {
        this.set('sortBy', sortBy);
      },
      setSortOrder: function setSortOrder(sortOrder) {
        this.set('sortOrder', sortOrder);
      },
      trackReportDownload: function trackReportDownload(type) {
        this.metrics.trackEvent('GoogleAnalyticsWithCustomDimensions', {
          eventCategory: 'reports',
          eventAction: 'download',
          eventLabel: "".concat(this.get('enabledProd'), " - ").concat(this.get('reportType'), " - ").concat(type)
        });
      },
      printTable: function printTable() {
        var _this = this;

        this.set('isLoading', true);
        this.metrics.trackEvent('GoogleAnalyticsWithCustomDimensions', {
          eventCategory: 'reports',
          eventAction: 'print',
          eventLabel: "".concat(this.get('enabledProd'), " - ").concat(this.get('reportType'))
        });
        this.reportApi.fetchReportForPrint(this.get('enabledProd'), this.generatedReportType, {
          startsAt: this.get('startsAt'),
          endsAt: this.get('endsAt'),
          locations: getLocations(this),
          intradayPeriodGroupGuids: this.intradayPeriodGroupGuids,
          revenueCenterGuids: this.revenueCenterGuids
        }).then(function (res) {
          switch (_this.browser.name) {
            case 'firefox':
              window.open(window.URL.createObjectURL(res));
              break;

            case 'ie':
              window.navigator.msSaveOrOpenBlob(res);
              break;

            default:
              {
                var printFrame = document.querySelector("#".concat(_this.elementId, " .print-frame"));

                printFrame.onload = function onload() {
                  printFrame.contentWindow.focus(); // Required for IE

                  printFrame.contentWindow.print();
                };

                printFrame.setAttribute('src', window.URL.createObjectURL(res));
              }
          }
        }).finally(function () {
          _this.set('isLoading', false);
        });
      },
      searchReport: function searchReport(searchText) {
        this.sendAction('searchReport', searchText);
      }
    }
  });

  _exports.default = _default;
});