define("lh4/mirage/routes/epro", ["exports", "moment", "lh4/mirage/fixtures/ticket-summary"], function (_exports, _moment, _ticketSummary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageEproServer;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function setMirageEproServer(serverObject) {
    var server = serverObject; // Employees

    server.get('/echo-pro/employees', function (_ref, request) {
      var eproEmployees = _ref.eproEmployees;
      return {
        employees: eproEmployees.where({
          isActive: request.queryParams.isActive,
          locationId: request.queryParams.location
        }).models
      };
    });
    server.get('/echo-pro/employees/:employeeId', function (_ref2, request) {
      var eproEmployees = _ref2.eproEmployees;
      return {
        employee: eproEmployees.where({
          guid: request.params.employeeId
        }).models
      };
    });
    server.put('/echo-pro/employees/:employeeId', function (schema, request) {
      var employee = schema.eproEmployees.findBy({
        guid: request.params.employeeId
      });
      return employee.update(JSON.parse(request.requestBody));
    }); // Employee Shifts

    server.get('/echo-pro/employee-shifts', function () {
      return {
        employeeShifts: [],
        meta: {
          count: 0
        }
      };
    }); // Jobs

    server.get('/echo-pro/jobs', function (schema, _ref3) {
      var queryParams = _ref3.queryParams;
      var jobs = schema.eproJobs.all().models;

      if (queryParams.isTipped) {
        var booleanValue = queryParams.isTipped === 'true';
        jobs = jobs.filter(function (job) {
          return job.isTipped === booleanValue;
        });
      }

      return {
        meta: {
          count: jobs.length
        },
        jobs: jobs
      };
    });
    server.get('/echo-pro/jobs/:guid', function (schema, _ref4) {
      var params = _ref4.params;
      var guid = params.guid;
      var jobs = schema.eproJobs.all().models;
      var job = jobs.find(function (it) {
        return it.guid === guid;
      });
      return {
        meta: {
          count: 1
        },
        job: [job]
      };
    }); // Tip Share By Titles

    server.get('/echo-pro/tip-share-rules', function (schema) {
      var tipShareRules = schema.eproTipShareRules.all().models.map(function (tipShareRule) {
        return _objectSpread(_objectSpread({}, tipShareRule.attrs), {}, {
          tipSharePayee: schema.eproJobs.findBy({
            guid: tipShareRule.tipSharePayeeGuid
          }),
          tipSharePayor: schema.eproJobs.findBy({
            guid: tipShareRule.tipSharePayorGuid
          })
        });
      });
      return {
        meta: {
          count: tipShareRules.length
        },
        tipShareRules: tipShareRules
      };
    }); // Permissions

    server.get('/echo-pro/permissions', function (schema) {
      return {
        permissions: schema.eproPermissions.all().models
      };
    }); // Settings

    server.get('/echo-pro/settings', function (_ref5) {
      var settings = _ref5.settings;
      return {
        settings: settings.all().models
      };
    }); // All Departments

    server.get('/echo-pro/departments', function (_ref6) {
      var departments = _ref6.departments;
      return {
        departments: departments.all().models
      };
    }); // Get Department by ID

    server.get('/echo-pro/departments/:departmentId', function (schema, request) {
      return {
        departments: schema.departments.where({
          guid: request.params.departmentId
        }).models
      };
    }); // All Course

    server.get('/echo-pro/courses', function (schema) {
      return {
        courses: schema.eproCourses.all().models
      };
    }); // Get Course by ID

    server.get('/echo-pro/courses/:courseId', function (schema, request) {
      return {
        courses: schema.eproCourses.where({
          guid: request.params.courseId
        }).models
      };
    }); // Taxes

    server.get('/echo-pro/taxes', function (schema) {
      return {
        taxes: schema.eproTaxes.all().models
      };
    }); // Get Taxes by Id

    server.get('/echo-pro/taxes/:taxId', function (schema, request) {
      return {
        taxes: schema.eproTaxes.where({
          guid: request.params.taxId
        }).models
      };
    });
    server.put('/echo-pro/taxes/:taxId', function (schema, request) {
      var employee = schema.eproTaxes.findBy({
        guid: request.params.taxId
      });
      return employee.update(JSON.parse(request.requestBody));
    }); // Revenue Classes

    server.get('/echo-pro/revenue-classes', function (_ref7) {
      var revenueClasses = _ref7.revenueClasses;
      return {
        revenueClasses: revenueClasses.all().models
      };
    }); // Item Tags

    server.get('/echo-pro/item-tags', function (schema) {
      return {
        itemTags: schema.eproItemTags.all().models
      };
    }); // Price Schedule Groups

    server.get('/echo-pro/price-schedule-groups', function (schema) {
      return {
        priceScheduleGroups: schema.eproPriceScheduleGroups.all().models
      };
    }); // Get Price Schedule Groups by ID

    server.get('/echo-pro/price-schedule-groups/:priceScheduleId', function (schema, request) {
      return {
        priceScheduleGroup: schema.eproPriceScheduleGroups.where({
          guid: request.params.priceScheduleId
        }).models
      };
    }); // Dynamic Pricing

    server.get('/echo-pro/availability-price/:locationId/dynamic-pricing', function (schema) {
      return {
        dynamicPrices: schema.eproDynamicPrices.all().models
      };
    }); // Get Dynamic Pricing by ID

    server.get('/echo-pro/availability-price/:locationId/dynamic-pricing/:scheduleTitleGuid', function (schema, request) {
      return {
        dynamicPrice: schema.eproDynamicPrices.where({
          guid: request.params.scheduleTitleGuid
        }).models
      };
    }); // Post Dynamic Pricing

    server.post('/echo-pro/availability-price/:locationId/dynamic-pricing', function (schema, request) {
      var payload = JSON.parse(request.requestBody);
      return {
        dynamicPrice: _objectSpread(_objectSpread({}, payload), {}, {
          guid: 'test-guid',
          revenueCenterGuids: [],
          overrideDates: [],
          isDateOverrideEnabled: false,
          intradayPeriodGroupGuids: [],
          schedules: []
        })
      };
    }); // Put Dynamic Pricing

    server.put('/echo-pro/availability-price/:locationId/dynamic-pricing/:scheduleTitleGuid', function (schema, request) {
      var payload = JSON.parse(request.requestBody);
      return {
        dynamicPrice: _objectSpread(_objectSpread({}, payload), {}, {
          revenueCenterGuids: [],
          overrideDates: [],
          isDateOverrideEnabled: false,
          intradayPeriodGroupGuids: [],
          schedules: []
        })
      };
    }); // Order Types

    server.get('/echo-pro/order-types', function (schema) {
      return {
        orderTypes: schema.eproOrderTypes.all().models
      };
    }); // Discounts

    server.get('/echo-pro/discounts', function (schema) {
      return {
        discounts: schema.eproDiscounts.all().models
      };
    }); // Get Discounts by Id

    server.get('/echo-pro/discounts/:discountId', function (schema, request) {
      return {
        discounts: schema.eproDiscounts.where({
          guid: request.params.discountId
        }).models
      };
    }); // Tenders

    server.get('/echo-pro/tenders', function (schema) {
      return {
        tenders: schema.eproTenders.all().models
      };
    }); // Get Tenders by Id

    server.get('/echo-pro/tenders/:tenderId', function (schema, request) {
      return {
        tenders: schema.eproTenders.where({
          guid: request.params.tenderId
        }).models
      };
    }); // Printers

    server.get('/echo-pro/printers', function (schema) {
      return {
        printers: schema.eproPrinters.all().models
      };
    });
    server.get('/echo-pro/order-types/:typeId', function (schema, request) {
      return {
        orderTypes: schema.eproOrderTypes.where({
          guid: request.params.typeId
        }).models
      };
    }); // Surcharges

    server.get('/echo-pro/accounting/surcharges', function (schema) {
      return {
        surcharges: schema.eproSurcharges.all().models
      };
    }); // Get Surcharges by Id

    server.get('/echo-pro/accounting/surcharges/:surchargeId', function (schema, request) {
      return {
        surcharges: schema.eproSurcharges.where({
          guid: request.params.surchargeId
        }).models
      };
    }); // Get Gratuities

    server.get('/echo-pro/gratuities', function (schema) {
      return {
        gratuities: schema.eproGratuities.all().models
      };
    }); // Get Gratuity by Id

    server.get('/echo-pro/gratuities/:gratuityId', function (schema, request) {
      return {
        gratuities: schema.eproGratuities.where({
          guid: request.params.gratuityId
        }).models
      };
    }); // Get Accounting Items

    server.get('/echo-pro/accounting/items', function (schema) {
      return {
        items: schema.eproItems.all().models
      };
    }); // Get Items

    server.get('/echo-pro/items', function (schema, _ref8) {
      var queryParams = _ref8.queryParams;
      var items = schema.eproItems.all().models;

      if (queryParams.searchName === 'search') {
        if (queryParams.fields === 'guid') {
          return {
            meta: {
              count: 2
            },
            items: [{
              guid: '3'
            }, {
              guid: '4'
            }]
          };
        }

        return {
          items: schema.eproItems.find(['3', '4']).models
        };
      }

      if (queryParams.limit) {
        var limit = queryParams.limit === 'all' ? items.length + 1 : parseInt(queryParams.limit, 10);
        var offset = parseInt(queryParams.offset, 10) || 0;
        return {
          meta: {
            count: items.length
          },
          items: items.slice(offset, offset + limit - 1)
        };
      }

      return {
        items: items
      };
    }); // Get Epro Items

    server.get('/echo-pro/items/:itemId', function (schema, request) {
      return {
        items: schema.eproItems.where({
          guid: request.params.itemId
        }).models
      };
    }); // Post Items

    server.post('/echo-pro/items', function (schema, request) {
      return JSON.parse(request.requestBody);
    }); // Tax Sets

    server.get('/echo-pro/accounting/tax-sets', function (schema) {
      return {
        taxSets: schema.eproTaxSets.all().models
      };
    }); // Get Tax Sets by Id

    server.get('/echo-pro/accounting/tax-sets/:taxSetId', function (schema, request) {
      return {
        taxSets: schema.eproTaxSets.where({
          guid: request.params.taxSetId
        }).models
      };
    }); // Modifiers

    server.get('/echo-pro/modifiers', function (schema, _ref9) {
      var queryParams = _ref9.queryParams;
      var modifiers = schema.eproModifiers.all().models;

      if (queryParams.name === 'search') {
        if (queryParams.fields === 'guid') {
          return {
            meta: {
              count: 2
            },
            modifiers: [{
              guid: 3
            }, {
              guid: 4
            }]
          };
        }

        return {
          modifiers: schema.eproModifiers.find([3, 4]).models
        };
      }

      if (queryParams.limit) {
        var limit = queryParams.limit === 'all' ? modifiers.length + 1 : parseInt(queryParams.limit, 10);
        var offset = parseInt(queryParams.offset, 10) || 0;
        return {
          meta: {
            count: modifiers.length
          },
          modifiers: modifiers.slice(offset, offset + limit - 1)
        };
      }

      return {
        modifiers: modifiers
      };
    }); // Modifier by id

    server.get('/echo-pro/modifiers/:modifierId', function (schema, request) {
      return {
        modifiers: schema.eproModifiers.where({
          guid: request.params.modifierId
        }).models
      };
    }); // Post Modifier

    server.post('/echo-pro/modifiers', function (schema, request) {
      return JSON.parse(request.requestBody);
    }); // Put Modifier

    server.put('/echo-pro/modifiers/:modifierId', function (schema, request) {
      var modifier = schema.eproModifiers.findBy({
        guid: request.params.modifierId
      });
      return modifier.update(JSON.parse(request.requestBody));
    }); // Pay Rates

    server.get('/echo-pro/pay-rates', function (schema, request) {
      return {
        payRates: schema.eproPayRates.where({
          guid: request.params.modifierId
        }).models
      };
    }); // Modifier Sets

    server.get('/echo-pro/modifier-sets', function (schema, _ref10) {
      var queryParams = _ref10.queryParams;
      var modifierSets = schema.eproModifierSets.all().models;

      if (queryParams.searchName === 'search') {
        if (queryParams.fields === 'guid') {
          return {
            meta: {
              count: 2
            },
            modifierSets: [{
              guid: '3'
            }, {
              guid: '4'
            }]
          };
        }

        return {
          modifierSets: schema.eproModifierSets.find(['3', '4']).models
        };
      }

      if (queryParams.limit) {
        var limit = queryParams.limit === 'all' ? modifierSets.length + 1 : parseInt(queryParams.limit, 10);
        var offset = parseInt(queryParams.offset, 10) || 0;
        return {
          meta: {
            count: modifierSets.length
          },
          modifierSets: modifierSets.slice(offset, offset + limit - 1)
        };
      }

      return {
        modifierSets: modifierSets
      };
    }); // Modifier Sets

    server.get('/echo-pro/modifier-sets/:editId', function (schema, request) {
      return {
        modifierSets: schema.eproModifierSets.where({
          guid: request.params.editId
        }).models
      };
    }); // Modifier Sets Maps

    server.get('/echo-pro/modifier-set-maps', function (schema) {
      return {
        modifierSets: schema.eproModifierSetMaps.all().models
      };
    }); // Terminals

    server.get('/echo-pro/terminals', function (schema, request) {
      return {
        terminals: schema.eproTerminals.where({
          locationId: request.queryParams.location
        }).models
      };
    }); // Set API namespace to hit the v2 endpoint

    server.namespace = '/api/v2'; // Location Settings

    server.get('/echo-pro/settings', function (_ref11, _ref12) {
      var eproSettingsManagedMenus = _ref11.eproSettingsManagedMenus,
          eproSettingsCustomers = _ref11.eproSettingsCustomers,
          locations = _ref11.locations;
      var _ref12$queryParams = _ref12.queryParams,
          type = _ref12$queryParams.type,
          name = _ref12$queryParams.name,
          locationId = _ref12$queryParams.location;

      if (type === 'managed_menus' && Number(locationId) === 256) {
        var settings = eproSettingsManagedMenus.findBy({
          locationId: locationId
        });
        var managedMenus = settings.attrs;
        delete managedMenus.locationId;
        delete managedMenus.id;
        return {
          settings: {
            locationId: locationId,
            managed_menus: managedMenus
          }
        };
      }

      if (type === 'business' && name === 'end_of_day_time') {
        var eod = 14400000; // default 4:00 AM

        if (Number(locationId) === 111) {
          eod = 3600000; // 1:00 AM
        }

        if (Number(locationId) === 222) {
          eod = 7200000; // 2:00 AM
        }

        if (Number(locationId) === 333) {
          eod = 10800000; // 3:00 AM
        }

        if (Number(locationId) === 999) {
          eod = 0; // 12:00 AM
        }

        return {
          settings: {
            locationId: locationId || 1,
            business: {
              end_of_day_time: eod
            }
          }
        };
      }

      var location = locations.findBy({
        id: locationId
      });
      return {
        settings: {
          locationId: locationId || 1,
          accounting: {
            breaksPaid: 'always',
            firstDailyOvertimeThreshold: '1.00',
            firstOvertimeMultiplier: '1.00',
            firstWeeklyOvertimeThreshold: '1.00',
            overtimeCalculation: 'Monthly',
            payPeriodType: 'Weekly',
            secondDailyOvertimeThreshold: '1.00',
            secondOvertimeMultiplier: '1.00',
            secondWeeklyOvertimeThreshold: '1.00',
            setting: '1.00'
          },
          business: {
            acceptTips: '1.00',
            addUpGratuitiesWithTips: '1.00',
            allowCustomStartingCash: '1.00',
            allowOpenDiscounts: '1.00',
            allowOpenDiscountsForItems: '1.00',
            allowOpenDiscountsForTickets: '1.00',
            autoCloseChangeDialog: '1.00',
            autoCloseChangeDialogTime: '1.00',
            creditCardSignatureLineThreshold: '1.00',
            creditCardThresholdAmount: '1.00',
            drawerStartingCash: '1.00',
            emailMerchantAddress: '1.00',
            enableQuickTableSelection: '1.00',
            endOfDayTime: '1.00',
            finalizeCreditCardPaymentsImmediatelyWithNoTip: '1.00',
            includeInclusiveTaxInSales: locationId === '6' ? '1' : '0',
            lockStationAttempts: '1.00',
            lockStationTime: '1.00',
            messageOfTheDay: '1.00',
            preAuthAmount: '1.00',
            preAuthEnabled: '1.00',
            predefinedOpenDiscountAmount1: '1.00',
            predefinedOpenDiscountAmount2: '1.00',
            predefinedOpenDiscountAmount3: '1.00',
            predefinedOpenDiscountAmount4: '1.00',
            predefinedOpenDiscountPercent1: '1.00',
            predefinedOpenDiscountPercent2: '1.00',
            predefinedOpenDiscountPercent3: '1.00',
            predefinedOpenDiscountPercent4: '1.00',
            printCreditCardSignatureLine: '1.00',
            printPaymentSlips: '1.00',
            printPaymentSlipsStrategy: '1.00',
            showDrawerEquations: '1.00',
            startOfWeek: 'Monday',
            timeoutTime: '1.00',
            tipReductionEnabled: '1.00',
            tipReductionPercent: '1.00',
            tipsFromCashDrawer: '1.00',
            userStartingCash: '1.00'
          },
          customer: eproSettingsCustomers.all().models[0],
          managed_menus: {
            menuManaged: location.corpId ? '1' : '0'
          },
          processing: {
            name: 'shift4'
          }
        }
      };
    });
    server.put('/echo-pro/settings', function (_ref13, _ref14) {
      var eproSettingsManagedMenus = _ref13.eproSettingsManagedMenus;
      var requestBody = _ref14.requestBody;

      var _JSON$parse = JSON.parse(requestBody),
          _JSON$parse$settings = _JSON$parse.settings,
          managedMenus = _JSON$parse$settings.managed_menus,
          locationId = _JSON$parse$settings.locationId;

      var settings = eproSettingsManagedMenus.findBy({
        locationId: JSON.parse(requestBody).settings.locationId
      });
      settings.update(managedMenus);
      return {
        settings: {
          locationId: locationId,
          managed_menus: managedMenus
        }
      };
    });
    server.get('/echo-pro/register-settings/:locationId', function () {
      return {
        registerSettings: [{
          name: 'payment_device_model',
          type: 'hardware',
          value: 'ONBOARD_READER'
        }]
      };
    }); // Pay Periods

    server.get('/echo-pro/pay-periods/:locationId', function (_ref15) {
      var eproPayPeriods = _ref15.eproPayPeriods;
      return {
        payPeriods: eproPayPeriods.all().models
      };
    });
    server.get('/echo-pro/pay-periods/:locationId/:guid', function (_ref16, _ref17) {
      var eproPayPeriods = _ref16.eproPayPeriods;
      var params = _ref17.params;
      return {
        payPeriods: eproPayPeriods.where({
          guid: params.guid
        }).models
      };
    }); // Timeclock Shifts

    server.get('/echo-pro/time-clock-shifts', function (_ref18, _ref19) {
      var _filter$guid, _filter$locationId, _filter$voided;

      var eproTimeClockShifts = _ref18.eproTimeClockShifts;
      var queryParams = _ref19.queryParams;
      var filter = queryParams.filter && JSON.parse(queryParams.filter);

      if (!filter) {
        return {
          timeClockShifts: eproTimeClockShifts.all().models
        };
      }

      var where = {};

      if (typeof (filter === null || filter === void 0 ? void 0 : (_filter$guid = filter.guid) === null || _filter$guid === void 0 ? void 0 : _filter$guid.$eq) !== 'undefined') {
        where.guid = filter.guid.$eq;
      }

      if (typeof (filter === null || filter === void 0 ? void 0 : (_filter$locationId = filter.locationId) === null || _filter$locationId === void 0 ? void 0 : _filter$locationId.$eq) !== 'undefined') {
        where.locationId = filter.locationId.$eq;
      }

      if (typeof (filter === null || filter === void 0 ? void 0 : (_filter$voided = filter.voided) === null || _filter$voided === void 0 ? void 0 : _filter$voided.$eq) !== 'undefined') {
        where.voided = filter.voided.$eq;
      }

      var timeClockShifts = eproTimeClockShifts.where(where).models;
      var timeFilters = filter === null || filter === void 0 ? void 0 : filter.$or;
      return timeFilters ? {
        timeClockShifts: timeClockShifts.filter(function (timeClockShift) {
          return timeFilters.some(function (timeFilter) {
            var key = Object.keys(timeFilter.$and[0])[0];

            var _timeFilter$$and$map = timeFilter.$and.map(function (it) {
              return (0, _moment.default)(Object.values(it[key])[0]);
            }),
                _timeFilter$$and$map2 = _slicedToArray(_timeFilter$$and$map, 2),
                from = _timeFilter$$and$map2[0],
                to = _timeFilter$$and$map2[1];

            return (0, _moment.default)(timeClockShift[key]).isSameOrAfter(from) && (0, _moment.default)(timeClockShift[key]).isSameOrBefore(to);
          });
        })
      } : {
        timeClockShifts: timeClockShifts
      };
    });
    server.get('/echo-pro/time-clock-shifts/:guid', function (_ref20, _ref21) {
      var eproTimeClockShifts = _ref20.eproTimeClockShifts;
      var guid = _ref21.params.guid;
      return {
        timeClockShift: eproTimeClockShifts.findBy({
          guid: guid
        })
      };
    });
    server.put('/echo-pro/time-clock-shifts/:guid', function (_ref22, _ref23) {
      var eproTimeClockShifts = _ref22.eproTimeClockShifts;
      var guid = _ref23.params.guid,
          requestBody = _ref23.requestBody;
      var payload = JSON.parse(requestBody);
      var segments = (payload.timeClockSegments || []).map(function (segment) {
        if (segment.guid) {
          return eproTimeClockShifts.findOrCreateBy(segment).toJSON();
        }

        var newSegment = _objectSpread({}, segment);

        delete newSegment.guid;
        return server.create('epro-time-clock-segment', newSegment).toJSON();
      });
      var timeClockShift = eproTimeClockShifts.findBy({
        guid: guid
      });
      timeClockShift.update(_objectSpread(_objectSpread({}, payload), {}, {
        timeClockSegments: segments
      }));
      return {
        timeClockShift: timeClockShift
      };
    });
    server.post('/echo-pro/time-clock-shifts', function (_, _ref24) {
      var requestBody = _ref24.requestBody;
      var payload = JSON.parse(requestBody);
      delete payload.guid;
      var timeClockShift = server.create('epro-time-clock-shift', payload);
      return {
        timeClockShift: timeClockShift
      };
    }); // Set namespace back to V1 endpoint

    server.namespace = '/api/v1'; // Ingredients

    server.get('/echo-pro/ingredients', function (_ref25) {
      var eproIngredients = _ref25.eproIngredients;
      return {
        ingredients: eproIngredients.all().models
      };
    });
    server.get('/echo-pro/ingredients/:ingredientId', function (_ref26, request) {
      var eproIngredients = _ref26.eproIngredients;
      return {
        ingredient: eproIngredients.where({
          guid: request.params.ingredientId
        }).models
      };
    });
    server.put('/echo-pro/ingredients/:ingredientId', function (schema, request) {
      var ingredient = schema.eproIngredient.findBy({
        guid: request.params.ingredientId
      });
      return ingredient.update(JSON.parse(request.requestBody));
    });
    server.post('/echo-pro/ingredients', function (schema, request) {
      return JSON.parse(request.requestBody);
    });
    server.delete('/echo-pro/ingredients/:ingredientId', {});
    server.get('/echo-pro/stposUiStylesheets', function () {
      return {
        stposUiStylesheets: []
      };
    });
    server.get('/echo-pro/:locationId/day-parts', function () {
      return [];
    });
    server.get('/echo-pro/day-parts/:locationId', function () {
      return [];
    }); // Revenue centers

    server.get('/echo-pro/revenue-centers', function (_ref27, request) {
      var eproRevenueCenters = _ref27.eproRevenueCenters;
      return {
        revenueCenters: eproRevenueCenters.where({
          locationId: request.queryParams.location
        }).models
      };
    });
    server.get('/echo-pro/revenue-centers/:revenueCenterGuid', function (_ref28, request) {
      var eproRevenueCenters = _ref28.eproRevenueCenters;
      return {
        revenueCenters: eproRevenueCenters.where({
          guid: request.params.revenueCenterGuid
        }).models
      };
    });
    server.delete('/echo-pro/revenue-centers/:revenueCenterGuid', function (_ref29, request) {
      var eproRevenueCenters = _ref29.eproRevenueCenters;
      var revenueCenter = eproRevenueCenters.where({
        guid: request.params.revenueCenterGuid
      }).models[0];
      revenueCenter.destroy();
      return {
        revenueCenter: revenueCenter
      };
    }); // Revenue center configs

    server.get('/echo-pro/revenue-center-configs', function (_ref30, request) {
      var eproRevenueCenterConfigs = _ref30.eproRevenueCenterConfigs;
      return {
        revenueCenterConfigs: eproRevenueCenterConfigs.where({
          locationId: request.queryParams.location
        }).models
      };
    }); // Get Intraday Period Groups

    server.get('/echo-pro/:locationId/day-parts/groups', function (_ref31, request) {
      var eproIntradayPeriodGroups = _ref31.eproIntradayPeriodGroups;
      return eproIntradayPeriodGroups.where({
        locationId: request.params.locationId
      }).models;
    }); // Update Intraday Period Groups

    server.put('/echo-pro/:locationId/day-parts/groups/:intradayPeriodGroupGuid', function (_ref32, request) {
      var eproIntradayPeriodGroups = _ref32.eproIntradayPeriodGroups;
      var group = eproIntradayPeriodGroups.findBy({
        guid: request.params.intradayPeriodGroupGuid
      });
      return group.update({
        intradayPeriodGroupName: request.name
      });
    }); // Get Intraday Period Group by id

    server.get('/echo-pro/:locationId/day-parts/groups/:categoryGuid', function (_ref33, request) {
      var eproIntradayPeriodGroups = _ref33.eproIntradayPeriodGroups;
      return eproIntradayPeriodGroups.where({
        guid: request.params.categoryGuid
      }).models;
    });
    server.get('/echo-pro/tickets/:locationId/summary', function () {
      return _ticketSummary.default;
    });
  }
});