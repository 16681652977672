define("lh4/templates/authenticated/launch-control", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3GPmI8Yh",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"outlet\"],false],[0,\"\\n\"],[4,\"ember-wormhole\",null,[[\"to\"],[\"modals\"]],{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[8],[0,\"\\n\\t\\t\"],[5,\"ui/launch-control/prompt-dialog-box\",[],[[\"@isOpen\",\"@onClose\",\"@onDiscard\",\"@onConfirm\",\"@title\",\"@content\",\"@discardText\",\"@confirmText\"],[[23,0,[\"transitionModal\",\"isOpen\"]],[23,0,[\"transitionModal\",\"close\"]],[23,0,[\"transitionModal\",\"discardChanges\"]],[23,0,[\"transitionModal\",\"saveChanges\"]],[22,\"title\"],[22,\"content\"],[22,\"discard\"],[22,\"confirm\"]]]],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/launch-control.hbs"
    }
  });

  _exports.default = _default;
});