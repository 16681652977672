define("lh4/routes/authenticated/internet-payments/settings/account-maintenance", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/mobile-scroll-route-mixin", "lh4/mixins/lighthouse-permissions-mixin"], function (_exports, _authenticatedRouteMixin, _mobileScrollRouteMixin, _lighthousePermissionsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _mobileScrollRouteMixin.default, _lighthousePermissionsMixin.default, {
    ajax: Ember.inject.service(),
    location: Ember.inject.service(),
    session: Ember.inject.service(),
    requiredPermissions: ['manage account-maintenance'],
    model: function model() {
      var userId = this.get('session.data.authenticated.sub');
      return Ember.RSVP.hash({
        location: this.location.model,
        token: this.ajax.request("/api/v1/accounts/".concat(userId, "/integration-token")).then(function (res) {
          return res.token;
        })
      });
    }
  });

  _exports.default = _default;
});