define("lh4/serializers/echo-pro/department", ["exports", "lh4/serializers/echo-pro/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      items: {
        embedded: 'always',
        serialize: 'record'
      },
      dynamicPricing: {
        embedded: 'always'
      },
      availability: {
        embedded: 'always'
      }
    },
    normalize: function normalize(modelClass, jsonToNormalize) {
      var json = jsonToNormalize;

      if (json.stposUiStylesheetObjectLinks) {
        json.stposUiStylesheetGuid = Ember.A(json.stposUiStylesheetObjectLinks).reduce(function (guid, stposUiStylesheetObjectLink) {
          return (stposUiStylesheetObjectLink === null || stposUiStylesheetObjectLink === void 0 ? void 0 : stposUiStylesheetObjectLink.stposUiStylesheetGuid) || guid;
        }, '');
      }

      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      return this._super.apply(this, [modelClass, json].concat(args));
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      if (json.availability && json.availability.schedules) {
        json.availability.schedules = json.availability.schedules.filter(function (schedule) {
          return !schedule.isVoided;
        });
      }

      if (json.stposUiStylesheetGuid) {
        json.stposUiStylesheetObjectLinks = [{
          locationId: json.locationId,
          isActive: true,
          departmentGuid: json.id,
          stposUiStylesheetGuid: json.stposUiStylesheetGuid
        }];
        delete json.stposUiStylesheetGuid;
      } else {
        json.stposUiStylesheetObjectLinks = [];
        delete json.stposUiStylesheetGuid;
      }

      return json;
    }
  });

  _exports.default = _default;
});