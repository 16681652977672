define("lh4/mirage/routes/location-groups-v2", ["exports", "ember-cli-mirage", "lh4/mirage/fixtures/location-groups-v2"], function (_exports, _emberCliMirage, _locationGroupsV) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageLocationGroupV2Server;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function setMirageLocationGroupV2Server(server) {
    server.get('/api/v2/location-group', _locationGroupsV.default);
    server.get('/api/v2/location-group/:id', function (schema, _ref) {
      var id = _ref.params.id;

      if (id === '404') {
        return new _emberCliMirage.Response(404);
      }

      if (id === '400') {
        return new _emberCliMirage.Response(400);
      }

      return {
        locationGroup: _toConsumableArray(_locationGroupsV.default).splice(Number(id), 1)[0]
      };
    });
    server.delete('/api/v2/location-group/:id', function () {
      return new _emberCliMirage.Response(204);
    });
    server.post('/api/v2/location-group', function () {
      return new _emberCliMirage.Response(201, {}, {
        locationGroup: _toConsumableArray(_locationGroupsV.default).splice(2, 1)[0]
      });
    });
    server.put('/api/v2/location-group/:id', function (schema, _ref2) {
      var id = _ref2.params.id;
      return new _emberCliMirage.Response(200, {}, {
        locationGroup: _toConsumableArray(_locationGroupsV.default).splice(Number(id), 1)[0]
      });
    });
  }
});