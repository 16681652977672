define("lh4/routes/authenticated/internet-payments/profile", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    can: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      this._super.apply(this, [controller, model].concat(args));

      var routeData = [];
      routeData.push({
        name: 'authenticated.internet-payments.profile.account',
        title: 'Account'
      });

      if (this.can.can('manage location users in general')) {
        routeData.push({
          name: 'authenticated.internet-payments.profile.users',
          title: 'Users'
        });
      }

      if (this.can.can('view mlm locations page in feature-flag')) {
        routeData.push({
          name: 'authenticated.internet-payments.profile.locations',
          title: 'Locations'
        });
      }

      if (this.can.can('view location groups menu item in general')) {
        routeData.push({
          name: 'authenticated.internet-payments.profile.location-groups',
          title: 'Location Groups'
        });
      }

      if (this.can.can('view location groups v2 in general')) {
        routeData.push({
          name: 'authenticated.internet-payments.profile.location-groups-v2',
          title: 'Groups'
        });
      }

      routeData.push({
        name: 'authenticated.internet-payments.profile.two-factor',
        title: 'Two-Factor Authentication'
      });
      routeData.push({
        name: 'authenticated.internet-payments.profile.account-preferences',
        title: 'Account Preferences'
      });
      controller.setProperties({
        routeData: routeData
      });
    }
  });

  _exports.default = _default;
});