define("lh4/models/echo-pro/setting/order-ready-screen", ["exports", "ember-data", "ember-data/relationships", "ember-cp-validations"], function (_exports, _emberData, _relationships, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.COLORS = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({});
  var COLORS = {
    BROWN: '#634339',
    BLACK: '#000',
    RED: '#E21E5C',
    VIOLET: '#6C3DB8',
    PURPLE: '#88039F',
    GREEN: '#0F7B40',
    ORANGE: '#C03D0A',
    TEAL: '#107066',
    BLUE: '#136EF6'
  };
  _exports.COLORS = COLORS;

  var _default = _emberData.default.Model.extend(Validations, {
    autoDisplayDuration: _emberData.default.attr('string'),
    manualDisplayDuration: _emberData.default.attr('string'),
    color: _emberData.default.attr('string'),
    displayModeAuto: _emberData.default.attr('boolean'),
    displayModeManual: _emberData.default.attr('boolean'),
    displayMode: Ember.computed('displayModeManual', 'displayModeAuto', {
      get: function get() {
        if (this.get('displayModeManual')) {
          return 'displayModeManual';
        }

        return 'displayModeAuto';
      },
      set: function set(key, value) {
        if (typeof value === 'string') {
          if (value === 'displayModeManual') {
            this.set('displayModeManual', true);
            this.set('displayModeAuto', false);
          } else if (value === 'displayModeAuto') {
            this.set('displayModeManual', false);
            this.set('displayModeAuto', true);
          }
        }

        return value;
      }
    }),
    enabled: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    displayType: _emberData.default.attr('string', {
      defaultValue: 'order_number'
    }),
    setting: (0, _relationships.belongsTo)('echo-pro/setting')
  });

  _exports.default = _default;
});