define("lh4/enums/location-group/location-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.GROUP_TYPE_NAME = _exports.GROUP_TYPE = void 0;

  var _Object$freeze;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var GROUP_TYPE = Object.freeze({
    LOCATION_GROUP: 'non-exclusive',
    STATEMENT_GROUP: 'exclusive'
  });
  _exports.GROUP_TYPE = GROUP_TYPE;
  var GROUP_TYPE_NAME = Object.freeze((_Object$freeze = {}, _defineProperty(_Object$freeze, GROUP_TYPE.LOCATION_GROUP, 'Location Group'), _defineProperty(_Object$freeze, GROUP_TYPE.STATEMENT_GROUP, 'Statement Group'), _Object$freeze));
  _exports.GROUP_TYPE_NAME = GROUP_TYPE_NAME;
});