define("lh4/templates/components/ui/icons/s4/burger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Efa4oV3Z",
    "block": "{\"symbols\":[\"@onClick\"],\"statements\":[[7,\"svg\",false],[12,\"class\",\"burger-icon\"],[12,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[12,\"width\",\"30\"],[12,\"height\",\"20\"],[12,\"viewBox\",\"0 0 30 20\"],[12,\"fill\",\"none\"],[3,\"on\",[\"click\",[23,1,[]]]],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"fill-rule\",\"evenodd\"],[10,\"clip-rule\",\"evenodd\"],[10,\"d\",\"M0 20H30V16.6667H0V20ZM0 11.6667H30V8.33333H0V11.6667ZM0 0V3.33333H30V0H0Z\"],[10,\"fill\",\"black\"],[10,\"fill-opacity\",\"0.6\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/s4/burger.hbs"
    }
  });

  _exports.default = _default;
});