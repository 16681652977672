define("lh4/mocks/disputes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.countByStatus = _exports.countAll = _exports.getByStatus = _exports.getByToken = _exports.getAll = void 0;
  // Mock data for QA testing since we don't really have a test env for the disputes service
  var DISPUTES = {
    'DF64751A-814B-40D1-B8C7-8CD613CBF861': {
      id: 'DF64751A-814B-40D1-B8C7-8CD613CBF861',
      offsetToken: 'DF64751A-814B-40D1-B8C7-8CD613CBF861',
      date: '2025-01-08',
      caseNumber: 'MOCK-33022378',
      disputeAmount: '1007.50',
      disputeCurrency: 'USD',
      status: 'WON',
      reasonCode: '10.4',
      acceptedAsLost: false,
      caseType: 'PRE_ARBITRATION',
      transaction: {
        date: '2024-12-24 20:53:54',
        id: '1000000001',
        amount: '1007.50',
        currency: 'USD',
        acquirerRefNumber: '10002348732853298498832',
        merchantReference: 'REF-1000000001',
        country: 'US'
      },
      eventHistory: [{
        code: 'FUNDS_RESTORED',
        message: '$1007.50 in dispute funds have been restored to your account.',
        date: '2025-02-03 08:38:47',
        disputeState: {
          id: 'DF64751A-814B-40D1-B8C7-8CD613CBF861',
          date: '2025-01-08',
          caseNumber: 'MOCK-33022378',
          disputeAmount: '1007.50',
          disputeCurrency: 'USD',
          status: 'WON',
          reasonCode: '10.4',
          acceptedAsLost: false,
          caseType: 'PRE_ARBITRATION',
          evidence: {}
        }
      }, {
        code: 'DISPUTE_WON',
        message: 'You won your dispute for $1007.50.',
        date: '2025-02-03 08:38:47',
        disputeState: {
          id: 'DF64751A-814B-40D1-B8C7-8CD613CBF861',
          date: '2025-01-08',
          caseNumber: 'MOCK-33022378',
          disputeAmount: '1007.50',
          disputeCurrency: 'USD',
          status: 'WON',
          reasonCode: '10.4',
          acceptedAsLost: false,
          caseType: 'PRE_ARBITRATION',
          evidence: {}
        }
      }, {
        code: 'FUNDS_WITHDRAWN',
        message: '$1007.50 in dispute funds have been withdrawn from your account.',
        date: '2025-01-29 18:26:51',
        disputeState: {
          id: 'DF64751A-814B-40D1-B8C7-8CD613CBF861',
          date: '2025-01-08',
          caseNumber: 'MOCK-29135760',
          disputeAmount: '1007.50',
          disputeCurrency: 'USD',
          status: 'NEED_RESPONSE',
          reasonCode: '10.4',
          acceptedAsLost: false,
          caseType: 'PRE_ARBITRATION',
          evidence: {},
          daysToAct: 19,
          dueBy: '2025-02-24'
        }
      }, {
        code: 'DISPUTE_UPDATED_TYPE_CHANGED_PRE_ARBITRATION',
        message: 'Dispute for 1007.50 is now in Pre-arbitration and response is required to contest dispute.',
        date: '2025-01-29 18:26:51',
        disputeState: {
          id: 'DF64751A-814B-40D1-B8C7-8CD613CBF861',
          date: '2025-01-08',
          caseNumber: 'MOCK-29135760',
          disputeAmount: '1007.50',
          disputeCurrency: 'USD',
          status: 'NEED_RESPONSE',
          reasonCode: '10.4',
          acceptedAsLost: false,
          caseType: 'PRE_ARBITRATION',
          evidence: {},
          daysToAct: 19,
          dueBy: '2025-02-24'
        }
      }, {
        code: 'FUNDS_RESTORED',
        message: '$1007.50 in dispute funds have been restored to your account.',
        date: '2025-01-29 18:26:51',
        disputeState: {
          id: 'DF64751A-814B-40D1-B8C7-8CD613CBF861',
          date: '2025-01-08',
          caseNumber: 'MOCK-28058502',
          disputeAmount: '1007.50',
          disputeCurrency: 'USD',
          status: 'WON',
          reasonCode: '10.4',
          acceptedAsLost: false,
          caseType: 'CHARGEBACK',
          evidence: {}
        }
      }, {
        code: 'DISPUTE_WON',
        message: 'You won your dispute for $1007.50.',
        date: '2025-01-29 18:26:51',
        disputeState: {
          id: 'DF64751A-814B-40D1-B8C7-8CD613CBF861',
          date: '2025-01-08',
          caseNumber: 'MOCK-28058502',
          disputeAmount: '1007.50',
          disputeCurrency: 'USD',
          status: 'WON',
          reasonCode: '10.4',
          acceptedAsLost: false,
          caseType: 'CHARGEBACK',
          evidence: {}
        }
      }, {
        code: 'DISPUTE_UPDATED_STATUS_CHANGED_AWAITING_INFO',
        message: 'Dispute response submitted & pending review by Analyst or Issuing bank.',
        date: '2025-01-28 18:26:23',
        disputeState: {
          id: 'DF64751A-814B-40D1-B8C7-8CD613CBF861',
          date: '2025-01-08',
          caseNumber: 'MOCK-28058502',
          disputeAmount: '1007.50',
          disputeCurrency: 'USD',
          status: 'AWAITING_INFO',
          reasonCode: '10.4',
          acceptedAsLost: false,
          caseType: 'CHARGEBACK',
          evidence: {}
        }
      }, {
        code: 'FUNDS_WITHDRAWN',
        message: '$1007.50 in dispute funds have been withdrawn from your account.',
        date: '2025-01-08 08:37:54',
        disputeState: {
          id: 'DF64751A-814B-40D1-B8C7-8CD613CBF861',
          date: '2025-01-08',
          caseNumber: 'MOCK-08010776',
          disputeAmount: '1007.50',
          disputeCurrency: 'USD',
          status: 'NEED_RESPONSE',
          reasonCode: '10.4',
          acceptedAsLost: false,
          caseType: 'CHARGEBACK',
          evidence: {},
          daysToAct: 0,
          dueBy: '2025-02-02'
        }
      }, {
        code: 'DISPUTE_CREATED',
        message: 'Your customer has disputed a charge for $1007.50.',
        date: '2025-01-08',
        disputeState: {
          id: 'DF64751A-814B-40D1-B8C7-8CD613CBF861',
          date: '2025-01-08',
          caseNumber: 'MOCK-08010776',
          disputeAmount: '1007.50',
          disputeCurrency: 'USD',
          status: 'NEED_RESPONSE',
          reasonCode: '10.4',
          acceptedAsLost: false,
          caseType: 'CHARGEBACK',
          evidence: {},
          daysToAct: 0,
          dueBy: '2025-02-02'
        }
      }, {
        code: 'TRANSACTION_CREATED',
        message: '$1007.50 transaction was made.',
        date: '2024-12-24 20:53:54'
      }],
      evidence: {},
      acquirerRefNumber: '10002348732853298498832',
      card: {
        last4: '0553',
        brand: 'visa'
      },
      reason: {
        description: 'Other Fraud - Card - Absent Environment',
        longDescription: 'The cardholder did not authorize or participate in a transaction conducted in a Card Not Present (CNP) environment',
        documents: ['Provide invoice or order form or other form of agreement.', 'Provide proof of positive AVS response and proof of delivery to the AVS confirmed address.', 'Advise if the transaction was Verified by Visa-authenticated at time of authorization.', 'Provide a letter or email from the cardholder that states they no longer dispute the transaction.']
      },
      evidenceFileDetails: {}
    },
    'A945B66E-5C7C-4843-A5AA-27E887657A4B': {
      id: 'A945B66E-5C7C-4843-A5AA-27E887657A4B',
      offsetToken: 'A945B66E-5C7C-4843-A5AA-27E887657A4B',
      date: '2025-01-03',
      caseNumber: 'MOCK-03038934',
      disputeAmount: '750.35',
      disputeCurrency: 'USD',
      status: 'LOST',
      reasonCode: '10.4',
      acceptedAsLost: false,
      caseType: 'CHARGEBACK',
      transaction: {
        date: '2024-12-28 11:04:08',
        id: '1000000002',
        amount: '750.35',
        currency: 'USD',
        acquirerRefNumber: '12874938795322349800019',
        merchantReference: 'REF-1000000002',
        country: 'US'
      },
      eventHistory: [{
        code: 'DISPUTE_LOST',
        message: 'Dispute past response timeframe and closed as a loss.',
        date: '2025-02-01 09:33:39',
        disputeState: {
          id: 'A945B66E-5C7C-4843-A5AA-27E887657A4B',
          date: '2025-01-03',
          caseNumber: 'MOCK-03038934',
          disputeAmount: '750.35',
          disputeCurrency: 'USD',
          status: 'LOST',
          reasonCode: '10.4',
          acceptedAsLost: false,
          caseType: 'CHARGEBACK',
          evidence: {}
        }
      }, {
        code: 'DISPUTE_UPDATED_STATUS_CHANGED_CHARGEBACK_NEW',
        message: 'Response required to contest dispute.',
        date: '2025-01-03 12:39:48',
        disputeState: {
          id: 'A945B66E-5C7C-4843-A5AA-27E887657A4B',
          date: '2025-01-03',
          caseNumber: 'MOCK-03038934',
          disputeAmount: '750.35',
          disputeCurrency: 'USD',
          status: 'NEED_RESPONSE',
          reasonCode: '10.4',
          acceptedAsLost: false,
          caseType: 'CHARGEBACK',
          evidence: {},
          daysToAct: 0,
          dueBy: '2025-01-27'
        }
      }, {
        code: 'FUNDS_WITHDRAWN',
        message: '$750.35 in dispute funds have been withdrawn from your account.',
        date: '2025-01-03 08:39:38',
        disputeState: {
          id: 'A945B66E-5C7C-4843-A5AA-27E887657A4B',
          date: '2025-01-03',
          caseNumber: 'MOCK-03038934',
          disputeAmount: '750.35',
          disputeCurrency: 'USD',
          status: 'AWAITING_INFO',
          reasonCode: '10.4',
          acceptedAsLost: false,
          caseType: 'CHARGEBACK',
          evidence: {}
        }
      }, {
        code: 'DISPUTE_CREATED',
        message: 'Your customer has disputed a charge for $750.35.',
        date: '2025-01-03',
        disputeState: {
          id: 'A945B66E-5C7C-4843-A5AA-27E887657A4B',
          date: '2025-01-03',
          caseNumber: 'MOCK-03038934',
          disputeAmount: '750.35',
          disputeCurrency: 'USD',
          status: 'AWAITING_INFO',
          reasonCode: '10.4',
          acceptedAsLost: false,
          caseType: 'CHARGEBACK',
          evidence: {}
        }
      }, {
        code: 'TRANSACTION_CREATED',
        message: '$750.35 transaction was made.',
        date: '2024-12-28 11:04:08'
      }],
      evidence: {},
      acquirerRefNumber: '12874938795322349800019',
      card: {
        last4: '1323',
        brand: 'visa'
      },
      reason: {
        description: 'Other Fraud - Card - Absent Environment',
        longDescription: 'The cardholder did not authorize or participate in a transaction conducted in a Card Not Present (CNP) environment',
        documents: ['Provide invoice or order form or other form of agreement.', 'Provide proof of positive AVS response and proof of delivery to the AVS confirmed address.', 'Advise if the transaction was Verified by Visa-authenticated at time of authorization.', 'Provide a letter or email from the cardholder that states they no longer dispute the transaction.']
      },
      evidenceFileDetails: {}
    },
    '841E8BAF-B0AA-4622-9044-8FCE4A2BB2EB': {
      id: '841E8BAF-B0AA-4622-9044-8FCE4A2BB2EB',
      offsetToken: '841E8BAF-B0AA-4622-9044-8FCE4A2BB2EB',
      date: '2025-02-04',
      caseNumber: 'MOCK-25041306',
      disputeAmount: '1282.55',
      disputeCurrency: 'USD',
      status: 'AWAITING_INFO',
      reasonCode: '10.4',
      acceptedAsLost: false,
      caseType: 'CHARGEBACK',
      transaction: {
        date: '2025-01-14 12:30:39',
        id: '1000000003',
        amount: '1282.55',
        currency: 'USD',
        acquirerRefNumber: '18738959238777783888929',
        merchantReference: 'REF-1000000003',
        country: 'US'
      },
      eventHistory: [{
        code: 'FUNDS_WITHDRAWN',
        message: '$1282.55 in dispute funds have been withdrawn from your account.',
        date: '2025-02-04 09:11:25',
        disputeState: {
          id: '841E8BAF-B0AA-4622-9044-8FCE4A2BB2EB',
          date: '2025-02-04',
          caseNumber: 'MOCK-25041306',
          disputeAmount: '1282.55',
          disputeCurrency: 'USD',
          status: 'AWAITING_INFO',
          reasonCode: '10.4',
          acceptedAsLost: false,
          caseType: 'CHARGEBACK',
          evidence: {}
        }
      }, {
        code: 'DISPUTE_CREATED',
        message: 'Your customer has disputed a charge for $1282.55.',
        date: '2025-02-04',
        disputeState: {
          id: '841E8BAF-B0AA-4622-9044-8FCE4A2BB2EB',
          date: '2025-02-04',
          caseNumber: 'MOCK-25041306',
          disputeAmount: '1282.55',
          disputeCurrency: 'USD',
          status: 'AWAITING_INFO',
          reasonCode: '10.4',
          acceptedAsLost: false,
          caseType: 'CHARGEBACK',
          evidence: {}
        }
      }, {
        code: 'TRANSACTION_CREATED',
        message: '$1282.55 transaction was made.',
        date: '2025-01-14 12:30:39'
      }],
      evidence: {},
      acquirerRefNumber: '18738959238777783888929',
      card: {
        last4: '1173',
        brand: 'visa'
      },
      reason: {
        description: 'Other Fraud - Card - Absent Environment',
        longDescription: 'The cardholder did not authorize or participate in a transaction conducted in a Card Not Present (CNP) environment',
        documents: ['Provide invoice or order form or other form of agreement.', 'Provide proof of positive AVS response and proof of delivery to the AVS confirmed address.', 'Advise if the transaction was Verified by Visa-authenticated at time of authorization.', 'Provide a letter or email from the cardholder that states they no longer dispute the transaction.']
      },
      evidenceFileDetails: {}
    },
    '05114C42-66BC-4EC6-8D34-181BB7EB59D2': {
      id: '05114C42-66BC-4EC6-8D34-181BB7EB59D2',
      offsetToken: '05114C42-66BC-4EC6-8D34-181BB7EB59D2',
      date: '2025-02-03',
      caseNumber: 'MOCK-34012017',
      disputeAmount: '119.00',
      disputeCurrency: 'USD',
      status: 'NEED_RESPONSE',
      reasonCode: '10.4',
      acceptedAsLost: false,
      caseType: 'CHARGEBACK',
      eventHistory: [{
        code: 'FUNDS_WITHDRAWN',
        message: '$119.00 in dispute funds have been withdrawn from your account.',
        date: '2025-02-03 08:38:59',
        disputeState: {
          id: '05114C42-66BC-4EC6-8D34-181BB7EB59D2',
          date: '2025-02-03',
          caseNumber: 'MOCK-34012017',
          disputeAmount: '119.00',
          disputeCurrency: 'USD',
          status: 'NEED_RESPONSE',
          reasonCode: '10.4',
          acceptedAsLost: false,
          caseType: 'CHARGEBACK',
          evidence: {},
          daysToAct: 21,
          dueBy: '2025-02-26'
        }
      }, {
        code: 'DISPUTE_CREATED',
        message: 'Your customer has disputed a charge for $119.00.',
        date: '2025-02-03',
        disputeState: {
          id: '05114C42-66BC-4EC6-8D34-181BB7EB59D2',
          date: '2025-02-03',
          caseNumber: 'MOCK-34012017',
          disputeAmount: '119.00',
          disputeCurrency: 'USD',
          status: 'NEED_RESPONSE',
          reasonCode: '10.4',
          acceptedAsLost: false,
          caseType: 'CHARGEBACK',
          evidence: {},
          daysToAct: 21,
          dueBy: '2025-02-26'
        }
      }],
      evidence: {},
      daysToAct: 21,
      dueBy: '2025-02-26',
      acquirerRefNumber: '11123857834892833848882',
      card: {
        last4: '6017',
        brand: 'visa'
      },
      reason: {
        description: 'Other Fraud - Card - Absent Environment',
        longDescription: 'The cardholder did not authorize or participate in a transaction conducted in a Card Not Present (CNP) environment',
        documents: ['Provide invoice or order form or other form of agreement.', 'Provide proof of positive AVS response and proof of delivery to the AVS confirmed address.', 'Advise if the transaction was Verified by Visa-authenticated at time of authorization.', 'Provide a letter or email from the cardholder that states they no longer dispute the transaction.']
      },
      evidenceFileDetails: {}
    }
  };
  var DISPUTES_LIST = Object.values(DISPUTES);

  var getAll = function getAll() {
    return DISPUTES_LIST;
  };

  _exports.getAll = getAll;

  var getByToken = function getByToken(token) {
    return DISPUTES[token];
  };

  _exports.getByToken = getByToken;

  var getByStatus = function getByStatus(status) {
    return DISPUTES_LIST.filter(function (item) {
      return item.status === status;
    });
  };

  _exports.getByStatus = getByStatus;

  var countAll = function countAll() {
    return getAll().length;
  };

  _exports.countAll = countAll;

  var countByStatus = function countByStatus(status) {
    return getByStatus(status).length;
  };

  _exports.countByStatus = countByStatus;
});