define("lh4/mirage/fixtures/echo-pro/activity-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ECHO_PRO_ACTIVITY_SUMMARY = {
    name: 'Activity Summary',
    custom: true,
    buckets: [{
      guid: null,
      name: 'Totals',
      reports: [{
        name: 'Business Trends',
        rows: [['Headcount', '0'], ['Avg. Head', '$0.00'], ['', ''], ['Open Tickets', '5'], ['Closed Tickets', '0'], ['Avg. Ticket', '$0.00'], ['', '']]
      }]
    }],
    warningText: 'This is a warning message'
  };
  var _default = ECHO_PRO_ACTIVITY_SUMMARY;
  _exports.default = _default;
});