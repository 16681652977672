define("lh4/abilities/echo-pro", ["exports", "ember-can", "lh4/mixins/abilities/ability-mixin", "lh4/utils/permissions", "lh4/utils/external-permissions", "lh4/enums/launch-darkly-feature-flags"], function (_exports, _emberCan, _abilityMixin, _permissions, _externalPermissions, _launchDarklyFeatureFlags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var permissionsArray = [_permissions.default.HBR.EMPLOYEES_AND_JOBS, _permissions.default.HBR.MENU, _permissions.default.HBR.ACCOUNTING, _permissions.default.HBR.TABLE_LAYOUT, _permissions.default.HBR.TIMECLOCK, _permissions.default.HBR.EMPLOYEE_SCHEDULING, _permissions.default.HBR.PAYMENTS, _permissions.default.HBR.CUSTOMERS];

  var _default = _emberCan.Ability.extend(_abilityMixin.default, {
    externalPermissions: Ember.inject.service(),
    launchDarkly: Ember.inject.service('launch-darkly'),
    canView: (0, _abilityMixin.computedAbility)(function getter() {
      return this.hasProduct('echo-pro');
    }),
    canManagePos: (0, _abilityMixin.computedAbility)(function getter() {
      var _this = this;

      return this.get('canView') && permissionsArray.some(function (permission) {
        return _this.hasLocationPermission(permission);
      });
    }),
    canViewAnalyticsLink: (0, _abilityMixin.computedAbility)('externalPermissions.permissions', function getter() {
      return this.externalPermissions.hasExternalPermission(_externalPermissions.default.ANALYTICS_LINK_VISIBILITY);
    }),
    canReorderModifiersForModifierSet: (0, _abilityMixin.computedAbility)('launchDarkly.featureFlags', function getter() {
      return this.launchDarkly.featureFlags[_launchDarklyFeatureFlags.default.ENABLE_MODIFIERS_ORDER_FOR_MODIFER_SET];
    })
  });

  _exports.default = _default;
});