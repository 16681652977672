define("lh4/models/echo-pro/modifier-set-map", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations", "lh4/enums/launch-darkly-feature-flags"], function (_exports, _model, _attr, _emberCpValidations, _launchDarklyFeatureFlags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    value: {
      description: 'Price',
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: true,
        allowString: true,
        // This is help mimic what is happening on the terminal
        lte: 10000000
      }), (0, _emberCpValidations.validator)('length', {
        max: 10
      }), (0, _emberCpValidations.validator)('negative-if-launch-darkly', {
        allowBlank: true,
        allowNegativeFlag: _launchDarklyFeatureFlags.default.ALLOW_NEGATIVE_MODIFIER_AMOUNT,
        message: 'Price must be positive'
      })]
    },
    onlineValue: {
      description: 'Online Price',
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: true,
        allowString: true,
        positive: true,
        // This is help mimic what is happening on the terminal
        lte: 10000000
      }), (0, _emberCpValidations.validator)('length', {
        max: 10
      })]
    }
  });

  var _default = _model.default.extend(Validations, {
    modifierGuid: (0, _attr.default)('string'),
    modifierSetGuid: (0, _attr.default)('string'),
    ordinal: (0, _attr.default)('string', {
      defaultValue: 0
    }),
    value: (0, _attr.default)('number'),
    isFlatAmount: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    onlineValue: (0, _attr.default)('number'),
    isFlatAmountOnline: (0, _attr.default)('boolean', {
      defaultValue: true
    })
  });

  _exports.default = _default;
});