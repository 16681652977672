define("lh4/templates/components/ui/icons/s4/agent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u531YIG/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"width\",\"34\"],[10,\"height\",\"30\"],[10,\"viewBox\",\"0 0 34 30\"],[10,\"fill\",\"none\"],[8],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M16.9999 0C24.8999 0 31.9999 6.21667 31.9999 15.3667C32.9833 15.8833 33.6666 16.9 33.6666 18.0667V21.9C33.6666 23.1 32.9833 24.1167 31.9999 24.6333V26.6667C31.9999 28.5 30.4999 30 28.6666 30H15.3333V26.6667H28.6666V14.8333C28.6666 8.38333 23.4499 3.16667 16.9999 3.16667C10.5499 3.16667 5.33325 8.38333 5.33325 14.8333V25H3.66659C1.83325 25 0.333252 23.5 0.333252 21.6667V18.3333C0.333252 17.1 0.999919 16.0333 1.99992 15.4667C1.99992 6.08333 9.18325 0 16.9999 0Z\"],[10,\"fill\",\"black\"],[10,\"fill-opacity\",\"0.6\"],[8],[9],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M13.6666 16.6667C13.6666 17.5871 12.9204 18.3333 11.9999 18.3333C11.0794 18.3333 10.3333 17.5871 10.3333 16.6667C10.3333 15.7462 11.0794 15 11.9999 15C12.9204 15 13.6666 15.7462 13.6666 16.6667Z\"],[10,\"fill\",\"black\"],[10,\"fill-opacity\",\"0.6\"],[8],[9],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M21.9999 18.3333C22.9204 18.3333 23.6666 17.5871 23.6666 16.6667C23.6666 15.7462 22.9204 15 21.9999 15C21.0794 15 20.3333 15.7462 20.3333 16.6667C20.3333 17.5871 21.0794 18.3333 21.9999 18.3333Z\"],[10,\"fill\",\"black\"],[10,\"fill-opacity\",\"0.6\"],[8],[9],[0,\"\\n\\t\"],[7,\"path\",true],[10,\"d\",\"M17.0833 5C22.0666 5 26.1999 8.63333 26.9999 13.3833C21.7999 13.3333 17.3166 10.3167 15.1333 5.93333C14.2499 10.4 11.1499 14.0667 7.03325 15.75C6.59992 9.18333 12.0333 5 17.0833 5Z\"],[10,\"fill\",\"black\"],[10,\"fill-opacity\",\"0.6\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/ui/icons/s4/agent.hbs"
    }
  });

  _exports.default = _default;
});