define("lh4/components/ui/s4/icons/blue-circle-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 90 90" fill="none">
  	<g clip-path="url(#clip0_84_37028)">
      	<path d="M45.0016 0.5C20.4246 0.5 0.5 20.4246 0.5 45.0016C0.5 69.5786 20.4246 89.5 45.0016 89.5C69.5786 89.5 89.5 69.5786 89.5 45.0016C89.5 20.4246 69.5786 0.5 45.0016 0.5Z" fill="#0E5BF3"/>
      	<path d="M67.7615 35.2216L37.9252 65.0579C36.4697 66.5134 34.1087 66.5134 32.6533 65.0579L22.2738 54.6784C20.646 53.0507 20.6065 50.3452 22.2659 48.7491C23.8746 47.2019 26.4316 47.2209 28.0166 48.806L35.2892 56.0786L61.9538 29.4139C63.5578 27.8099 66.1574 27.8099 67.7615 29.4139C69.3655 31.018 69.3655 33.6176 67.7615 35.2216Z" fill="white"/>\
      </g>
      <defs>
      <clipPath id="clip0_84_37028">
        <rect width="89" height="89" fill="white" transform="translate(0.5 0.5)"/>
      </clipPath>
    </defs>
  </svg>
  
  */
  {
    id: "QpnhNAoI",
    block: "{\"symbols\":[],\"statements\":[[7,\"svg\",true],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[10,\"width\",\"90\"],[10,\"height\",\"90\"],[10,\"viewBox\",\"0 0 90 90\"],[10,\"fill\",\"none\"],[8],[0,\"\\n\\t\"],[7,\"g\",true],[10,\"clip-path\",\"url(#clip0_84_37028)\"],[8],[0,\"\\n    \\t\"],[7,\"path\",true],[10,\"d\",\"M45.0016 0.5C20.4246 0.5 0.5 20.4246 0.5 45.0016C0.5 69.5786 20.4246 89.5 45.0016 89.5C69.5786 89.5 89.5 69.5786 89.5 45.0016C89.5 20.4246 69.5786 0.5 45.0016 0.5Z\"],[10,\"fill\",\"#0E5BF3\"],[8],[9],[0,\"\\n    \\t\"],[7,\"path\",true],[10,\"d\",\"M67.7615 35.2216L37.9252 65.0579C36.4697 66.5134 34.1087 66.5134 32.6533 65.0579L22.2738 54.6784C20.646 53.0507 20.6065 50.3452 22.2659 48.7491C23.8746 47.2019 26.4316 47.2209 28.0166 48.806L35.2892 56.0786L61.9538 29.4139C63.5578 27.8099 66.1574 27.8099 67.7615 29.4139C69.3655 31.018 69.3655 33.6176 67.7615 35.2216Z\"],[10,\"fill\",\"white\"],[8],[9],[0,\"\\\\\\n    \"],[9],[0,\"\\n    \"],[7,\"defs\",true],[8],[0,\"\\n    \"],[7,\"clipPath\",true],[10,\"id\",\"clip0_84_37028\"],[8],[0,\"\\n      \"],[7,\"rect\",true],[10,\"width\",\"89\"],[10,\"height\",\"89\"],[10,\"fill\",\"white\"],[10,\"transform\",\"translate(0.5 0.5)\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    meta: {
      moduleName: "lh4/components/ui/s4/icons/blue-circle-check.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});