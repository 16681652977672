define("lh4/enums/transactions-filters", ["exports", "lh4/enums/ip-risk-statuses", "lh4/enums/transaction-types"], function (_exports, _ipRiskStatuses, _transactionTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.GIFT_CARD_EVENT_STATUSES = _exports.EVENT_STATUS_OPTIONS_BASE = _exports.TRANSACTION_STATUS_OPTIONS_BASE = _exports.RISK_OPTIONS_BASE = _exports.TYPE_OPTIONS_BASE = _exports.CARD_TYPES_OPTIONS_BASE = void 0;
  var TYPE_OPTIONS_BASE = Object.freeze([{
    id: _transactionTypes.TRANSACTION_TYPE.AUTH,
    name: _transactionTypes.TRANSACTION_TYPE_DISPLAY_VALUE.AUTH
  }, {
    id: _transactionTypes.TRANSACTION_TYPE.SALE,
    name: _transactionTypes.TRANSACTION_TYPE_DISPLAY_VALUE.SALE
  }, {
    id: _transactionTypes.TRANSACTION_TYPE.REFUND,
    name: _transactionTypes.TRANSACTION_TYPE_DISPLAY_VALUE.REFUND
  }, {
    id: _transactionTypes.TRANSACTION_TYPE.VOID,
    name: _transactionTypes.TRANSACTION_TYPE_DISPLAY_VALUE.VOID
  }]);
  _exports.TYPE_OPTIONS_BASE = TYPE_OPTIONS_BASE;
  var RISK_OPTIONS_BASE = Object.freeze([{
    id: _ipRiskStatuses.default.APPROVED,
    name: 'Approved'
  }, {
    id: _ipRiskStatuses.default.DECLINED,
    name: 'Declined'
  }, {
    id: _ipRiskStatuses.default.REVIEW,
    name: 'Review'
  }, {
    id: _ipRiskStatuses.default.ESCALATION,
    name: 'Escalation'
  }]);
  _exports.RISK_OPTIONS_BASE = RISK_OPTIONS_BASE;
  var TRANSACTION_STATUS_OPTIONS_BASE = Object.freeze([{
    id: 'SETTLED',
    name: 'Settled'
  }, {
    id: 'UNSETTLED',
    name: 'Unsettled'
  }, {
    id: 'DECLINED',
    name: 'Declined'
  }, {
    id: 'FAILED',
    name: 'Failed'
  }, {
    id: 'CHARGEBACK',
    name: 'Chargeback'
  }]);
  _exports.TRANSACTION_STATUS_OPTIONS_BASE = TRANSACTION_STATUS_OPTIONS_BASE;
  var CARD_TYPES_OPTIONS_BASE = Object.freeze([{
    id: 'Credit Card',
    name: 'Credit Card'
  }, {
    id: 'Debit Card',
    name: 'Debit Card'
  }, {
    id: 'Prepaid Debit Card',
    name: 'Prepaid Debit Card'
  }, {
    id: 'Gift Card',
    name: 'Gift Card'
  }, {
    id: 'Unknown',
    name: 'Unknown'
  }]);
  _exports.CARD_TYPES_OPTIONS_BASE = CARD_TYPES_OPTIONS_BASE;
  var EVENT_STATUS_OPTIONS_BASE = Object.freeze([{
    id: 'RISK_APPROVED',
    longName: 'Risk Approved',
    nameKey: 'enums:transactions-filters:event-status:risk-approved'
  }, {
    id: 'RISK_DECLINED',
    longName: 'Risk Declined',
    nameKey: 'enums:transactions-filters:event-status:risk-declined'
  }, {
    id: 'RISK_REVIEW',
    longName: 'Risk Review',
    nameKey: 'enums:transactions-filters:event-status:risk-review'
  }, {
    id: 'RISK_ESCALATION',
    longName: 'Risk Escalation',
    nameKey: 'enums:transactions-filters:event-status:risk-escalation'
  }, {
    id: 'CARD_VERIFY_APPROVED',
    longName: 'Card Verify Approved',
    nameKey: 'enums:transactions-filters:event-status:card-verify-approved'
  }, {
    id: 'CARD_VERIFY_DECLINED',
    longName: 'Card Verify Declined',
    nameKey: 'enums:transactions-filters:event-status:card-verify-declined'
  }, {
    id: 'AUTH_APPROVED',
    longName: 'Auth Approved',
    nameKey: 'enums:transactions-filters:event-status:auth-approved'
  }, {
    id: 'AUTH_DECLINED',
    longName: 'Auth Declined',
    nameKey: 'enums:transactions-filters:event-status:auth-declined'
  }, {
    id: 'CAPTURED',
    longName: 'Captured',
    nameKey: 'enums:transactions-filters:event-status:captured'
  }, {
    id: 'VOIDED',
    longName: 'Voided',
    nameKey: 'enums:transactions-filters:event-status:voided'
  }, {
    id: 'SENT_FOR_SETTLEMENT',
    longName: 'Sent for Settlement',
    nameKey: 'enums:transactions-filters:event-status:sent-for-settlement'
  }, {
    id: 'SETTLED',
    longName: 'Settled',
    nameKey: 'enums:transactions-filters:event-status:settled'
  }, {
    id: 'AUTO_SETTLED',
    longName: 'Auto Settled',
    nameKey: 'enums:transactions-filters:event-status:auto-settled',
    isChecked: false
  }, {
    id: 'SETTLEMENT_FAILED',
    longName: 'Settlement Failed',
    nameKey: 'enums:transactions-filters:event-status:settlement-failed'
  }, {
    id: 'AUTH_EXPIRED',
    longName: 'Auth Expired',
    nameKey: 'enums:transactions-filters:event-status:auth-expired'
  }, {
    id: 'AUTH_REFERRAL',
    longName: 'Auth Referral',
    nameKey: 'enums:transactions-filters:event-status:auth-referral'
  }, {
    id: 'REFUND_REQUESTED',
    longName: 'Refund Requested',
    nameKey: 'enums:transactions-filters:event-status:refund-requested'
  }, {
    id: 'REFUNDED',
    longName: 'Refunded',
    nameKey: 'enums:transactions-filters:event-status:refunded'
  }, {
    id: 'REFUND_FAILED',
    longName: 'Refund Failed',
    nameKey: 'enums:transactions-filters:event-status:refund-failed'
  }, {
    id: 'NOTIFICATION_OF_DISPUTE',
    longName: 'Notification of Dispute',
    nameKey: 'enums:transactions-filters:event-status:dispute-notified'
  }, {
    id: 'RETRIEVAL_REQUEST',
    longName: 'Retrieval Request',
    nameKey: 'enums:transactions-filters:event-status:retrieval-request'
  }]);
  _exports.EVENT_STATUS_OPTIONS_BASE = EVENT_STATUS_OPTIONS_BASE;
  var GIFT_CARD_EVENT_STATUSES = Object.freeze([{
    id: 'BALANCE CHECK',
    longName: 'Balance Check',
    nameKey: 'enums:transactions-filters:event-status:balance-check'
  }, {
    id: 'LOADED',
    longName: 'Loaded',
    nameKey: 'enums:transactions-filters:event-status:loaded'
  }, {
    id: 'DEACTIVATED',
    longName: 'Deactivated',
    nameKey: 'enums:transactions-filters:event-status:deactivated'
  }]);
  _exports.GIFT_CARD_EVENT_STATUSES = GIFT_CARD_EVENT_STATUSES;
});