define("lh4/routes/authenticated/settings/reporting-settings", ["exports", "moment", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/routes/discard-changes-mixin", "lh4/enums/date-range-title"], function (_exports, _moment, _authenticatedRouteMixin, _discardChangesMixin, _dateRangeTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _discardChangesMixin.default, {
    modalName: 'discard-reporting-settings-changes',
    modelNameToTrack: 'model.preferences',
    ajax: Ember.inject.service(),
    tokenData: Ember.inject.service(),
    location: Ember.inject.service(),
    can: Ember.inject.service(),

    /**
     * If needed, finds a country by time zone name.
     * Returns the first matching country.
     */
    findCountryByTimeZone: function findCountryByTimeZone(timeZoneName, timeZonesByCountryList) {
      if (!timeZonesByCountryList) {
        return false;
      }

      return Object.keys(timeZonesByCountryList).find(function (country) {
        return timeZonesByCountryList[country].some(function (_ref) {
          var name = _ref.name;
          return name === timeZoneName;
        });
      });
    },

    /**
     * Returns time zone objects for the selected country.
    */
    setTimeZones: function setTimeZones(selectedCountry, timeZonesByCountry) {
      return timeZonesByCountry[selectedCountry] || null;
    },
    model: function model() {
      var _this = this;

      // Ember doesn't allow to import JSON files directly
      // Looks like popular way to do it is put file in /public folder
      return this.ajax.request("".concat(window.location.origin, "/time-zones-list.json")).then(function (list) {
        var timeZonesByCountry = {};
        var countriesList = list.map(function (item) {
          timeZonesByCountry[item.name] = item.locales.map(function (locale) {
            return {
              name: locale,
              offSet: _moment.default.tz(locale).format('Z')
            };
          });
          return item.name;
        });
        return Ember.RSVP.hash({
          countriesList: countriesList,
          preferences: _this.store.findRecord('preferences', _this.get('tokenData.data.sub')),
          timeZonesByCountry: timeZonesByCountry
        });
      });
    },
    setupController: function setupController(controller, model) {
      var _model$preferences$re;

      this._super(controller, model);

      var selectedCountry = (_model$preferences$re = model.preferences.region) !== null && _model$preferences$re !== void 0 ? _model$preferences$re : this.findCountryByTimeZone(model.preferences.timeZone, model.timeZonesByCountry);
      var timeZones = this.setTimeZones(selectedCountry, model.timeZonesByCountry);
      controller.setProperties({
        selectedCountry: selectedCountry,
        timeZones: timeZones,
        setTimeZones: this.setTimeZones,
        selectedPeriod: (0, _dateRangeTitle.isCurrentBusinessDay)(model.preferences.currentOrLastDay) ? _dateRangeTitle.DATE_RANGE_TITLE.BUSINESS_DAY.CURRENT : _dateRangeTitle.DATE_RANGE_TITLE.BUSINESS_DAY.LAST
      });
    }
  });

  _exports.default = _default;
});