define("lh4/mirage/fixtures/reports/solo/quick-menu-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SOLO_QUICK_MENU_SUMMARY = {
    custom: true,
    name: 'Quick Menu Summary',
    totals: {
      name: 'Sales summary',
      sales: '$2000',
      tax: '$10',
      total: '$2010',
      results: [['Count', 'Item', 'Total Price', 'Total Tax', 'Total Amount'], ['9', 'Waffle Fries', '$18', '$1', '$19'], ['16', 'Cheeseburger', '$10', '$3', '$13'], ['40', 'Pizza', '$100', '$2', '$102'], ['55', 'Steak', '$10122', '$2', '$10124'], ['32', 'Fries', '$130', '$1', '$131']]
    },
    employees: [{
      name: 'Emp 1',
      tax: '$8.4',
      sales: '$23',
      total: '$31.4',
      results: [['Count', 'Item', 'Total Price', 'Total Tax', 'Total Amount'], ['9', 'Waffle Fries', '$18', '$2', '$20'], ['16', 'Cheeseburger', '$10', '$1', '$11']]
    }, {
      name: 'Emp 2',
      tax: '$10.4',
      sales: '$2',
      total: '$12.4',
      results: [['Count', 'Item', 'Total Price', 'Total Tax', 'Total Amount'], ['13', 'Waffle Fries', '$36', '$5', '$41'], ['100', 'Coors Light', '$180', '$15', '$195']]
    }]
  };
  var _default = SOLO_QUICK_MENU_SUMMARY;
  _exports.default = _default;
});