define("lh4/templates/authenticated/onboarding-wizard/complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5VfGz9GD",
    "block": "{\"symbols\":[],\"statements\":[[5,\"onboarding-wizard/onboarding-wizard-step-wrapper\",[],[[\"@data-test\",\"@data-dd-name\",\"@className\",\"@firstButtonText\",\"@onFirstButtonClick\",\"@isCentered\",\"@model\",\"@hideHeader\"],[\"onboarding-wizard-complete\",\"onboarding-wizard-complete\",\"onboarding-chapter\",\"ENTER\",[22,\"continue\"],true,[23,0,[\"model\"]],true]],{\"statements\":[[0,\"\\n\\t\"],[7,\"div\",false],[12,\"class\",\"wizard-complete-page\"],[3,\"did-insert\",[[23,0,[\"shootConfetti\"]]]],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"complete-content\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"complete-logo\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[5,\"ui/s4/icons/blue-circle-check\",[],[[],[]]],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"complete-title\"],[8],[0,\"\\n\\t\\t\\t\\tWelcome to SkyTab Lighthouse!\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\tYour all-in-one platform to manage your business,\\n\\t\\t\\ttrack performance, and optimize operations.\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/onboarding-wizard/complete.hbs"
    }
  });

  _exports.default = _default;
});