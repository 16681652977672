define("lh4/routes/authenticated/internet-payments/profile/index", ["exports", "lh4/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var BASE_ROUTE = 'authenticated.internet-payments.profile';

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    beforeModel: function beforeModel(transition) {
      var _routeData$, _transition$from, _routeData$find;

      var _this$controllerFor = this.controllerFor(BASE_ROUTE),
          routeData = _this$controllerFor.routeData;

      var defaultRoute = routeData === null || routeData === void 0 ? void 0 : (_routeData$ = routeData[0]) === null || _routeData$ === void 0 ? void 0 : _routeData$.name;
      var fromRoute = (_transition$from = transition.from) === null || _transition$from === void 0 ? void 0 : _transition$from.name;
      var toRoute = (routeData === null || routeData === void 0 ? void 0 : (_routeData$find = routeData.find(function (r) {
        return fromRoute === null || fromRoute === void 0 ? void 0 : fromRoute.startsWith("".concat(r.name));
      })) === null || _routeData$find === void 0 ? void 0 : _routeData$find.name) || defaultRoute;
      this.transitionTo(toRoute);
    }
  });

  _exports.default = _default;
});