define("lh4/serializers/echo-pro/modifier", ["exports", "lh4/serializers/echo-pro/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      modifierSets: {
        embedded: 'always',
        serialize: 'id'
      },
      nestedModifierSets: {
        embedded: 'always',
        serialize: 'id'
      },
      modifierSetMap: {
        serialize: 'false',
        deserialize: 'records'
      },
      printers: {
        embedded: 'always',
        serialize: 'id'
      }
    },
    normalize: function normalize(modelClass, jsonToNormalize) {
      var json = jsonToNormalize;

      if (json.parentModifierSets) {
        json.modifierSets = json.parentModifierSets;
        delete json.parentModifierSets;
      }

      if (json.stposUiStylesheetObjectLinks) {
        json.stposUiStylesheetGuid = Ember.A(json.stposUiStylesheetObjectLinks).reduce(function (guid, stposUiStylesheetObjectLink) {
          return (stposUiStylesheetObjectLink === null || stposUiStylesheetObjectLink === void 0 ? void 0 : stposUiStylesheetObjectLink.stposUiStylesheetGuid) || guid;
        }, '');
      }

      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      return this._super.apply(this, [modelClass, json].concat(args));
    },
    serialize: function serialize() {
      var json = this._super.apply(this, arguments);

      json.kitchenName = json.kitchenName && json.kitchenName.length ? json.kitchenName : null;
      json.parentModifierSets = json.modifierSets;
      delete json.modifiers;

      if (json.stposUiStylesheetGuid) {
        json.stposUiStylesheetObjectLinks = [{
          locationId: json.locationId,
          isActive: true,
          modifierGuid: json.id,
          stposUiStylesheetGuid: json.stposUiStylesheetGuid
        }];
        delete json.stposUiStylesheetGuid;
      } else {
        json.stposUiStylesheetObjectLinks = [];
        delete json.stposUiStylesheetGuid;
      }

      return json;
    }
  });

  _exports.default = _default;
});