define("lh4/serializers/location", ["exports", "lh4/enums/services", "lh4/serializers/application"], function (_exports, _services, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _application.default.extend({
    phoneNumber: Ember.inject.service(),
    primaryKey: 'id',
    normalizeFindAllResponse: function normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      // The locations .findAll endpoint response is cached (by both the browser and API).
      // After a page refresh the .findAll cached response overrides the .find fresh response with the cached data,
      // meaning any changes made since user login would appear reverted in the UI. The solution here for
      // location services is to store 2 versions of the services array.
      // Keep location services from .findAll in "cachedServices"
      var mapLocation = function mapLocation(location) {
        return _objectSpread(_objectSpread({}, location), {}, {
          services: undefined,
          cachedServices: location.services
        });
      };

      var payloadData = {
        locations: payload.locations.map(mapLocation)
      };
      return this._super(store, primaryModelClass, payloadData, id, requestType);
    },
    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, primaryModelClass, payload, id, requestType) {
      // The locations .find endpoint response is contains fresh data.
      // Keep location services from .find in "freshServices"
      var location = payload.location;
      var payloadData = {
        location: _objectSpread(_objectSpread({}, location), {}, {
          services: undefined,
          freshServices: location.services
        })
      };
      return this._super(store, primaryModelClass, payloadData, id, requestType);
    },
    normalize: function normalize(modelClass, jsonToNormalize) {
      var _this = this;

      var json = jsonToNormalize;
      var locationServices = jsonToNormalize.freshServices || jsonToNormalize.cachedServices || jsonToNormalize.services || [];
      var loyaltyLocationService = locationServices.find(function (s) {
        return s.serviceId === _services.SERVICE_IDS[_services.SERVICES.LOYALTY];
      });

      if (loyaltyLocationService) {
        var _loyaltyLocationServi, _loyaltyLocationServi2;

        json.loyaltyProgram = (_loyaltyLocationServi = loyaltyLocationService.configuration) === null || _loyaltyLocationServi === void 0 ? void 0 : (_loyaltyLocationServi2 = _loyaltyLocationServi.loyaltyProgramGuid) === null || _loyaltyLocationServi2 === void 0 ? void 0 : _loyaltyLocationServi2.toLowerCase();
      }

      locationServices.forEach(function (iter) {
        var _iter$configuration, _iter$configuration$d;

        if ((_iter$configuration = iter.configuration) !== null && _iter$configuration !== void 0 && (_iter$configuration$d = _iter$configuration.decorations) !== null && _iter$configuration$d !== void 0 && _iter$configuration$d.phone) {
          // eslint-disable-next-line no-param-reassign
          iter.configuration.decorations.phone = _this.phoneNumber.formatPhoneNumber(iter.configuration.decorations.phone);
        }
      });

      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      return this._super.apply(this, [modelClass, json].concat(args));
    }
  });

  _exports.default = _default;
});