define("lh4/enums/launch-darkly-feature-flags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FEATURE_FLAG_NAME = Object.freeze({
    // Lighthouse environment feature flags
    ADD_LOCATION_BUTTON: 'add-location-button',
    ADVANTAGE_PROGRAM_VISIBILITY: 'advantage-program-visibility',
    BUSINESS_SETTINGS_VISIBILITY: 'business-settings-visibility',
    CARD_BRAND_BREAKDOWN: 'card-brand-breakdown',
    CASH_DRAWER_ORDER_VISIBILITY: 'cash-drawer-order-visibility',
    CASH_ROUNDING_SETTING_VISIBILITY: 'cash-rounding-setting-visibility',
    DAY_PARTS_VISIBILITY: 'day-parts-visibility',
    DEFAULT_MODIFIERS_COLUMN_VISIBILITY: 'default-modifiers-column-visibility',
    DOLLAR_INCREMENT_BUTTON_VISIBILITY: 'dollar-increment-button-visibility',
    ENABLE_FISCALISATION_REPORTS: 'enable-fiscalisation-reports',
    ENABLE_GIFT_CARD_EVENT_STATUSES: 'enable-gift-card-event-statuses',
    ENABLE_I18N_ADDRESS_VALIDATIONS: 'enable-i18n-address-validations',
    ENABLE_I18N_PHONE_NUMBER_VALIDATIONS: 'enable-i18n-phone-number-validations',
    ENABLE_LAUNCH_CONTROL_EMPLOYEES: 'enable-launch-control-employees',
    ENABLE_LBM_MV_SSO: 'enable-lbm-mv-sso',
    ENABLE_LOGIN_INTERFACE_UPDATES: 'enable-login-interface-updates',
    ENABLE_PROCESSING_FINARO_REPORTS: 'enable-processing-finaro-reports',
    ENABLE_FINARO_INSIGHTS_REDIRECT: 'enable-finaro-insights-redirect',
    ENABLE_TRANSACTIONS_RISK_RULE_FILTER: 'enable-transactions-risk-rule-filter',
    ENABLE_TRANSACTION_ADJUSTMENTS: 'enable-transaction-adjustments',
    ENABLE_TRANSACTION_DATA_ADDITIONS: 'enable-transaction-data-additions',
    ENABLE_TRANSACTION_FILTER_TEMPLATES: 'enable-transaction-filter-templates',
    EPRO_COLOR_PICKER: 'epro-color-picker',
    FISCAL_REPORTS_VISIBILITY: 'fiscal-reports-visibility',
    GIFT_CARD_VISIBILITY: 'gift-card-visibility',
    HARDWARE_ORDERING_DISCOUNTS_ENABLED: 'hardware-ordering-discounts-enabled',
    HARDWARE_ORDERING_PRODUCT_LIST_VISIBILITY: 'hardware-ordering-product-list-visibility',
    INSIGHTS_V2_VISIBILITY: 'insights-v2-visibility',
    INTERNET_PAYMENTS_DISPUTE_MANAGEMENT: 'internet-payments-dispute-management',
    INTERNET_PAYMENTS_SHOW_IP_ADDRESS_BLOCK_TRUST_V2: 'ip-show-ip-address-in-block-trust-v2',
    INVOICES: 'invoices-visibility',
    IP_APM_SERVICES_VISIBILITY: 'ip-apm-services-visibility',
    IP_CARD_VERIFY_INSIGHT_VISIBILITY: 'ip-card-verify-insight-visibility',
    IP_CHARGEBACK_INSIGHT_VISIBILITY: 'ip-chargeback-insight-visibility',
    IP_DEVELOPER_API_KEYS_VISIBILITY: 'ip-developer-api-keys-visibility',
    IP_DEVELOPER_WEBHOOKS_VISIBILITY: 'ip-developer-webhooks-visibility',
    IP_MANAGE_BLOCKED_DATA_VISIBILITY: 'ip-manage-blocked-data-visibility',
    IP_RISK_INSIGHT_VISIBILITY: 'ip-risk-insight-visibility',
    IP_RISK_MENU_ITEM_VISIBILITY: 'ip-risk-menu-item-visibility',
    IP_TRANSACTIONS_INSIGHTS_AUTH_VISIBILITY: 'ip-transactions-insights-auth-visibility',
    IP_TRANSACTIONS_INSIGHTS_RISK_VISIBILITY: 'ip-transactions-insights-risk-visibility',
    IP_TRANSACTIONS_INSIGHTS_VISIBILITY: 'ip-transactions-insights-visibility',
    IP_WEBHOOK_EVENTS_RESENT_UPDATES: 'ip-webhook-events-resent-updates',
    KDS_INGREDIENT_DELETE: 'kds-ingredient-delete',
    KDS_ORDER_READY_SCREEN: 'kds-order-ready-screen',
    KDS_PREPARATION_TIMES: 'kds-preparation-times',
    KDS_PREP_ITEM: 'kds-prep-item',
    LAUNCH_CONTROL: 'launch-control',
    LAUNCH_CONTROL_WIZARD: 'launch-control-boarding-us-skytab-v1',
    LBM_TRACK_USAGE_WITH_DATADOG: 'lbm-track-usage-with-data-dog',
    LBM_TRANSACTIONS_VISIBILITY: 'lbm-transactions-visibility',
    LOCATION_OVERVIEW_WIDGETS_VISIBILITY: 'location-overview-widgets-visibility',
    MENU_BULK_EDIT: 'menu-bulk-edit-ability',
    MENU_COURSING_VISIBILITY: 'menu-coursing-visibility',
    NICE_LIVECHAT_VISIBILITY: 'nice-livechat-visibility',
    OP_AUTO_CLOSE_SETTINGS_VISIBILITY: 'op-auto-close-settings-visibility',
    OP_TRANSACTION_ADJUSTMENT_BUTTON: 'op-transaction-adjustment-button',
    OP_TRANSACTION_ADJUSTMENT_TYPE_CUSTOM_AMOUNT: 'op-adjustment-type-custom-amount-visibility',
    PREP_TIMES_COLUMN_VISIBILITY: 'prep-times-column-visibility',
    REMOTE_DOT_MATRIX_KITCHEN_PRINTER_ORDER_VISIBILITY: 'remote-dot-matrix-kitchen-printer-order-visibility',
    REPORTS_VIEW_EMPLOYEE_SHIFT_DETAIL_NEW: 'reports-view-employee-shift-detail-new',
    REPORTS_VIEW_EMPLOYEE_TIP_TOTALS_NEW: 'reports-view-employee-tip-totals-new',
    REPORTS_VIEW_TAX_SUMMARY_BY_REVENUE_CLASS: 'reports-view-tax-summary-by-revenue-class',
    REVENUE_CENTERS_VISIBILITY: 'revenue-centers-visibility',
    SHIPPING_METHOD_VISIBILITY: 'shipping-method-visibility',
    SKYTAB_CUSTOMER_FACING_DISPLAY_ORDER_VISIBILITY: 'skytab-customer-facing-display-order-visibility',
    SKYTAB_EMPLOYEE_ID_CARDS_IN_STOCK: 'skytab-employee-id-cards-in-stock',
    SKYTAB_FAST_QUANTITY_VISIBILITY: 'skytab-fast-quantity-visibility',
    SKYTAB_GLASS_ROUTER_CHECK_ENABLED: 'skytab-glass-router-check-enabled',
    SKYTAB_KDS_ORDER_VISIBILITY: 'skytab-kds-order-visibility',
    SKYTAB_MOBILE_ORDER_VISIBILITY: 'skytab-mobile-order-visibility',
    SKYTAB_ORDERS_VISIBILITY: 'skytab-orders-visibility',
    SKYTAB_POS_BUNDLE_ORDER_VISIBILITY: 'skytab-pos-bundle-order-visibility',
    SOLO_DEVICE_MANAGEMENT_VISIBILITY: 'solo-device-management-visibility',
    SOLO_ENABLE_CREDIT_CARD_VISIBILITY: 'solo-enable-credit-card-visibility',
    SOLO_ENABLE_PIN_DEBIT_VISIBILITY: 'solo-enable-pin-debit-visibility',
    SOLO_KIOSK_MODE_VISIBILITY: 'solo-kiosk-mode-visibility',
    SOLO_NON_CASH_CHARGE_VISIBILITY: 'solo-non-cash-charge-visibility',
    SOLO_OFFLINE_PAYMENT_VISIBILITY: 'solo-offline-payment-visibility',
    SOLO_PRINT_RECEIPT_SETTINGS_VISIBILITY: 'solo-print-receipts-settings-visibility',
    SOLO_QUICK_MENU: 'solo-quick-menu-visibility',
    SOLO_SETTINGS_DUAL_PRICING_VISIBILITY: 'solo-settings-dual-pricing-visibility',
    SOLO_SETTINGS_NEW_ADVANTAGE_PROGRAM_VISIBILITY: 'solo-settings-new-advantage-program-visibility',
    SOLO_SETTINGS_SYNC_TIPS: 'solo-settings-sync-tips',
    SOLO_TAXES_VISIBILITY: 'solo-taxes-visibility',
    STATEMENT_TAX_FORMS_VISIBILITY: 'statements-tax-forms-visibility',
    THERMAL_PRINTER_ORDER_VISIBILITY: 'thermal-printer-order-visibility',
    TIP_SHARING_VISIBILITY: 'tip-sharing-visibility-enabled',
    VIEW_TRANSACTION_ADVANCED_SEARCH: 'view-transaction-advanced-search',
    WEBHOOKS_PAYOUT_PROCESSING_EVENTS_VISIBILITY: 'webhooks-payout-processing-events-visibility',
    WORKFORCE_REPORT_VISIBILITY: 'workforce-report-visibility',
    // mlm multi location
    MLM_LOCATION_GROUPS_PAGE_VISIBILITY: 'mlm-location-groups-page-visbility',
    MLM_LOCATION_GROUPS_PERMISSIONS: 'mlm-location-groups-permissions',
    // TODO LH-26811 remove FF
    ENABLE_I18N_DASHBOARD_DATE_FORMATS: 'enable-i18n-dashboard-date-formats',
    ENABLE_CORPORATE_DEPLOYMENT: 'enable-corporate-deployment',
    ENABLE_LAUNCH_CONTROL_DISCOUNTS: 'enable-launch-control-discount-and-gratuity',
    // STPOS environment feature flags
    EW_LH_MENU_AVAILABILITY: 'ew-lh-menu-availability',
    EW_LH_PRICE_SCHEDULES_TO_DYNAMIC_PRICING: 'ew-lh-price-schedules-to-dynamic-pricing',
    EW_LH_CANADA_TAX_UI_ENABLED: 'ew-lh-canada-tax-ui-enabled',
    EW_PAYMENT_CAPTURE_IMPROVEMENTS: 'ew-payment-capture-improvements',
    // TODO LH-28403: remove this when the feature flag is removed
    ENABLE_TRANSACTION_AUTO_SETTLEMENT: 'enable-transaction-auto-settlement',
    SHOW_TIMEZONE_NEXT_TO_LOCATION: 'show-timezone-next-to-location',
    ENABLE_LAUNCH_CONTROL_KIOSK: 'enable-launch-control-kiosk',
    ENABLE_MODIFIERS_ORDER_FOR_MODIFER_SET: 'enable-modifiers-order-for-modifer-set',
    MLM_LOCATIONS_SIDEBAR_VISIBILITY: 'mlm-locations-sidebar-visibility',
    MLM_TRANSACTIONS_LOCATIONS_FILTER_VISIBILITY: 'mlm-transactions-locations-filter-visibility',
    MLM_USER_TRAY_LOCATION_TREE_VISIBILITY: 'mlm-user-tray-location-tree-visibility',
    PHONE_NUMBER_IDENTIFY_CALLER: 'phone-number-identify-caller',
    ENABLE_EXTERNAL_ORDERS: 'enable-external-orders',
    CANADA_TRANSACTION_DETAILS_RELATED_REFUNDS_VISIBILITY: 'canada-transaction-details-related-refunds-visibility',
    CHANGE_TRANSACTION_MANGER_DEFAULT_LOAD_TIME: 'reduce-transaction-manager-default-load-time',
    MLM_LOCATIONS_PAGE_VISIBILITY: 'mlm-locations-page-visbility',
    MLM_LOCATION_FILTER_FOR_DASHBOARD: 'mlm-location-filter-for-dashboard',
    MLM_REPORTS_LOCATIONS_FILTER_VISIBILITY: 'mlm-reports-locations-filter-visibility',
    MLM_BUSINESS_SETTINGS_PERMISSIONS: 'mlm-business-settings-permissions',
    OP_MERCHANT_BATCH_SETTINGS: 'op-merchant-batch-settings',
    ENABLE_MARKETPLACE_FOR_LBM2: 'enable-marketplace-for-lbm2',
    ENABLE_DISPUTES_EVENT_LOG: 'enable-disputes-event-log',
    LOCATION_GROUPS_V2: 'location-groups-v2',
    USE_DISPUTES_MANAGER_MOCK_DATA: 'use-disputes-manager-mock-data',
    POS_API_AUDIT_TRAIL_VISIBILITY: 'pos-api-audit-trail-visibility',
    NON_POS_API_AUDIT_TRAIL_VISIBILITY: 'non-pos-api-audit-trail-visibility',
    SKYTAB_ENTERPRISE_ENABLED: 'skytab-enterprise-enabled',
    ENABLE_CLASSIC_REPORTS_IN_LBM2: 'enable-classic-reports-in-lbm2',
    ALLOW_NEGATIVE_MODIFIER_AMOUNT: 'allow_negative_modifier_amount',
    ENABLE_HOSPITALITY_ACCOUNT_MAINTENANCE: 'enable-hospitality-account-maintenance',
    ENABLE_TRANSACTION_MANAGER_L10N: 'enable-transaction-manager-localization'
  });
  var _default = FEATURE_FLAG_NAME;
  _exports.default = _default;
});