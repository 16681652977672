define("lh4/utils/is-decimal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DECIMAL_REGEX = /^-?(?!0*(\.0+)?$)(\d+|\d+[.,]\d{1,2})$/;
  /**
   * Validate if given value is zero, positive or negative decimal with precision 2
   *
   * @param {string|number} value - value to validate
   * @returns {boolean} - true if value is decimal
   */

  var isDecimal = function isDecimal(value) {
    return parseFloat(value) === 0 || DECIMAL_REGEX.test(value);
  };

  var _default = isDecimal;
  _exports.default = _default;
});