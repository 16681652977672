define("lh4/mirage/fixtures/echo-pro/cash-transaction-by-employee", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ECHO_PRO_CASH_TRANSACTION_BY_EMPLOYEE = {
    name: 'Cash Transactions by Employee',
    headers: ['Employee Name', 'Cash Received', 'Change Made', 'Cash Payment Total'],
    rows: [['Agne Kasiuleviciute', '$199.47', '$0.00', '$199.47'], ['Arturas Cernodiedas', '$7.01', '$0.00', '$7.01'], ['Blixa Bargeld', '$391.87', '$106.06', '$285.81'], ['Danielius Jonusas', '$1220.31', '$0.00', '$1220.31'], ['Darijus Aleknavicius', '$2713.62', '$0.00', '$2713.62']]
  };
  var _default = ECHO_PRO_CASH_TRANSACTION_BY_EMPLOYEE;
  _exports.default = _default;
});