define("lh4/templates/components/internet-payments/info-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PW5pUI5f",
    "block": "{\"symbols\":[\"@className\",\"@headerTitle\",\"@inputClass\",\"@bodyText\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"info-box \",[23,1,[]]]]],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[1,[23,2,[]],false],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"div\",true],[11,\"class\",[29,[\"input-container \",[23,3,[]]]]],[8],[0,\"\\n\\t\\t\"],[1,[23,4,[]],true],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/components/internet-payments/info-box.hbs"
    }
  });

  _exports.default = _default;
});