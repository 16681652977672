define("lh4/routes/authenticated/epro/workforce/landing-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    can: Ember.inject.service(),
    location: Ember.inject.service(),
    permissions: Ember.inject.service(),
    router: Ember.inject.service(),
    locationInterval: null,
    redirect: function redirect() {
      if (this.can.can('manage employees and jobs for echo-pro/workforce', this.location.model)) {
        return this.transitionTo('authenticated.epro.manage-employees.employees');
      }

      if (this.can.can('manage timeclock for echo-pro/workforce', this.location.model)) {
        return this.transitionTo('authenticated.epro.timeclock.settings');
      }

      return this.transitionTo('authenticated.epro.workforce.landing-page');
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments); // Pulling fresh location model in case something has changed


      return this.permissions.refresh();
    },
    afterModel: function afterModel() {
      var _socialSchedulesServi,
          _socialSchedulesServi2,
          _this = this;

      var socialSchedulesService = this.permissions.getService('social-schedules');

      if ((socialSchedulesService === null || socialSchedulesService === void 0 ? void 0 : (_socialSchedulesServi = socialSchedulesService.configuration) === null || _socialSchedulesServi === void 0 ? void 0 : _socialSchedulesServi.status) === 'start' && !(socialSchedulesService !== null && socialSchedulesService !== void 0 && (_socialSchedulesServi2 = socialSchedulesService.configuration) !== null && _socialSchedulesServi2 !== void 0 && _socialSchedulesServi2.error) && !(socialSchedulesService !== null && socialSchedulesService !== void 0 && socialSchedulesService.isActive)) {
        this.set('locationInterval', setInterval(function () {
          _this.permissions.refresh().then(function () {
            var _newSocialSchedulesSe, _newSocialSchedulesSe4, _newSocialSchedulesSe5;

            var newSocialSchedulesService = _this.permissions.getService('social-schedules');

            if (newSocialSchedulesService !== null && newSocialSchedulesService !== void 0 && (_newSocialSchedulesSe = newSocialSchedulesService.configuration) !== null && _newSocialSchedulesSe !== void 0 && _newSocialSchedulesSe.error && !(newSocialSchedulesService !== null && newSocialSchedulesService !== void 0 && newSocialSchedulesService.isActive)) {
              var _newSocialSchedulesSe2, _newSocialSchedulesSe3;

              if ((newSocialSchedulesService === null || newSocialSchedulesService === void 0 ? void 0 : (_newSocialSchedulesSe2 = newSocialSchedulesService.configuration) === null || _newSocialSchedulesSe2 === void 0 ? void 0 : _newSocialSchedulesSe2.status) === 'start') {
                _this.controller.set('showActivateButton', true);

                _this.controller.send('showErrorForReactivation');

                clearInterval(_this.locationInterval);
              } else if ((newSocialSchedulesService === null || newSocialSchedulesService === void 0 ? void 0 : (_newSocialSchedulesSe3 = newSocialSchedulesService.configuration) === null || _newSocialSchedulesSe3 === void 0 ? void 0 : _newSocialSchedulesSe3.status) === 'onboarding.error') {
                _this.controller.set('showActivateButton', false);

                _this.controller.send('showErrorForOnboardingError');

                clearInterval(_this.locationInterval);
              }
            } else if (!(newSocialSchedulesService !== null && newSocialSchedulesService !== void 0 && (_newSocialSchedulesSe4 = newSocialSchedulesService.configuration) !== null && _newSocialSchedulesSe4 !== void 0 && _newSocialSchedulesSe4.error) && (newSocialSchedulesService === null || newSocialSchedulesService === void 0 ? void 0 : (_newSocialSchedulesSe5 = newSocialSchedulesService.configuration) === null || _newSocialSchedulesSe5 === void 0 ? void 0 : _newSocialSchedulesSe5.status) === 'onboarding.complete' && newSocialSchedulesService !== null && newSocialSchedulesService !== void 0 && newSocialSchedulesService.isActive) {
              _this.controller.set('showActivateButton', false);

              _this.controller.send('showNotificationForOnboardingSuccess');

              clearInterval(_this.locationInterval);
            }
          });
        }, 60000));
      }
    },
    setupController: function setupController(controller, model) {
      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      this._super.apply(this, [controller, model].concat(args));

      var socialSchedulesService = this.permissions.getService('social-schedules');

      if (!(socialSchedulesService !== null && socialSchedulesService !== void 0 && socialSchedulesService.isActive)) {
        var _socialSchedulesServi3, _socialSchedulesServi4, _socialSchedulesServi5, _socialSchedulesServi6;

        if ((socialSchedulesService === null || socialSchedulesService === void 0 ? void 0 : (_socialSchedulesServi3 = socialSchedulesService.configuration) === null || _socialSchedulesServi3 === void 0 ? void 0 : _socialSchedulesServi3.status) === 'start' && socialSchedulesService !== null && socialSchedulesService !== void 0 && (_socialSchedulesServi4 = socialSchedulesService.configuration) !== null && _socialSchedulesServi4 !== void 0 && _socialSchedulesServi4.error) {
          controller.set('showActivateButton', false);
        } else if ((socialSchedulesService === null || socialSchedulesService === void 0 ? void 0 : (_socialSchedulesServi5 = socialSchedulesService.configuration) === null || _socialSchedulesServi5 === void 0 ? void 0 : _socialSchedulesServi5.status) === 'onboarding.error' && socialSchedulesService !== null && socialSchedulesService !== void 0 && (_socialSchedulesServi6 = socialSchedulesService.configuration) !== null && _socialSchedulesServi6 !== void 0 && _socialSchedulesServi6.error) {
          controller.set('showActivateButton', false);
          controller.send('showErrorForOnboardingError');
        }
      }
    },
    actions: {
      willTransition: function willTransition() {
        if (this.locationInterval) {
          clearInterval(this.locationInterval);
        }

        return this._super.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});