define("lh4/routes/authenticated/reporting", ["exports", "lh4/mixins/authenticated-route-mixin", "lh4/mixins/mobile-scroll-route-mixin", "lh4/utils/lbm2"], function (_exports, _authenticatedRouteMixin, _mobileScrollRouteMixin, _lbm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _mobileScrollRouteMixin.default, {
    location: Ember.inject.service(),
    can: Ember.inject.service(),
    intl: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      this.titleToken = this.intl.t('page:reporting:title');
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (this.can.cannot('view reports in general') && (this.can.cannot('manage virtual-gift-card in general') || this.can.cannot('access gift cards reporting in general'))) {
        this.replaceWith('/');
      } // This is to cover a usecase where user navigates to MID1 that that's an LBM2.0 MID and also
      // has access to classic reports and then user selects location that's also LBM2.0 MID but
      // doesn't have access to classic reports


      if ((0, _lbm.default)(this.location.model) && !this.can.can('view classic reports in feature-flag')) {
        this.replaceWith('/internet-payments/dashboard');
      }
    }
  });

  _exports.default = _default;
});