define("lh4/components/ui/form-fields/radio-stylesheet-picker", ["exports", "lh4/enums/stpos-ui-stylesheet-color", "lh4/components/ui/form-fields/field"], function (_exports, _stposUiStylesheetColor, _field) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = _field.default.extend({
    dataTest: 'form-field-radio-stylesheet-picker',
    useUnsetOption: false,
    pickerClass: Ember.computed('fullWidth', function getter() {
      if (!this.label) {
        return 'sixteen wide column';
      }

      return "".concat(this.fullWidth ? 'twelve' : 'eight', " wide column");
    }),
    stylesheets: [],
    colors: Ember.computed('stylesheets', 'useUnsetOption', function getter() {
      var colors = (this.stylesheets || []).map(function (_ref) {
        var stylesheet = _ref.stylesheet;
        return stylesheet === null || stylesheet === void 0 ? void 0 : stylesheet.primary;
      });

      if (!this.useUnsetOption) {
        return colors;
      }

      return [_stposUiStylesheetColor.UNSET_OPTION.color].concat(_toConsumableArray(colors));
    }),
    selectedColor: Ember.computed('changeset', function getter() {
      var _selectedStylesheet$s;

      var stposUiStylesheetGuid = this.changeset.get(this.property);
      var selectedStylesheet = (this.stylesheets || []).find(function (_ref2) {
        var guid = _ref2.guid;
        return guid === stposUiStylesheetGuid;
      });

      if (!selectedStylesheet) {
        return this.useUnsetOption ? _stposUiStylesheetColor.UNSET_OPTION.color : null;
      }

      return (_selectedStylesheet$s = selectedStylesheet.stylesheet) === null || _selectedStylesheet$s === void 0 ? void 0 : _selectedStylesheet$s.primary;
    }),
    actions: {
      onChange: function onChange(color) {
        var selectedStylesheet = (this.stylesheets || []).find(function (_ref3) {
          var stylesheet = _ref3.stylesheet;
          return (stylesheet === null || stylesheet === void 0 ? void 0 : stylesheet.primary) === color;
        });

        if (!selectedStylesheet) {
          this.changeset.set(this.property, '');
        } else {
          this.changeset.set(this.property, selectedStylesheet.guid);
        }
      }
    }
  });

  _exports.default = _default;
});