define("lh4/routes/authenticated/locations/location/day-parts", ["exports", "moment", "lh4/mixins/authenticated-route-mixin"], function (_exports, _moment, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var DEFAULT_START_OF_DAY = '04:00 AM';
  var TIME_OPTION_INTERVAL_MINUTES = 60;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    location: Ember.inject.service(),
    can: Ember.inject.service(),
    router: Ember.inject.service(),
    corporation: Ember.inject.service(),
    intradayPeriod: Ember.inject.service('intraday-period'),
    locationSettings: Ember.inject.service('echo-pro/settings'),
    beforeModel: function beforeModel() {
      // Fetch location menu settings to evaluate isMenuManagedByCorporate before components are loaded
      if (this.location.model.corpId) {
        this.corporation.loadLocationMenuSettings(this.location.model.id);
      }
    },
    model: function model(params) {
      var location = this.location.model.id;
      return Ember.RSVP.hash({
        dayPartOptions: this.intradayPeriod.getDayPartsData(this.controller, location, params),
        startOfDay: this.locationSettings.getEoDSetting(location)
      });
    },
    setupController: function setupController() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      var controller = args[0],
          model = args[1];

      this._super.apply(this, args);

      var generateTimeArray = function generateTimeArray(startOfDayTime) {
        var start = _moment.default.utc(startOfDayTime, 'hh:mm A');

        var end = (0, _moment.default)(start).add(1, 'days');
        var timeArray = [];

        while (start <= end) {
          var isOvernight = start.dayOfYear() === end.dayOfYear();
          var formattedHours = String(start.hour() + (isOvernight ? 24 : 0)).padStart(2, '0');
          var formattedMinutes = String(start.minute()).padStart(2, '0');
          timeArray.push("".concat(formattedHours, ":").concat(formattedMinutes, ":00"));
          start.add(TIME_OPTION_INTERVAL_MINUTES, 'minutes');
        }

        return timeArray;
      };

      var timeOptions = generateTimeArray(model.startOfDay || DEFAULT_START_OF_DAY);

      var beginTimeOptions = _toConsumableArray(timeOptions);

      beginTimeOptions.pop();

      var endTimeOptions = _toConsumableArray(timeOptions);

      endTimeOptions.shift(); // If no day parts exist, create a default "All Day" record

      if (model.dayPartOptions.dayParts.intradayPeriods.length === 0) {
        var defaultDayPart = this.intradayPeriod.createDefaultDayPart(beginTimeOptions[0], endTimeOptions[endTimeOptions.length - 1]);
        model.dayPartOptions.dayParts.intradayPeriods.push(defaultDayPart);
      }

      var dayPartsSnapshot = JSON.parse(JSON.stringify(model.dayPartOptions.dayParts));
      var intradayPeriodGroupsSnapshot = this.intradayPeriod.mapIntradayPeriodGroupNameToGuid(model.dayPartOptions.intradayPeriodGroups);
      controller.setProperties({
        dayPartsSnapshot: dayPartsSnapshot,
        intradayPeriodGroupsSnapshot: intradayPeriodGroupsSnapshot,
        beginTimeOptions: beginTimeOptions,
        endTimeOptions: endTimeOptions
      });
      var locationController = this.controllerFor('authenticated.locations.location');
      locationController.set('showLocationTabs', true);
      model.dayPartOptions.dayParts.intradayPeriods.forEach(function (group) {
        group.periods.forEach(function (period) {
          if (!beginTimeOptions.includes(period.begin)) {
            Ember.set(period, 'invalidBeginTime', true);
          }

          if (!endTimeOptions.includes(period.end)) {
            Ember.set(period, 'invalidEndTime', true);
          }
        });
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        var locationController = this.controllerFor('authenticated.locations.location');
        locationController.set('showLocationTabs', false);
      }
    },
    redirect: function redirect() {
      if (this.can.cannot('view day parts in day-part', this.location.model) || this.can.can('access skytab enterprise as location in skytab-enterprise')) {
        this.transitionTo('authenticated.dashboard2');
      }
    },
    actions: {
      willTransition: function willTransition(transition) {
        if (this.controller.isDirty) {
          if (transition.targetName.includes(this.router.currentRoute.parent.name)) {
            return true;
          }

          var confirmation = window.confirm('You have unsaved changes. Are you sure you want to leave this page?');

          if (confirmation) {
            this.controller.set('isDirty', false);
            return true;
          }

          transition.abort();
        }

        return true;
      }
    }
  });

  _exports.default = _default;
});