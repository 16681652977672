define("lh4/adapters/location-group-v2", ["exports", "lh4/enums/location-group/location-group", "lh4/adapters/application"], function (_exports, _locationGroup, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType: function pathForType() {
      return 'location-group';
    },
    namespace: 'api/v2',
    urlForDeleteRecord: function urlForDeleteRecord(id, _modelName, snapshot) {
      var locationGroupType = snapshot.attr('locationGroupType');

      if (locationGroupType === _locationGroup.GROUP_TYPE.STATEMENT_GROUP) {
        return "".concat(this.host, "/").concat(this.namespace, "/location-group/statement-group/").concat(id);
      }

      return "".concat(this.host, "/api/v1/location-group/").concat(id);
    }
  });

  _exports.default = _default;
});