define("lh4/models/echo-pro/menu/item-bulk", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations", "lh4/mixins/model-cloneable", "lh4/utils/boolean-property-array", "lh4/utils/has-many-ids"], function (_exports, _model, _attr, _relationships, _emberCpValidations, _modelCloneable, _booleanPropertyArray, _hasManyIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.Validations = void 0;

  var _Ember;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var Validations = (0, _emberCpValidations.buildValidations)({
    priceChangeType: {
      description: 'Price Change Type',
      disabled: Ember.computed.not('model.includeGeneralPrice'),
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        positive: true
      })]
    },
    priceChangeValue: {
      description: 'Price Change Value',
      disabled: Ember.computed.not('model.includeGeneralPrice'),
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        positive: true,
        allowString: true
      })]
    },
    priceChangeRoundingRule: {
      description: 'Rounding Rule',
      disabled: Ember.computed.not('model.includeGeneralPrice'),
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        positive: true
      })]
    },
    departmentGuid: {
      description: 'Department',
      disabled: Ember.computed.not('model.includeGeneralDepartment'),
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    taxSetGuid: {
      description: 'Revenue Class',
      disabled: Ember.computed.not('model.includeGeneralRevenueClass'),
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    isFavorite: {
      description: 'Favorite',
      disabled: Ember.computed.not('model.includeGeneralFavorite'),
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    defaultQty: {
      description: 'Default Quantity',
      disabled: Ember.computed.not('model.includeAdvancedQuantity'),
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        allowBlank: false,
        allowString: true,
        positive: true,
        integer: true,
        gte: 1
      })]
    },
    isOpenItem: {
      description: 'isOpenItem',
      disabled: Ember.computed.not('model.includeAdvancedOptions'),
      validators: [(0, _emberCpValidations.validator)(function (value, options, model) {
        if (model.get('advanced').includes('isOpenItem') && model.get('advanced').includes('shouldNotPrintOnCustomerReceipt')) {
          return '"Do not print item to customer receipt" and ' + '"Open items (Show keypad to enter price)" cannot be enabled at the same time';
        }

        return true;
      }, {
        dependentKeys: ['advanced']
      })]
    },
    tareWeight: {
      description: 'Tare Weight',
      disabled: Ember.computed.not('model.includeAdvancedWeight'),
      validators: [(0, _emberCpValidations.validator)('number', {
        allowString: true
      }), (0, _emberCpValidations.validator)(function (value, options, model) {
        var errorMessage = 'Weight must be positive with at most three decimals';
        var regex = /^\d+([.,]\d{0,3})?$/;

        if (!regex.test(value) && model.get('isWeighted')) {
          return errorMessage;
        }

        return true;
      }, {
        dependentKeys: ['isWeighted']
      })]
    },
    quantityDecimalPlaces: {
      description: 'Quantity Decimal Places',
      disabled: Ember.computed.not('model.isManualQuantity'),
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        allowBlank: false,
        allowString: false,
        integer: true,
        positive: true,
        gte: 0,
        lte: 3
      })]
    },
    defaultUnit: {
      description: 'Default Unit',
      disabled: Ember.computed.not('model.isManualQuantity'),
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        max: 60
      })]
    },
    qtyOnHand: {
      description: 'Quantity On Hand',
      disabled: Ember.computed.not('model.includeTrackingDisplay'),
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: true,
        allowString: true,
        integer: true,
        positive: true,
        gte: 0,
        lte: 99999
      }), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Quantity On Hand must be filled if Track Items is checked',
        disabled: Ember.computed('model.includeTrackingDisplay', 'model.isDisplayedOnItemTracking', function getter() {
          return !this.model.get('includeTrackingDisplay') || !this.model.get('isDisplayedOnItemTracking');
        })
      })]
    },
    minQty: {
      description: 'Alert Quantity',
      disabled: Ember.computed.not('model.includeTrackingAlert'),
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: true,
        allowString: true,
        positive: true,
        integer: true
      }), (0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: 'Alert Quantity must be filled if Low Stock Indicator is checked',
        disabled: Ember.computed('model.includeTrackingAlert', 'model.showQtyAlert', function getter() {
          return !this.model.get('includeTrackingAlert') || !this.model.get('showQtyAlert');
        })
      })]
    },
    prepTimeText: {
      description: 'Item Prep Time',
      disabled: Ember.computed.not('model.includePrepTime'),
      validators: [(0, _emberCpValidations.validator)(function (value) {
        if (value === '' || value === '0') {
          return true;
        }

        var _value$split = value.split(':'),
            _value$split2 = _slicedToArray(_value$split, 2),
            minutes = _value$split2[0],
            seconds = _value$split2[1];

        var regex = /^([0-9]{1,4}):([0-5][0-9])$/; // Validation to allow only mm:ss time format, up to 23:59:59

        if (value && !regex.test(value) || parseInt(minutes, 10) > 1439 || parseInt(seconds, 10) > 59) {
          return 'Enter the time in minutes and seconds. Example: 2:30';
        }

        return true;
      })]
    }
  });
  _exports.Validations = Validations;
  var ADVANCED_ATTRIBUTES = {
    shouldNotPrintOnCustomerReceipt: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    isOpenItem: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    showSpecialRequest: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    canBuyWithGiftCard: (0, _attr.default)('boolean', {
      defaultValue: true,
      isCopyable: true
    })
  };
  var FIELD_SELECTION_GROUPS = {
    // General
    includeGeneralActive: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    includeGeneralPrice: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    includeGeneralDepartment: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    includeGeneralRevenueClass: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    includeGeneralFavorite: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    // Advanced
    includeAdvancedQuantity: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    includeAdvancedOptions: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    includeAdvancedWeight: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    // Accounting
    includeAccountingTaxes: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    // Tracking
    includeTrackingDisplay: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    includeTrackingAlert: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    includeTrackingAllowZeroQtyOrdering: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    // Prep Time
    includePrepTime: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    // Modifier Sets
    includeModifierSets: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    // Printers
    includePrinters: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    })
  };

  var _default = _model.default.extend(Validations, _modelCloneable.default, ADVANCED_ATTRIBUTES, FIELD_SELECTION_GROUPS, {
    locationId: (0, _attr.default)('number', {
      isCopyable: true
    }),
    itemTags: (0, _relationships.hasMany)('echo-pro/item-tag'),
    // General
    isActive: (0, _attr.default)('boolean', {
      defaultValue: true,
      isCopyable: true
    }),
    departmentGuid: (0, _attr.default)('string', {
      isCopyable: true
    }),
    taxSetGuid: (0, _attr.default)('string', {
      isCopyable: true
    }),
    priceChangeType: (0, _attr.default)('number', {
      isCopyable: true
    }),
    priceChangeValue: (0, _attr.default)('number', {
      isCopyable: true
    }),
    priceChangeRoundingRule: (0, _attr.default)('number', {
      isCopyable: true
    }),
    isFavorite: (0, _attr.default)('boolean', {
      isCopyable: true
    }),
    stposUiStylesheetGuid: (0, _attr.default)('string', {
      isCopyable: true
    }),
    itemTagIds: Ember.computed('itemTags', (0, _hasManyIds.default)('itemTags', 'echo-pro/item-tag')),
    // Advanced
    defaultQty: (0, _attr.default)('number', {
      defaultValue: 1,
      isCopyable: true
    }),
    advanced: (_Ember = Ember).computed.apply(_Ember, _toConsumableArray(Object.keys(ADVANCED_ATTRIBUTES)).concat([(0, _booleanPropertyArray.default)(Object.keys(ADVANCED_ATTRIBUTES))])),
    isWeighted: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    tareWeight: (0, _attr.default)('string', {
      isCopyable: true
    }),
    isManualQuantity: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    quantityDecimalPlaces: (0, _attr.default)('number', {
      defaultValue: 3,
      isCopyable: true
    }),
    defaultUnit: (0, _attr.default)('string', {
      defaultValue: 'LB',
      isCopyable: true
    }),
    allowZeroQtyOrdering: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    // Accounting
    taxes: (0, _relationships.hasMany)('echo-pro/tax'),
    taxIds: Ember.computed('taxes', (0, _hasManyIds.default)('taxes', 'echo-pro/tax')),
    // Tracking
    isDisplayedOnItemTracking: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    qtyOnHand: (0, _attr.default)('number', {
      isCopyable: true
    }),
    showQtyAlert: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    minQty: (0, _attr.default)('number', {
      isCopyable: true
    }),
    // Prep Time
    prepTimeSeconds: (0, _attr.default)('number', {
      defaultValue: 0,
      isCopyable: true
    }),
    prepTimeText: (0, _attr.default)('string', {
      defaultValue: '0:00',
      isCopyable: true
    }),
    // Modifier Sets
    modifierSets: (0, _relationships.hasMany)('echo-pro/modifier-set'),
    modifierSetIds: Ember.computed('modifierSets', (0, _hasManyIds.default)('modifierSets', 'echo-pro/modifier-set')),
    // Printers
    printers: (0, _relationships.hasMany)('echo-pro/printer'),
    printerIds: Ember.computed('printers', (0, _hasManyIds.default)('printers', 'echo-pro/printer')),
    // Availability
    defaultAvailability: (0, _attr.default)('boolean', {
      defaultValue: true,
      isCopyable: true
    }),
    // Online Ordering
    onlineActive: (0, _attr.default)('boolean', {
      defaultValue: true,
      isCopyable: true
    }),
    onlinePriceChangeType: (0, _attr.default)('number', {
      isCopyable: true
    }),
    onlinePriceChangeValue: (0, _attr.default)('number', {
      isCopyable: true
    }),
    onlinePriceChangeRoundingRule: (0, _attr.default)('number', {
      isCopyable: true
    })
  });

  _exports.default = _default;
});