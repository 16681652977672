define("lh4/models/echo-pro/modifier", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-cp-validations", "lh4/mixins/model-cloneable", "lh4/utils/has-many-ids"], function (_exports, _model, _attr, _relationships, _emberCpValidations, _modelCloneable, _hasManyIds) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      description: 'Modifier Name',
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        ignoreBlank: true
      }), (0, _emberCpValidations.validator)('length', {
        max: 100
      })]
    },
    kitchenName: {
      description: 'Kitchen Print Name',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 255
      })]
    },
    onlineDescription: {
      description: 'Online Description',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 255
      })]
    },
    onlineName: {
      description: 'Online Display Name',
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 255
      })]
    }
  });

  var _default = _model.default.extend(Validations, _modelCloneable.default, {
    guid: (0, _attr.default)('string'),
    active: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    cost: (0, _attr.default)('string'),
    kitchenName: (0, _attr.default)('string'),
    locationId: (0, _attr.default)('number', {
      isCopyable: true
    }),
    name: (0, _attr.default)('string'),
    ordinal: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    shouldPrintDistinctly: (0, _attr.default)('boolean', {
      isCopyable: true
    }),
    upc: (0, _attr.default)('string'),
    onlineActive: (0, _attr.default)('boolean', {
      defaultValue: true,
      isCopyable: true
    }),
    onlineName: (0, _attr.default)('string'),
    onlineDescription: (0, _attr.default)('string', {
      isCopyable: true
    }),
    printers: (0, _relationships.hasMany)('echo-pro/printer'),
    modifierSets: (0, _relationships.hasMany)('echo-pro/modifier-set'),
    nestedModifierSets: (0, _relationships.hasMany)('echo-pro/modifier-set', {
      inverse: 'nestedModifiers'
    }),
    printerIds: Ember.computed('printers', (0, _hasManyIds.default)('printers', 'echo-pro/printer')),
    modifierSetIds: Ember.computed('modifierSets', (0, _hasManyIds.default)('modifierSets', 'echo-pro/modifier-set')),
    nestedModifierSetIds: Ember.computed('nestedModifierSetIds', (0, _hasManyIds.default)('nestedModifierSets', 'echo-pro/modifier-set')),
    shouldPromptSpecialRequest: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    stposUiStylesheetGuid: (0, _attr.default)('string', {
      isCopyable: true
    })
  });

  _exports.default = _default;
});