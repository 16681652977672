define("lh4/mirage/routes/internet-payments/transactions", ["exports", "lh4/mirage/fixtures/internet-payments/transactions"], function (_exports, _transactions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setMirageInternetPaymentsTransactionsServer;

  function setMirageInternetPaymentsTransactionsServer(serverObject) {
    var server = serverObject;
    server.get('/internet-payments/transactions', function (schema, request) {
      var type = request.queryParams.type;

      if ((type === null || type === void 0 ? void 0 : type.length) === 1 && type.includes('INFO')) {
        return _transactions.TRANSACTION_TYPE_INFO_RESPONSE_FIXTURE;
      }

      return _transactions.default;
    });
    server.namespace = '/api/v2';
    server.post('/internet-payments/transactions', function (schema, request) {
      var type = request.queryParams.type;

      if ((type === null || type === void 0 ? void 0 : type.length) === 1 && type.includes('INFO')) {
        return _transactions.TRANSACTION_TYPE_INFO_RESPONSE_FIXTURE;
      }

      return _transactions.default;
    });
    server.namespace = '/api/v1';
    server.get('/internet-payments/transactions/related-transactions/data', function () {
      return {
        count: 1,
        transactions: [{
          transbaseId: 'uniqueId2',
          invoice: '0000000002',
          date: '2023-06-08 23:45:12',
          amount: 120,
          id: 4,
          cardNumber: '2011',
          cardType: 'mastercard',
          cardToken: 'token',
          customerName: 'Very good name',
          transactionId: '0003765456',
          processingStatus: 'settled',
          riskStatus: null,
          acquirerResponse: '00 - Approval'
        }]
      };
    });
  }
});