define("lh4/models/echo-pro/menu/modifier-set-bulk", ["exports", "ember-data/model", "ember-data/attr", "ember-cp-validations", "lh4/mixins/model-cloneable", "lh4/utils/boolean-property-array", "lh4/utils/is-decimal", "lh4/enums/launch-darkly-feature-flags"], function (_exports, _model, _attr, _emberCpValidations, _modelCloneable, _booleanPropertyArray, _isDecimal, _launchDarklyFeatureFlags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _Ember;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var Validations = (0, _emberCpValidations.buildValidations)({
    value: {
      description: 'Modifier Price',
      disabled: Ember.computed.not('model.includeGeneralPrice'),
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: false,
        allowString: true
      }), (0, _emberCpValidations.validator)('length', {
        max: 9
      }), (0, _emberCpValidations.validator)('negative-if-launch-darkly', {
        allowNegativeFlag: _launchDarklyFeatureFlags.default.ALLOW_NEGATIVE_MODIFIER_AMOUNT,
        message: 'Modifier Price must be positive'
      }), (0, _emberCpValidations.validator)(function (value) {
        return (0, _isDecimal.default)(value) || 'Modifier Price can only contain maximum two decimal numbers';
      })]
    },
    minModifiersPerSet: {
      description: 'Minimum amount of modifiers',
      disabled: Ember.computed('model.includeGeneralDisplay', 'model.isRequired', function getter() {
        return !this.model.get('includeGeneralDisplay') || !this.model.get('isRequired');
      }),
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true,
        gt: 0
      })]
    },
    maxQuantityForIndividualModifier: {
      description: 'Maximum quantity for individual modifier',
      disabled: Ember.computed('model.includeGeneralQuantity', 'model.areModifierQuantitiesEnabled', function getter() {
        return !this.model.get('includeGeneralQuantity') || !this.model.get('areModifierQuantitiesEnabled');
      }),
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true,
        gt: 0
      })]
    }
  });
  var GENERAL_ATTRIBUTES = {
    isPrintedRedOrBold: (0, _attr.default)('boolean', {
      defaultValue: true,
      isCopyable: true
    }),
    isSetNamePrepended: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    })
  };
  var FIELD_SELECTION_GROUPS = {
    // General
    includeGeneralActive: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    includeGeneralPrice: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    includeGeneralOptions: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    includeGeneralDisplay: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    includeGeneralQuantity: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    })
  };

  var _default = _model.default.extend(Validations, _modelCloneable.default, GENERAL_ATTRIBUTES, FIELD_SELECTION_GROUPS, {
    locationId: (0, _attr.default)('number', {
      isCopyable: true
    }),
    // General
    isActive: (0, _attr.default)('boolean', {
      defaultValue: true,
      isCopyable: true
    }),
    isFlatPrice: (0, _attr.default)('boolean', {
      defaultValue: true,
      isCopyable: true
    }),
    value: (0, _attr.default)('number', {
      isCopyable: true
    }),
    options: (_Ember = Ember).computed.apply(_Ember, _toConsumableArray(Object.keys(GENERAL_ATTRIBUTES)).concat([(0, _booleanPropertyArray.default)(Object.keys(GENERAL_ATTRIBUTES))])),
    isRequired: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    minModifiersPerSet: (0, _attr.default)('number', {
      defaultValue: 1,
      isCopyable: true
    }),
    areModifierQuantitiesEnabled: (0, _attr.default)('boolean', {
      defaultValue: false,
      isCopyable: true
    }),
    minQuantityForIndividualModifier: (0, _attr.default)('number', {
      defaultValue: 1,
      isCopyable: true
    }),
    maxQuantityForIndividualModifier: (0, _attr.default)('number', {
      defaultValue: 5,
      isCopyable: true
    }),
    // Online Ordering
    onlineActive: (0, _attr.default)('boolean', {
      defaultValue: true,
      isCopyable: true
    })
  });

  _exports.default = _default;
});