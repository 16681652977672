define("lh4/mirage/fixtures/reports/solo/sales-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SOLO_SALES_SUMMARY = {
    name: 'Sales Summary',
    custom: true,
    totals: {
      name: 'Sales summary',
      count: 25,
      totalPrice: '$226.25',
      totalTips: '$0.00',
      totalTax: '$59.25',
      totalAmount: '$285.50',
      totalPlusTips: '$285.50',
      employeeNames: 'Jack Jackson, Emily James',
      deviceSerialNos: '0002555',
      cardBrandResults: [['Tender Type', 'Card Brand', 'Tender Total', 'Tip Amount', 'Tender Ttl-Tip'], ['credit', 'AMEX', '$94.20', '$0.00', '$94.20'], ['credit', 'VISA', '$76.64', '$0.00', '$76.64'], ['credit', 'MASTERCARD', '$55.41', '$0.00', '$55.41'], ['gift-card', '', '$40.00', '$0.00', '$40.00']],
      results: [['Count', 'Item', 'Unit Price', 'Unit Tax', 'Inclusive Unit Price', 'Total Price', 'Total Tax', 'Total Amount'], ['10', 'Liquor', '$10.16', '$2.37', '$12.53', '$101.60', '$23.70', '$125.30'], ['15', 'Wine', '$8.31', '$2.37', '$10.68', '$124.65', '$35.55', '$160.20']]
    },
    devices: [{
      name: '0002555',
      tax: '$59.25',
      tips: '$0.00',
      count: 25,
      totalPrice: '$226.25',
      totalTips: '$0.00',
      totalAmount: '$285.50',
      totalPlusTips: '$285.50',
      employeeNames: 'Jack Jackson, Emily James',
      results: [['Count', 'Item', 'Unit Price', 'Unit Tax', 'Inclusive Unit Price', 'Total Price', 'Total Tax', 'Total Amount'], ['10', 'Liquor', '$10.16', '$2.37', '$12.53', '$101.60', '$23.70', '$125.30'], ['15', 'Wine', '$8.31', '$2.37', '$10.68', '$124.65', '$35.55', '$160.20']],
      cardBrandResults: [['Tender Type', 'Card Brand', 'Tender Total', 'Tip Amount', 'Tender Ttl-Tip'], ['credit', 'AMEX', '$94.20', '$0.00', '$94.20'], ['credit', 'VISA', '$76.64', '$0.00', '$76.64'], ['credit', 'MASTERCARD', '$55.41', '$0.00', '$55.41'], ['gift-card', '', '$40.00', '$0.00', '$40.00']]
    }]
  };
  var _default = SOLO_SALES_SUMMARY;
  _exports.default = _default;
});