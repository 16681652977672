define("lh4/controllers/authenticated/reporting/reporting", ["exports", "lh4/enums/color-code-strategy", "moment", "lh4/components/drill-down-report-box"], function (_exports, _colorCodeStrategy, _moment, _drillDownReportBox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Controller.extend({
    queryParams: ['dates.startsAt', 'dates.startsAtTime', 'dates.endsAt', 'dates.endsAtTime', {
      reportType: 'report',
      selectedLocations: 'locations',
      reportPeriodSelected: 'period',
      includeZeroBalances: 'balances',
      status: 'status',
      generate: 'generate'
    }],
    reportApi: Ember.inject.service('reports/drill-down-report-api'),
    reportError: Ember.computed.oneWay('error'),
    selectedReport: Ember.computed('reportType', function selectedReport() {
      var reportTypes = this.reportTypes,
          reportType = this.reportType;
      return reportTypes.find(function (_ref) {
        var slug = _ref.slug;
        return slug === reportType;
      }) || {
        exports: {}
      };
    }),
    session: Ember.inject.service(),
    permissions: Ember.inject.service(),
    ajax: Ember.inject.service(),
    metrics: Ember.inject.service(),
    location: Ember.inject.service(),
    reportType: null,
    hideUnsupportedLocations: true,
    selectedLocations: [],
    includeZeroBalances: false,
    colorCodeStrategy: _colorCodeStrategy.default.DEFAULT,
    showGiftCardPeriodDisclaimer: false,
    showTimeZoneWarning: true,
    currentReport: null,
    generatedReportType: null,
    rangeTitle: null,
    dates: {},
    isSubscriptionModalOpen: false,
    allowedLocations: Ember.computed('permissions', function allowedLocations() {
      var _this = this;

      return this.get('permissions.reportLocations').map(function (id) {
        return _this.store.peekRecord('location', id);
      }).filter(function (location) {
        return _this.permissions.hasProduct('echo-pro', location);
      }).sortBy('name');
    }),
    locationIdInternetPayments: Ember.computed('permissions.reportLocations', function locationIdInternetPayments() {
      var _this2 = this;

      return this.get('permissions.reportLocations').filter(function (id) {
        return _this2.permissions.hasProduct('internet-payments', _this2.store.peekRecord('location', id));
      });
    }),
    determineTimeRange: function determineTimeRange(dates, isDateTimeBusinessDay) {
      var _this$reportApi$prepa = this.reportApi.prepareDates(dates.startsAt, dates.startsAtTime, dates.endsAt, dates.endsAtTime, isDateTimeBusinessDay),
          startsAt = _this$reportApi$prepa.startsAt,
          endsAt = _this$reportApi$prepa.endsAt;

      this.setProperties({
        startsAt: startsAt,
        endsAt: endsAt
      });
      var period = (0, _moment.default)(endsAt).diff((0, _moment.default)(startsAt), 'days');
      return {
        startsAt: startsAt,
        endsAt: endsAt,
        period: period
      };
    },
    actions: {
      resetReport: function resetReport() {
        this.setProperties({
          currentReport: null,
          fetchFunction: null,
          generatedReportType: null,
          error: null
        });
      },
      generateReport: function generateReport(includeZeroBalances, status, colorCodeStrategy, includeCardBrandRows) {
        var _selectedReport$param,
            _selectedReport$param2,
            _this3 = this;

        if (this.generate) {
          this.set('generate', undefined);
        }

        var reportType = this.reportType,
            reportApi = this.reportApi,
            dates = this.dates,
            selectedReport = this.selectedReport,
            selectedLocations = this.selectedLocations,
            limit = this.limit,
            offset = this.offset;
        var isDateTimeBusinessDay = this.selectedReport.params.datePickerOptions.isDateTimeBusinessDay;
        this.set('generatedReport', selectedReport);

        var _this$determineTimeRa = this.determineTimeRange(dates, isDateTimeBusinessDay, reportType),
            startsAt = _this$determineTimeRa.startsAt,
            endsAt = _this$determineTimeRa.endsAt,
            period = _this$determineTimeRa.period;

        var locations = (_selectedReport$param = selectedReport.params) !== null && _selectedReport$param !== void 0 && (_selectedReport$param2 = _selectedReport$param.locations) !== null && _selectedReport$param2 !== void 0 && _selectedReport$param2.limit ? selectedLocations.slice(0, selectedReport.params.locations.limit) : selectedLocations;
        this.setProperties({
          reportType: reportType,
          currentReport: 'loading',
          error: null
        });
        this.metrics.trackEvent(['datadogRum'], {
          event: 'Report Generated',
          'Report Name': selectedReport.name,
          'Date Period': period,
          'Date Period Name': this.rangeTitle,
          'User Role': this.session.isSSO ? 'Internal' : 'External',
          Email: this.get('tokenData.data.email'),
          'Report Type': reportType,
          'Number of Locations': selectedLocations.length
        });

        var fetchFunction = function fetchFunction() {
          var body = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
          return reportApi.fetchReport(reportType, _objectSpread({
            startsAt: startsAt,
            endsAt: endsAt,
            locations: locations,
            colorCodeStrategy: colorCodeStrategy,
            includeZeroBalances: includeZeroBalances,
            includeCardBrandRows: includeCardBrandRows,
            status: status,
            limit: limit,
            offset: offset
          }, body)).catch(function (e) {
            _this3.setProperties({
              currentReport: null,
              fetchFunction: null,
              generatedReportType: null,
              error: JSON.parse(e.message)
            });
          });
        };

        fetchFunction().then(function (currentReport) {
          var _currentReport$pagina, _currentReport$pagina2;

          if (reportType === _drillDownReportBox.GIFT_CARD_BALANCES_REPORT.slug) {
            if (currentReport.pagination && currentReport.pagination.pageCount > 1) {
              _this3.setProperties({
                giftCardReportTemplate: 'reports/gift-card',
                reportType: reportType,
                currentReport: currentReport,
                includeZeroBalances: includeZeroBalances
              });
            }
          }

          _this3.setProperties({
            reportTemplate: 'reports/report-table-drill-down',
            currentReport: currentReport,
            pageCount: (_currentReport$pagina = currentReport.pagination) === null || _currentReport$pagina === void 0 ? void 0 : _currentReport$pagina.pageCount,
            totalCount: (_currentReport$pagina2 = currentReport.pagination) === null || _currentReport$pagina2 === void 0 ? void 0 : _currentReport$pagina2.totalCount,
            fetchFunction: fetchFunction,
            generatedReportType: reportType
          });
        });
      },
      toggleUnsupportedLocations: function toggleUnsupportedLocations() {
        this.toggleProperty('hideUnsupportedLocations');
      },
      subscribeModal: function subscribeModal() {
        this.get('model.newSubscription').setProperties({
          product: this.get('enabledProd'),
          report: this.get('reportType'),
          options: {
            locations: _toConsumableArray(this.get('selectedLocations'))
          }
        });
        this.set('isSubscriptionModalOpen', true);
      },
      modalSubscriptionClose: function modalSubscriptionClose() {
        this.set('isSubscriptionModalOpen', false);
      },
      selectReportPeriod: function selectReportPeriod(name, firstDate, secondDate, rangeTitle) {
        var _this4 = this,
            _this$selectedReport,
            _this$selectedReport$;

        if (!firstDate) {
          return this.set('dates', {});
        }

        var dates = {};
        this.set('rangeTitle', rangeTitle); // when internet payments date range picker input not disabled prevents error when name not set

        var isInternetPayments = this.selectedLocations.some(function (s) {
          return _this4.locationIdInternetPayments.find(function (c) {
            return c.toString() === s;
          });
        }); // even when name also need to determine if internetpayment components date range picker is active

        if (!name && !isInternetPayments) {
          return this.set('dates', dates);
        }

        var _ref2 = ((_this$selectedReport = this.selectedReport) === null || _this$selectedReport === void 0 ? void 0 : (_this$selectedReport$ = _this$selectedReport.params) === null || _this$selectedReport$ === void 0 ? void 0 : _this$selectedReport$.datePickerOptions) || {},
            _ref2$isDateTimeBusin = _ref2.isDateTimeBusinessDay,
            isDateTimeBusinessDay = _ref2$isDateTimeBusin === void 0 ? true : _ref2$isDateTimeBusin;

        this.set('offset', 0);

        if (secondDate) {
          var firstReportDate = isDateTimeBusinessDay ? (0, _moment.default)(firstDate) : (0, _moment.default)(firstDate).utc(true).startOf('day');
          var secondReportDate = isDateTimeBusinessDay ? (0, _moment.default)(secondDate) : (0, _moment.default)(secondDate).utc(true).endOf('day');
          return this.set('dates', {
            startsAt: firstReportDate.format(),
            startsAtTime: firstReportDate.format('hh:mmA'),
            endsAt: secondReportDate.format(),
            endsAtTime: secondReportDate.format('hh:mmA')
          });
        } // only one date passed, check if selected report requires start or end date/time params


        var params = this.selectedReport.params;
        var paramsKey = params.start ? 'start' : 'end';
        dates["".concat(paramsKey, "sAt")] = firstDate === null || firstDate === void 0 ? void 0 : firstDate.format();

        if (params[paramsKey].time) {
          dates["".concat(paramsKey, "sAtTime")] = firstDate === null || firstDate === void 0 ? void 0 : firstDate.format('hh:mmA');
        }

        return this.set('dates', dates);
      }
    }
  });

  _exports.default = _default;
});