define("lh4/templates/authenticated/launch-control/review-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mmaSspUK",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"s4-launch-control-first-page-container\"],[8],[0,\"\\n\\t\"],[5,\"ui/launch-control/fullscreen-modal\",[],[[\"@title\",\"@onClose\",\"@isWhiteHeader\",\"@isBlackCloseHeader\",\"@isBlackCloseButton\",\"@isLaunchControl\",\"@isLoading\",\"@errorMessage\"],[\"title\",[23,0,[\"close\"]],true,true,true,true,[23,0,[\"isLoading\"]],[23,0,[\"errorMessage\"]]]],{\"statements\":[[0,\"\\n\\t\\t\"],[6,[23,1,[\"content\"]],[],[[],[]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"landing-page\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"container\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"column\"],[8],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[7,\"img\",true],[10,\"src\",\"/img/launch-control/carousel/menu_upload.svg\"],[10,\"alt\",\"Menu\"],[8],[9],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[7,\"h1\",true],[8],[1,[23,0,[\"title\"]],false],[9],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[7,\"p\",true],[8],[1,[23,0,[\"description\"]],false],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"button-section\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[5,\"ui/s4/buttons/button\",[],[[\"@type\",\"@text\",\"@onClick\"],[\"rounded-contained\",\"GET STARTED\",[23,0,[\"navigateToMenuViewerPage\"]]]]],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\"]],\"parameters\":[1]}],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lh4/templates/authenticated/launch-control/review-menu.hbs"
    }
  });

  _exports.default = _default;
});